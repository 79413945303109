import {stakeStSolInstructions} from "@/api/models/instructions/stakeStSolInstructions";
import {PublicKey, TransactionInstruction} from "@solana/web3.js";
import BN from "bn.js";

export const stakeStSolLiquidity =  (
    instructions: TransactionInstruction[],
    wallet:PublicKey,
    associatedTokenAccount:PublicKey,
    liquidityAmount:BN
) =>{
    //
    // const LIDO_ADDRESS = new PublicKey('49Yi1TKkNyYjPAFdR9LBvoHcUjuPX4Df5T5yv39w2XTn')
    // const PROGRAM_ID = new PublicKey('CrX7kMhLC3cSsXJdT7JDgqrRVWGnUpX3gfEfxxU2NVLi')
    // const bufferArray = [
    //     LIDO_ADDRESS.toBuffer(),
    //     Buffer.from('reserve_account'),
    // ];
    // const [reserveAccount] = await PublicKey.findProgramAddress(bufferArray, PROGRAM_ID);
    //
    // const mintAuthorityBufferArray = [
    //     LIDO_ADDRESS.toBuffer(),
    //     Buffer.from('mint_authority'),
    // ];
    // const [mintAuthority] = await PublicKey.findProgramAddress(mintAuthorityBufferArray, PROGRAM_ID);
    // console.log('reserveAccount',reserveAccount.toString())
    // console.log('mintAuthority',mintAuthority.toString())
    instructions.push(
        stakeStSolInstructions(
            liquidityAmount,
            wallet,
            associatedTokenAccount
        )
    )
}