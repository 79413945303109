import {ReserveDetails} from "@/controller/type/reserveDetails";
import BigNumber from "bignumber.js";
import {Detail, LendingMarket, Obligation, Reserve} from "@/api/models";
import {Mining} from "@/api/models/state/mining";
import {calculateAllMine} from "@/api/utils/calculateAllMine";
import store from "@/store";
import {PublicKey} from "@solana/web3.js";
import {LENDING_MARKET_CONFIGS} from "@/api/constants/config";
import {UserLaunchpadObligationIndexDetail} from "@/controller/appController";
export type UserLaunchpadDetails = {
    poolType:string,
    userBorrowLimit:BigNumber,
    netValue:BigNumber
    netApr:BigNumber,
    userLiquidationThreshold:BigNumber,
    userTotalSupply:BigNumber,
    userTotalBorrow:BigNumber,
    userLarixReward:BigNumber,

    userMining:Detail<Mining>|undefined,
    userObligations:Detail<Obligation>|undefined,
    userAllObligations:Detail<Obligation>[]|undefined,
    lendingMarket:Detail<LendingMarket>,
    lendingProgramID:PublicKey,
}
export class UserLaunchpadData {
    private launchpadReserveDetails : ReserveDetails[];
    public userLaunchpadDetails:UserLaunchpadDetails[];
    private userLaunchpadMining:Map<string, Detail<Mining>[]>;
    private userLaunchpadObligations:Map<string, Detail<Obligation>[]>;
    private lendingReserveArray:Detail<Reserve>[];
    constructor(
        allReserveDetails:ReserveDetails[],
        userLaunchpadMining:Map<string, Detail<Mining>[]>,
        userLaunchpadObligations:Map<string, Detail<Obligation>[]>,
        lendingReserveArray:Detail<Reserve>[]
    ){
        //先单独处理larix隔离池，之后再迁移
        this.launchpadReserveDetails = allReserveDetails.filter((reserve)=> reserve.poolType!=='main')
        this.userLaunchpadDetails = []
        this.userLaunchpadMining =userLaunchpadMining
        this.userLaunchpadObligations =userLaunchpadObligations
        this.lendingReserveArray = lendingReserveArray
        this.calcUserAssetsOverView()
    }
    public calcUserAssetsOverView(){

        LENDING_MARKET_CONFIGS.forEach((config)=>{
            if (config.poolType ==='main') return
            const poolType = config.poolType
            const lendingMarket = config.lendingMarket
            const lendingProgramID = config.lendingProgramID
            const obligation = this.userLaunchpadObligations.get(poolType)
            const mining  = this.userLaunchpadMining.get(poolType)
            const userLaunchpadObligationIndexDetails = store.state.market.userLaunchpadObligationIndexDetails as UserLaunchpadObligationIndexDetail[]
            const targetPool = userLaunchpadObligationIndexDetails.find((detail)=>detail.poolType===poolType)
            const targetLaunchpadReserveDetails = this.launchpadReserveDetails.filter((reserve)=>reserve.poolType===poolType)
            let userTotalSupply = new BigNumber(0)
            let userTotalBorrow = new BigNumber(0)
            let userBorrowLimit = new BigNumber(0)
            let userLiquidationThreshold = new BigNumber(0)
            let yearSupplyInterest = new BigNumber(0)
            let yearBorrowInterest = new BigNumber(0)
            let yearMiningInterest = new BigNumber(0)
            let totalSupply = new BigNumber(0)
            let totalBorrow = new BigNumber(0)

            targetLaunchpadReserveDetails.forEach((reserve)=>{
                const config = LENDING_MARKET_CONFIGS.find((item)=>item.poolType===reserve.poolType)!

                totalSupply = totalSupply.plus(reserve.totalLiquidityInUsd)
                totalBorrow = totalBorrow.plus(reserve.totalBorrowedInUsd)
                userTotalSupply = userTotalSupply.plus(reserve.userAssetDetails.supplyBalance)
                userTotalBorrow = userTotalBorrow.plus(reserve.userAssetDetails.borrowBalance)
                userBorrowLimit = userBorrowLimit.plus(
                    reserve.userAssetDetails.isEnterMarket
                        ? reserve.userAssetDetails.supplyBalance.times(reserve.userAssetDetails.collateralFactor)
                        : 0
                )
                userLiquidationThreshold = userLiquidationThreshold.plus(
                    reserve.userAssetDetails.isEnterMarket
                        ? reserve.userAssetDetails.supplyBalance.times(reserve.userAssetDetails.liquidationThreshold)
                        : 0
                )
                yearSupplyInterest = yearSupplyInterest.plus(reserve.userAssetDetails.supplyBalance.times(reserve.supplyApy))
                yearSupplyInterest = yearSupplyInterest.plus(
                    reserve.userAssetDetails.supplyBalance.times( reserve.singleTokenDoubleRewardApy||0)
                );
                yearBorrowInterest =  yearBorrowInterest.minus(
                    reserve.userAssetDetails.borrowBalance.times( (reserve.singleTokenDoubleBorrowRewardApy||new BigNumber(0)).div(100))
                )
                yearBorrowInterest =  yearBorrowInterest.plus(
                    reserve.userAssetDetails.borrowBalance.times( reserve.borrowApy)
                );
                yearMiningInterest = yearMiningInterest.plus(
                    reserve.userAssetDetails.supplyBalance.times(reserve.supplyDistributionApy)
                ).plus(
                    reserve.userAssetDetails.borrowBalance.times(reserve.borrowDistributionApy)
                )
            })
            const netValue = userTotalSupply.minus(userTotalBorrow)
            const netApr = !userTotalSupply.isZero()
                ? yearSupplyInterest
                    .minus(yearBorrowInterest)
                    .div(netValue)
                    .toFixed(4)
                : 0
            const miningApy = !userTotalSupply.isZero()
                ?yearMiningInterest
                    .div(netValue)
                    .toFixed(4)
                : 0
            const userLarixReward = calculateAllMine(
                //@ts-ignore
                mining===undefined?undefined:mining[0],
                obligation===undefined?undefined:targetPool?obligation[targetPool.obligationIndex]:obligation[0],
                this.lendingReserveArray.filter((reserve)=>{
                    return reserve.info.liquidity.poolType===poolType
                })
            )
            this.userLaunchpadDetails.push({
                poolType:poolType,
                userBorrowLimit:userBorrowLimit,
                netValue:netValue,
                netApr:new BigNumber(netApr).plus(miningApy).times(100),
                userLiquidationThreshold:userLiquidationThreshold,
                userTotalSupply:userTotalSupply,
                userTotalBorrow:userTotalBorrow,
                userLarixReward:userLarixReward,
                userMining:mining?mining[0]:undefined,
                userObligations:obligation?targetPool?obligation[targetPool.obligationIndex]:obligation[0]:undefined,
                userAllObligations:obligation?obligation:undefined,
                lendingMarket:lendingMarket,
                lendingProgramID:lendingProgramID,
            })
        })
    }
    public setVuex(){
        store.commit('updateUserLaunchpadDetails',this.userLaunchpadDetails)
    }
}