import {PublicKey, TransactionInstruction,SystemProgram} from "@solana/web3.js";
import BN from 'bn.js';
import * as BufferLayout from 'buffer-layout';
import * as Layout from '../../utils/layout';
import {TOKEN_PROGRAM_ID } from '../../constants/config';

export const stakeStSolInstructions =  (
    liquidityAmount: number | BN,
    wallet:PublicKey,
    associatedTokenAccountAddress:PublicKey,
) : TransactionInstruction=>{
    const dataLayout = BufferLayout.struct([
        BufferLayout.u8('instruction'),
        BufferLayout.nu64('amount'), // little endian
    ]);

    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
        {
            instruction: 1, // 1 for deposit instruction
            amount: new BN(liquidityAmount),
        },
        data,
    );
    const keys = [
        //lidoAddress
        { pubkey: new PublicKey('49Yi1TKkNyYjPAFdR9LBvoHcUjuPX4Df5T5yv39w2XTn'), isSigner: false, isWritable: true, },
        { pubkey: wallet, isSigner: true, isWritable: true },
        { pubkey: associatedTokenAccountAddress, isSigner: false, isWritable: true, },
        //stSolMint
        { pubkey: new PublicKey('7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj'), isSigner: false, isWritable: true, },
        //reserve Account
        { pubkey: new PublicKey('3Kwv3pEAuoe4WevPB4rgMBTZndGDb53XT7qwQKnvHPfX'), isSigner: false, isWritable: true },
        //mintAuthority
        { pubkey: new PublicKey('8kRRsKezwXS21beVDcAoTmih1XbyFnEAMXXiGXz6J3Jz'), isSigner: false, isWritable: false },
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
    ];

    return new TransactionInstruction({
        keys,
        //lido programId
        programId: new PublicKey('CrX7kMhLC3cSsXJdT7JDgqrRVWGnUpX3gfEfxxU2NVLi'),
        data,
    });
}