export default {
    name: 'Indonesia',
    headers:{
        dashBoard:'Beranda',
        market:'Pasar',
        liquidation:'Likuiditas',
        Positions:'Posisi',
        larix:'LARIX',
        stake:'Stake',
        dao:'DAO',
        connect:'Hubungkan',
        About:'Tentang',
        reward:'Hadiah',
        launchpad:'Launchpad',
        main:'Main Pool'
    },
    reward:{
        larixBalance:'Saldo LARIX',
        larixRewards:'Hadiah LARIX',
        mSOLReward:'Hadiah mSOL',
        insufficientUsdcBalance:'Insufficient USDC balance',
        stake:'Mempertaruhkan',
        stakeForLP:'Taruhan Untuk Menghasilkan LP',
        earn2x:'Taruhan untuk Mendapatkan {x}X Hadiah LARIX',
        claimDirectly:'Klaim ',
        myStaking: 'Taruhan Saya',
        seeDetails: 'Lihat Detail',
        youWillLose1: 'Klaim Sekarang Anda Akan Kehilangan Peluang',
        youWillLose2: 'Mendapatkan Ekstra {x} Larix Rewards',
        reward:'Hadiah',
        walletBalance:'* Saldo Dompet :',
        maximumReceive:'MAX Dapat Diklaim {x} LARIX',
        expiredReward:'Hadiah Setelah Periode Lock-up',
        days:'hari',
        currentRewards:'Hadiah Saat Ini',
        stakeTime:'Periode Penguncian',
        claimLARIX:'Claim LARIX',
        needUSDC:'Estimated USDC ≈',
        chooseToReceive:'Choose to receive',
        main:"Main",
    },
    wallet:{
        reward:'Hadiah',
        connect:'Hubungkan',
        faucet:'Faucet',
        connectToAWallet:'Hubungkan ke Dompet',
        yourWallet:'Dompet Anda',
        disconnect:'Keluar',
        connected:'Terhubung',
        harvest:'Klaim Sekarang',
        walletBalance: 'Saldo Dompet',
        unclaimedBalance: 'Hadiah yang Dapat Diklaim',
        price: 'Harga LARIX',
        nothingToClaim: 'Tidak ada saldo untuk diklaim',
        subAccount :'My position',
        recentTransactions : 'Riwayat transaksi',
        wallet : 'Dompet'
    },
    footer:{
        doc:'Dok'
    },
    home:{
        totalSupply:'Total Suplai',
        totalBorrow:'Total Pinjaman',
        totalMined:'Total Sirkulasi',
        larixPrice:'Harga Larix',
        supplyMarket:'Suplai Pasar',
        borrowMarket:'Pasar Dana Pinjaman',
        myBill:'Tagihanku',
        asset:'Aset',
        collateral:'Jadikan Jaminan',
        allMarket:'Semua pasar',
        supplyAPY:'APR Suplai',
        wallet:'Saldo Dompet',
        borrowAPY:"APR Pinjaman",
        liquidity:"Liquiditas",
        operation:"Langkah Selanjutnya",
        borrowBalance:"Saldo Pinjaman",
        supplyBalance:"Suplai Saldo",
        withdraw:"Tarik",
        repay:"Bayar",
        supply:"Suplai",
        borrow:"Pinjam",
        loading:"Memuat",
        connectToAWallet:'Hubungkan ke Dompet',
        liquidityMining:'Penambangan likuiditas',
        totalAPR:'Total APR',
        more:'More',
        pool:'Pool',
        raydiumLP:'Raydium LP',
        unsettledAssets:'Aset yang belum diselesaikan',
        handleTip:'Transaction undone, please click here to process >',
        singleAsset:'Aset Tunggal',
        myPosition:'Posisi saya',
        tip:'Petunjuk',
        noBorrowFee:'Tidak Ada Biaya Pinjaman',
    },
    supplyAndBorrow:{
        borrowLimit:"Limit Pinjaman",
        netAPY:"Net APR",
        borrowBalance:"Saldo Pinjaman",
        supplyBalance:"Suplai Saldo",
        netValue:'Nilai Bersih',
        borrowRatio:'Rasio pinjaman',
    },
    supplyDialog: {
        supply: "Suplai",
        withDraw: "Tarik",
        wallet: "Dompet",
        balance: "Saldo",
        walletBalance: "Dompet",
        liquidity: "Likuiditas",
        max: "MAKS",
        limit85: "85% LIMIT",
        supplyApy: "APR Suplai",
        supplyBalance: "Suplai saldo",
        borrowLimit: "Limit pinjaman",
        supplyRate: "Tingkat Suplai",
        withdrawRate: "Tingkat Penarikan",
        invalidNumber:"Kesalahan Nomor",
        insufficientLiquidity:"Likuiditas Tidak Mencukupi",
        stillWithdraw:"Menarik Kapanpun",
        borrowLimitUsedPercent:"Utilitas batas tingkat pinjaman",
        collateralFactor:"Faktor Jaminan",
        insufficientFunds:"Dana Tidak Mencukupi",
        solBalanceIsLow:'SOL Balance is Low',
        cancel:'Batal',
        interestRate: "Suku Bunga",
        miningAPY: "APR Penambangan",
        supplyMaxLimit100M:"Jumlah maksimal suplai dibatasi sampai {x} AS ",
        currentSupply:"Current supply amount is",
        reachLpLimit:"Reach LP deposit Limit",
        reachLimit:"Reach deposit Limit",
        baseReward:'Hadiah dasar',
        boostedAPR:'Ditingkatkan LARIX APR',
        APR:'APR',
        supplyMaxAPR:'Max APR',
        baseAPR:'Base APR',
        baseLARIXRewards:'Base LARIX rewards',
        borrowingMinAPR:'Min APR',
    },
    borrowDialog: {
        borrow: "Pinjaman",
        repay: "Bayar",
        wallet: "Dompet",
        balance: "Saldo",
        walletBalance: "Saldo Dompet",
        liquidity: "Likuiditas",
        borrowMax: "MAKS",
        max:'MAKS',
        borrowApy: "APR Pinjaman",
        borrowBalance: "Saldo Pinjaman",
        borrowLimit: "Limit Pinjaman",
        borrowRate: "Tingkat Pinjaman",
        repayRate: "Tingkat Pembayaran",
        borrowMaxInTokenUnit:"Maksimal Pinjaman",
        borrowLimitUsedPercent:"Batas Tingkat pinjaman",
        collateralFactor:"Faktor Jaminan",
        invalidNumber:"Kesalahan Nomor",
        insufficientLiquidity:"Likuiditas tidak mencukupi",
        stillBorrow:"Pinjam Kapanpun",
        noBalanceToRepay:"Tidak ada Pinjaman",
        insufficientFunds:"Dana Tidak Sesuai",
        cancel:'Batal',
        interestRate: "Suku bunga",
        miningAPY: "APR Penambangan",
        reachPositionLimit:'Setiap Akun dibatasi 5 agunan dan Interaksi peminjaman secara bersamaan',
    },
    market:{
        supply:"Suplai",
        borrow:"Pinjaman",
        top3Markets:"3 Pasar Teratas",
        asset:"Aset",
        totalSupply:"Total Suplai",
        supplyAPY:"APR Suplai",
        totalBorrow:"Total Pinjaman",
        borrowAPY:"APR pinjaman",
        details:"Detail",
        supplyBorrow:'Keseluruhan Nilai Pasar',
        loading:"Loading",
        connectToAWallet:'Hubungkan ke Dompet',
        totalSupplyApy:"Total Suplai/APR",
        totalBorrowApy:"Total Pinjaman/APR",
        depositLimit:"Batas Setoran",
        supplyIR:'Suplai Bunga',
        borrowIR:'Bunga Pinjaman',
        supplyDistributionAPR:'Distribusi Suplai APR',
        borrowDistributionAPR:'Distribusi Pinjaman APR',
        supplyIRHelpContext:'Supply APR = Supply Interest + Supply Mining APR',
        borrowIRHelpContext:'Borrow APR = Borrow Interest - Borrow Mining APR',
        stake:'Stake',
        swap:'Swap',
    },
    marketId:{
        market:"Pasar",
        supply:"Suplai",
        borrow:"Pinjaman",
        netRate:"Net APR",
        supplyAPY:"APR Suplai",
        distributionSupplyAPY:"Distribusi APR",
        distributionBorrowAPY:"Distribusi APR",
        totalSupply:"Suplai Keseluruhan",
        borrowAPY:"APR Pinjaman",
        totalBorrow:"Total Pinjaman",
        interestRateModel:"Model Suku Bunga",
        marketDetails:"Detail Pasar",
        Price:"Harga",
        marketLiquidity:"Likuiditas Pasar",
        collateralFactor:"Faktor Jaminan",
        exchangeRate:"Tingkat Pertukaran",
        supplyMiningAPR:"APR Suplai Penambangan",
        borrowMiningAPR:"APR Pinjaman Penambangan",
        utilization:"Utilitas",
        supplyChartAPY:"APR Suplai",
        borrowChartAPY:"APR Pinjaman",
        mndeAPR:"APR"
    },
    larix:{
        amountinCirculation:"Jumlah dari Peredaran ",
        circulatingMarketCap:"Kapitalisasi pasar yang beredar",
        totalSupply:"Jumlah Keseluruhan",
        lARIXDistribution:"Distribusi LARIX",
        market:"Pasar",
        perDay:"Mining Harian",
        supplyMiningAPR:"APR Suplai Penambangan",
        borrowMiningAPR:"APR Pinjaman Penambangan",
        dailyDistribution:"Distribusi Harian",
        other:"Lainnya",
        distribution:"Distribusi",
        larixPrice:'Harga Larix',
    },
    About:{
        aboutLarix:"Tentang Larix",
        aboutLarixContext:"Larix adalah Protokol Keuangan berbasis Metaverse pertama di Solana, yang mengadopsi Suku bunga dinamis dan menciptakan kumpulan manajemen resiko yang lebih hemat modal, seperti perluasan agunan, Token Kripto, stable koin, aset sintetis, NFT, dan jenis aset lainnya yang dapat di manfaatkan sepenuhnya secara aman.",
        auditReport:"Laporan Audit",
        whyLarix:"Kenapa Larix",
        reason1:"Protokol peminjaman pertama dengan penambangan secara langsung",
        reason2:"Protokol peminjaman pertama yang mendukung peer to peer NFT ",
        reason3:"Protokol Peminjaman pertama sebagai Open-Sourced di Solana",
        roadmap:"Roadmap",
        roadmapStep1:"Fase 1: Jun – Sep 2021",
        roadmapStep2:"Fase 2: Late 2021 to 2022",
        roadmapStep3:"Fase 3: 2022 onwards",
        roadmapStep1Context:"Larix Memulai dari kebutuhan essensial Defi landing. Pada Tahap ini, Token kripto, Stablecoin, dan aset sintesis bisa diterima sebagai Jaminan, Resiko manajemen dan Keamanan aset adalah keutamaan Larix",
        roadmapStep2Context:"Bekerja Sama dengan jaringan PYTH untuk saling menghubungkan pasar keuangan High-fidelity (HiFi) ke World of decentralized Finance ( DeFI) yang dimana memungkinkan modal yang lebih tinggi dari berbagai aset keuangan konvensional.",
        roadmapStep3Context:"Protokol yang memperluas basis jaminan untuk penerima NFT melalui pinjaman peer to peer di semua kelas aset. Larix akan bekerja dengan banyak mitra untuk menyediakan likuiditas yang aman, dan hampir instan di ekonomi yang nyata.",
        seedRoundInvestor:"Investor Kami ",
        partnership:"Mitra",
        audit:"Audit",
    },
    liquidation: {
        liquidation : 'Likuiditas',
        liquidateTips : 'Likuidasi Tips',
        account: 'Akun',
        amountTip: 'Jumlah yang ingin anda bayar kembali',
        assetToRepay :'aset untuk dibayar',
        assetsToRepay :'aset yang dibayar',
        maxRepayAmount: 'Maksimal Jumlah dibayar',
        assetToSeize : 'Aset untuk diserahkah',
        assetsToSeize : 'Aset untuk diserahkan',
        maxSeizeAmount : 'Maksimal jumlah diserahkan',
        debtRatio : 'Rasio Pinjaman',
        action : 'Action',
        liquidate : 'Likuiditas',
    },
    noticeDialog: {
        notice : 'Notice',
        text : 'Hitungan mundur untuk memulai penambangan',
        confirm: 'Konfirmasi'
    },
    pendingDialog: {
        transactionPending: "Tertunda",
        viewOnEtherscan: "Lihat di Heco",
        confirm: "Konfirmasi transaksi...",
        broadcast: "Transaksi tersiar...",
        waitingEvm: "Menunggu hasil dari EVM...",
        errorTips:"Transaksi Gagal",
        checkTips:"Transaksi Sukses"
    },
    handleLpDialog:{
        unsettledAssets:'Aset yang belum diselesaikan',
        withdraw:'Tarik',
        keepMining:'Pertambangan',
        tooltip:'Silakan pilih pesanan yang ingin Anda proses'
    },
    openObligationDialog:{
        openChoose:'Buka atau pilih sub-akun',
        usable:'sub-akun yang dapat digunakan:',
        unavailable:'Tidak ada sub-akun yang tersedia',
        rulesLine1:'* lp Jaminan aturan:',
        rulesLine2:'Sub-akun hanya dapat menjaminkan satu aset LP',
        action:'Buka akun baru dan agunan',
    },
    borrowFeeTipDialog:{
        tip:'Petunjuk',
        noBorrowFee:'Tidak ada biaya pinjaman di Larix',
        gotIt:'dipahami',
    },
    larixStakingDialog:{
        expireDate:'Expire date',
        stakedLP:'LP yang dipertaruhkan',
        assets:'Aktiva',
        amount:'Jumlah',
        unlockingTime:'Membuka Waktu',
        stakeTime:'Locked Date',
        actions:'tindakan',
        unlocking:'Unlocking',
        oneClickClaim:'Claim LARIX rewards from Raydium',
        claim:'Claim',
        raydiumReward:'Raydium Hadiah',
    },
    tip:{
        borrowLimitTip:'Batas pinjaman adalah jumlah maksimum aset yang dapat Anda pinjam. Ini ditandai dengan bar jeruk. Semakin tinggi nilai aset yang dipasok, semakin tinggi batas pinjamannya.',
        borrowBalanceTip:'Saldo Pinjaman: Saldo pinjaman adalah jumlah dari semua aset yang dipinjam.',
        supplyBalanceTip:'Saldo Pasokan: Saldo pasokan adalah jumlah dari semua aset yang dipasok. Meningkatkan simpanan dapat meningkatkan batas pinjaman Anda.',
        netAPRTip:'APR Bersih = Total Hasil Tahunan / Nilai Bersih (Total hasil tahunan = total hasil pasokan - total biaya pinjaman).',
        netValueTip:'Nilai Bersih=Saldo Pasokan-Saldo Pinjam.',
        borrowRatioTip:'Rasio pinjaman =Total pinjaman / Saldo persediaan. Tingkat pinjaman mencerminkan tingkat pemanfaatan aset Anda, dan batas atas adalah batas pinjaman.',
        liquidationTip:'umlah total pinjaman saat akun memicu likuidasi. Ini ditandai dengan bilah merah. Ketika jumlah total pinjaman => ambang likuidasi, akun akan memasuki keadaan yang dapat dilikuidasi. Meningkatkan simpanan atau mengurangi pinjaman dapat membebaskan akun Anda dari likuidasi.',
        supplyAPRTip:'Tingkat persentase tahunan (APR) yang diperoleh untuk menyediakan aset. APR bersifat dinamis dan berfluktuasi seiring dengan peningkatan/penurunan total pasokan di kumpulan.Supply APR= bunga pasokan (hasil aset yang disediakan) + hasil penambangan pasokan (token LARIX).',
        borrowAPRTip:'Tarif persentase tahunan (APR) yang dibayarkan untuk aset yang dipinjam. APR bersifat dinamis dan berfluktuasi karena total dana pinjaman di kumpulan meningkat/berkurang. Pinjam APR = bunga pinjaman (biaya untuk meminjam token tertentu) - meminjam hasil penambangan (token LARIX).',
        walletBalanceTip:'Saldo dompet adalah saldo berbagai aset di dompet Anda. Saldo dompet dapat digunakan untuk memasok atau membayar kembali aset yang dipinjam dan bunga yang sesuai.',
        liquidityTip:'Likuiditas adalah saldo aset saat ini yang tersedia untuk ditarik/dipinjam. Likuiditas yang cukup juga berarti Anda dapat menarik simpanan Anda kapan saja.',
        borrowFeeTip:'Larix memiliki kebijakan \'nol biaya pinjaman\'. Biaya pinjaman merupakan biaya tambahan pada saat meminjam yang berbeda dengan bunga pinjaman. Biaya pinjaman = Jumlah pinjaman * Tarif biaya pinjaman. Jumlah pinjaman yang lebih besar akan menyebabkan tingkat biaya pinjaman yang lebih tinggi, yang akan menghasilkan biaya pinjaman yang lebih besar. Biaya pinjaman dibebankan setiap kali terjadi transaksi.',
        collateralTip:'Setelah menyetor, Anda perlu mengaktifkan tombol agunan untuk meminjam. Semakin tinggi nilai taruhan suatu aset, semakin tinggi jumlah (yaitu batas pinjaman) yang dapat Anda pinjam.',
        availableTip:'Tersedia adalah aset pinjaman maksimum Anda saat ini.',
        myRewardTip:'Menyediakan/meminjam token tunggal atau mempertaruhkan LP di Larix dapat memperoleh hasil penambangan (token LARIX), ini adalah persediaan/peminjaman hasil penambangan Anda.',
        myStakeTip:'Jumlah LP LARIX/USDC yang dipertaruhkan di Raydium saat Anda memilih hadiah yang ditingkatkan x5/x10.',
        lockedDateTip:'Locked date adalah tanggal ketika Anda memilih x5/x10 boosted rewards dan stake LARIX/USDC LP. Anda tidak dapat menarik LARIX/USDC LP yang di-staking selama periode lock-up (90 hari atau 180 hari).',
        unlockingDateTip:'Waktu Buka Kunci adalah tanggal di mana Anda bisa mengklaim 5x/10x LARIX boosted reward dan membuka LP setelah 90 hari/180 hari staking.',
        stakeAmountTip:'Jumlah adalah jumlah LARIX/USDC LP yang dipertaruhkan setelah memilih hadiah yang ditingkatkan x5/x10.',
        raydiumRewardTip:'Setelah memilih x5/x10 boosted reward dan menambahkan likuiditas LARIX/USDC, Anda akan menerima hadiah LARIX untuk penambangan likuiditas, yang dapat Anda klaim di Larix kapan saja tanpa periode penguncian, cukup klik tombol "Klaim" untuk memanen semua. Bagian RAY dari hadiah akan dikirim ke dompet Anda setelah Anda menghapus likuiditas dari Raydium.',
        dailyTip:'Daily Mining is the daily amount of LARIX tokens earned by supply & borrow of assets.',
        collateralFactorTip:'Rasio aset pinjaman maksimum dari satu unit agunan. Misalnya, jika Anda menyediakan USDC senilai $100 dan dapat meminjam BTC senilai hingga $85, faktor jaminan USDC adalah 85%.',
        larixRewards:'LARIX Hadiah',
        dialogAPR1X:'Awal 1X APR',
        dialogAPR5X:'Ditingkatkan 5X APR',
        APRTip:'Optional boosted APR if LARIX rewards are staked for 10x',
        baseRewardTip:'Receive local currency APR and LARIX APR',
        boostedAPRTip:'Optional boosted APR if LARIX rewards are staked for 5x',
        maxAPRTip:'(10x boosted staking)',
        baseAPRTip:'(Supply APR + LARIX rewards)',
        baseAPRTip2:'(Borrow APR - LARIX rewards)',
        interest:'interest',
        doubleRewardTip:'(Hadiah {x} akan didistribusikan langsung ke dompet Anda setiap 24 jam)',
        claimStakeRewardTip:'Click any \'Claim\' button to claim all LP rewards (5x and 10x included).  If there\'s still remaining unclaimed rewards, keep clicking \'Claim\' until all rewards are claimed.',
        liquidateTip:'Likuidasi terjadi ketika rasio utang melebihi 100%.',
    },
    positions:{
        mySupply:'Pasokan saya',
        myBorrow:'Pinjamanku',
        myStake:'Taruhan saya',
        myReward:'Hadiah saya',
        liquidationThreshol:'Ambang Likuidasi',
        claim:'Klaim ',
        noSupplyYet:'Belum Ada Pasokan',
        noBorrowYet:'Belum Pinjam',
        noStakeYet:'Belum Ada Taruhan',
        supplyNow:'Setor Sekarang',
        borrowNow:'Pinjam Sekarang',
        stakeNow:'Taruhan Sekarang',
    },
    launchpad:{
        whatLaunchpad:'Apa itu landasan peluncuran pinjaman Larix?',
        details1:'Larix Lending Launchpad menyediakan kumpulan pendanaan terpisah untuk token SPL asli.',
        details2:'Larix meluncurkan kumpulan dana terisolasi berdasarkan kumpulan pinjaman lintas agunan yang ada, untuk mendukung lebih banyak token untuk mewujudkan fungsi pinjaman.',
        details3:'Jika Anda ingin mendaftarkan aset baru di Lending Launchpad, jangan ragu untuk mengisi formulir aplikasi!',
        apply:'Berlaku'
    }
}
