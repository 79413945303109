export default {
    name: 'Русский',
    headers:{
        dashBoard:' Обзор ',
        market:'Рынок',
        liquidation:'Ликвидация',
        larix:'LARIX',
        Positions:'Позиции',
        stake:'Стейкинг',
        dao:'DAO',
        connect:'Подключиться',
        About:'О проекте',
        reward:'Награда',
        launchpad:'Launchpad',
        main:'Main Pool'
    },
    reward:{
        larixBalance:'Баланс LARIX',
        larixRewards:'Награды LARIX',
        mSOLReward:'Награда mSOL',
        insufficientUsdcBalance:'Insufficient USDC balance',
        stake:'Ставка',
        stakeForLP:'Ставка для создания LP',
        earn2x:'Делайте ставки, чтобы заработать в {x} раза больше вознаграждений LARIX ',
        claimDirectly:'Претензия ',
        myStaking: 'Мой стейкинг',
        seeDetails: 'Смотрите подробности',
        youWillLose1: 'Заявите сейчас, что вы потеряете шанс заработать',
        youWillLose2: 'дополнительные {x} вознаграждений LARIX',
        reward:'Награды',
        walletBalance:'* Баланс кошелька :',
        maximumReceive:'Может получить до {x} LARIX',
        expiredReward:'Награды после периода блокировки',
        days:'дней',
        currentRewards:'Текущие награды',
        stakeTime:'Период блокировки',
        claimLARIX:'Claim LARIX',
        needUSDC:'Estimated USDC ≈',
        chooseToReceive:'Choose to receive',
        main:"Main",
    },
    wallet:{
        reward:'Награда',
        connect:'Подключиться',
        faucet:'Кран',
        connectToAWallet:'Подключиться к кошельку',
        yourWallet:'Ваш кошелек',
        disconnect:'Отключить',
        connected:'Подключено',
        harvest:'Собрать',
        walletBalance: 'Баланс кошелька',
        unclaimedBalance: 'Неснятый остаток',
        price: 'Цена',
        nothingToClaim: 'Нечего снимать',
        subAccount :'My position',
        recentTransactions : 'Последние транзакции',
        wallet : 'Кошелек'
    },
    footer:{
        doc:'Документация'
    },
    home:{
        totalSupply:'Всё предложение',
        totalBorrow:'Всего займов',
        totalMined:'Всего добыто',
        larixPrice:'Цена Larix',
        supplyMarket:'Рынок предложения',
        borrowMarket:'Рынок займов',
        myBill:'Мой счет',
        asset:'Активы',
        collateral:'Ипотека',
        allMarket:'Все рынки',
        supplyAPY:'APR предложения',
        wallet:'Кошелек',
        borrowAPY:"APR заимствования",
        liquidity:"Ликвидность",
        operation:"Операция",
        borrowBalance:"Баланс заимствования",
        supplyBalance:"Баланс обеспечения",
        withdraw:"Снять",
        repay:"Погасить",
        supply:"Обеспечение",
        borrow:"Заимствовать",
        loading:"Загрузка",
        connectToAWallet:'Подключиться к кошельку',
        liquidityMining:'ликвидность Майнинг',
        totalAPR:'весь APR',
        more:'более',
        pool:'Капитальный пул',
        raydiumLP:'Raydium LP',
        unsettledAssets:'неурегулированные активы',
        handleTip:'Transaction undone, please click here to process >',
        singleAsset:'Отдельный актив',
        myPosition:'Моя позиция',
        tip:'намекать',
        noBorrowFee:'Нет комиссии за заем',
    },
    supplyAndBorrow:{
        borrowLimit:"Лимит заимствования",
        netAPY:"Общий APR",
        borrowBalance:"Баланс заимствования",
        supplyBalance:"Баланс обеспечения",
        netValue:'Чистая стоимость',
        borrowRatio:'Borrow Ratio',
    },
    supplyDialog: {
        supply: "Обеспечение",
        withDraw: "Снять",
        wallet: "Кошелек",
        balance: "Баланс",
        walletBalance: "кошелек",
        liquidity: "Ликвидность",
        max: "MAX",
        limit85: "Лимит 85%",
        supplyApy: "APR обеспечения",
        supplyBalance: "Баланс обеспечения",
        borrowLimit: "Лимит заимствования",
        supplyRate: "Процент обеспечения",
        withdrawRate: "Доля вывода",
        invalidNumber:"Неверный номер",
        insufficientLiquidity:"Недостаточная ликвидность",
        stillWithdraw:"Снять в любом случае",
        borrowLimitUsedPercent:"Коэффициент использования лимита заимствования",
        collateralFactor:"Залоговый фактор",
        insufficientFunds:"Недостаточно средств",
        solBalanceIsLow:'SOL Баланс низкий',
        cancel:'Отмена',
        interestRate: "Процентная ставка",
        miningAPY: "APR майнинга",
        supplyMaxLimit100M:"Максимальная сумма предложения ограничена {x}",
        currentSupply:"Current supply amount is",
        reachLpLimit:"Reach LP deposit Limit",
        reachLimit:"Reach deposit Limit",
        baseReward:'Базовая награда',
        boostedAPR:'Увеличенный LARIX APR',
        APR:'APR',
        supplyMaxAPR:'Max APR',
        baseAPR:'Base APR',
        baseLARIXRewards:'Base LARIX rewards',
        borrowingMinAPR:'Min APR',
    },
    borrowDialog: {
        borrow: "Заимствовать",
        repay: "Погасить",
        wallet: "Кошелек",
        balance: "Баланс",
        walletBalance: "кошелек",
        liquidity: "Ликвидность",
        borrowMax: "MAX",
        max:'MAX',
        borrowApy: "APR заимствования",
        borrowBalance: "Сумма займа",
        borrowLimit: "Лимит заимствования",
        borrowRate: "Ставка займа",
        repayRate: "Ставка погашения",
        borrowMaxInTokenUnit:"Max заимствования",
        borrowLimitUsedPercent:"Коэффициент использования лимита заимствования",
        collateralFactor:"Залоговый фактор",
        invalidNumber:"Неверный номер",
        insufficientLiquidity:"Недостаточная ликвидность",
        stillBorrow:"Занять в любом случае",
        noBalanceToRepay:"Нет баланса для погашения",
        insufficientFunds:"Недостаточно средств",
        cancel:'Отмена',
        interestRate: "Процентная ставка",
        miningAPY: "APR майнинга",
        reachPositionLimit:'Каждая учетная запись ограничена 5 позициями по обеспечению и заимствованию.'
    },
    market:{
        supply:"Обеспечение",
        borrow:"Заимствование",
        top3Markets:"3 лучших рынка",
        asset:"Актив",
        totalSupply:"Общее обеспечение",
        supplyAPY:"APR обеспечения",
        totalBorrow:"Общая сумма займа",
        borrowAPY:"APR заимствования",
        details:"Детали",
        supplyBorrow:'Общая рыночная стоимость',
        loading:"Загрузка",
        connectToAWallet:'Подключиться к кошельку',
        totalSupplyApy:"Общее обеспечение/APR",
        totalBorrowApy:"Общее заимствование/APR",
        depositLimit:"Лимит депозита",
        supplyIR:'Процент обеспечения ',
        borrowIR:'Процент заимствования',
        supplyDistributionAPR:'APR распределения обеспечения',
        borrowDistributionAPR:'APR распределения заимствования',
        supplyIRHelpContext:'APR обеспечения = процент обеспечения + APR майнинга обеспечения',
        borrowIRHelpContext:'APR заимствования = процент заимствования - APR майнинга заимствования',
        stake:'Stake',
        swap:'Swap',
    },
    marketId:{
        market:"Рынок",
        supply:"Обеспечение",
        borrow:"Заимствование",
        netRate:"Исходный APR",
        supplyAPY:"APR обеспечения",
        distributionSupplyAPY:"Распределение APR",
        distributionBorrowAPY:"Распределение APR",
        totalSupply:"Общее обеспечение",
        borrowAPY:"APR заимствования",
        totalBorrow:"Общая сумма займа",
        interestRateModel:"Модель процентной ставки",
        marketDetails:"Информация о рынке",
        Price:"Цена",
        marketLiquidity:"Ликвидность рынка",
        collateralFactor:"Залоговый фактор",
        exchangeRate:"Обменный курс",
        supplyMiningAPR:"APR майнинга предложения",
        borrowMiningAPR:"APR майнинга заимствования",
        utilization:"Утилизация",
        supplyChartAPY:"APR предложения",
        borrowChartAPY:"APR заимствования",
        mndeAPR:"APR"
    },
    larix:{
        amountinCirculation:"Объем циркуляции",
        circulatingMarketCap:"Рыночная капитализация",
        totalSupply:"Общий объем",
        lARIXDistribution:"Дистрибуция LARIX",
        market:"Рынок",
        perDay:"Суточный майнинг",
        supplyMiningAPR:"APR майнинга обеспечения",
        borrowMiningAPR:"APR майнинга заимствования",
        dailyDistribution:"Ежедневное распределение",
        other:"Другое",
        distribution:"Распределение",
        larixPrice:'Цена Larix',
    },
    About:{
        aboutLarix:"О проекте Larix",
        aboutLarixContext:"Larix - это первый финансовый протокол, основанный на Метавселенной, на Solana, использующий динамическую модель процентных ставок и обеспечивающий более эффективную модель управления рисками, поскольку в пулах используется  безопасный способ использования широкого спектра типов обеспечения: крипто-токены, стейблкоины, синтетические активы, NFT и другие типы активов.",
        auditReport:"Отчет аудитора",
        whyLarix:"Почему Larix?",
        reason1:"Первый протокол кредитования с функцией майнинга в реальном времени",
        reason2:"Первый протокол кредитования с поддержкой кредитования NFT",
        reason3:"Первый протокол кредитования с открытым исходным кодом на Solana",
        roadmap:"Дорожная карта",
        roadmapStep1:"Этап 1: Июнь - Сентябрь 2021 года",
        roadmapStep2:"Этап 2: Конец 2021, начало 2022 года",
        roadmapStep3:"Этап 3: В течение 2022 года",
        roadmapStep1Context:"Larix начинает с предоставления основных потребностей автоматизированного кредитования Defi. На этом этапе криптовалютные токены, стейблкоины и синтетические активы принимаются в качестве залога. Управление рисками и безопасность активов являются краеугольными камнями Larix.",
        roadmapStep2Context:"Партнерство с сетью PYTH для подключения финансовых рынков высокой точности (HiFi) к миру децентрализованных финансов (Defi), что позволяет более эффективно использовать капитал более широкому кругу обычных финансовых активов.",
        roadmapStep3Context:"Протокол расширяет базу обеспечения, чтобы принимать невзаимозаменяемые токены (NFT) через одноранговое кредитование для всех классов активов. Larix будет сотрудничать с различными партнерами, чтобы обеспечить безопасную, гибкую и почти мгновенную ликвидность для реальной экономики.",
        seedRoundInvestor:"Наши инвесторы",
        partnership:"Партнерство",
        audit:"Аудит",
    },
    liquidation: {
        liquidation : 'Ликвидация',
        liquidateTips : 'Ликвидировать советы',
        account: 'Учетная запись',
        amountTip: 'Сумма, которую вы хотите погасить',
        assetToRepay :'Актив для погашения',
        assetsToRepay :'Активы для погашения',
        maxRepayAmount: 'Максимальная сумма погашения',
        assetToSeize : 'Актив для изъятия',
        assetsToSeize : 'Активы для изъятия',
        maxSeizeAmount : 'Максимальная сумма изъятия',
        debtRatio : 'Коэффициент задолженности',
        action : 'Действие',
        liquidate : 'Ликвидировать',
    },
    noticeDialog: {
        notice : 'Уведомление',
        text : 'Обратный отсчет до начала добычи',
        confirm: 'Подтвердить'
    },
    pendingDialog: {
        transactionPending: "Ожидание",
        viewOnEtherscan: "Просмотр в Heco",
        confirm: "Подтверждение транзакции...",
        broadcast: "Показать транзакцию...",
        waitingEvm: "Ожидание EVM результатов...",
        errorTips:"Транзакция не удалась",
        checkTips:"Успешная транзакция"
    },
    handleLpDialog:{
        unsettledAssets:'неурегулированные активы',
        withdraw:'Снять',
        keepMining:'майнинга',
        tooltip:'Выберите заказ, который хотите обработать'
    },
    openObligationDialog:{
        openChoose:'Открыть или выбрать субсчет',
        usable:'пригодный для использования субсчет:',
        unavailable:'Субаккаунт недоступен',
        rulesLine1:'* lp Ипотека правила:',
        rulesLine2:'Субсчет может обеспечивать залог только одного актива LP',
        action:'Открыть новый счет и залог',
    },
    borrowFeeTipDialog:{
        tip:'намекать',
        noBorrowFee:'Плата за кредит в Larix не взимается',
        gotIt:'Понятно',
    },
    larixStakingDialog:{
        expireDate:'Expire date',
        stakedLP:'Ставка LP',
        assets:'Ресурсы',
        amount:'Количество',
        unlockingTime:'Время разблокировки',
        stakeTime:'Locked Date',
        actions:'Действия',
        unlocking:'Unlocking',
        oneClickClaim:'Claim LARIX rewards from Raydium',
        claim:'Claim',
        raydiumReward:'Raydium Награда',
    },
    tip:{
        borrowLimitTip:'Лимит заимствования — это максимальная сумма активов, которую вы можете заимствовать. Это отмечено апельсин полосой. Чем выше стоимость поставляемых активов, тем выше будет лимит заимствования',
        borrowBalanceTip:'Остаток займа представляет собой сумму всех заемных активов',
        supplyBalanceTip:'Баланс предложения — это сумма всех предоставленных активов. Увеличение депозитов может увеличить лимиты заимствования',
        netAPRTip:'чистая годовая доходность = общая годовая доходность / чистая стоимость (общая годовая доходность = общая доходность предложения - общая стоимость займа )',
        netValueTip:'Чистая стоимость = Баланс предложения-Баланс займа',
        borrowRatioTip:'Коэффициент заимствования = Общий объем заимствования / Баланс предложения. Ставка займа отражает коэффициент использования ваших активов, а верхний предел — это лимит займа',
        liquidationTip:'общая сумма займа, когда учетная запись инициирует ликвидацию. Она отмечена красной полосой. Когда общая сумма займа => порог ликвидации, учетная запись перейдет в состояние, которое может быть ликвидировано. Увеличение депозитов или уменьшение заимствований может освободить ваш счет от ликвидации',
        supplyAPRTip:'годовая процентная ставка (годовая ставка), полученная за предоставление активов. Годовая процентная ставка является динамической и колеблется по мере увеличения/уменьшения общего предложения в пуле. Предложение APR = процент предложения (доход от поставляемого актива) + доход от майнинга предложения (токен LARIX)',
        borrowAPRTip:'годовая процентная ставка (годовая), уплачиваемая за заемные активы. Годовые процентные ставки являются динамическими и колеблются по мере увеличения/уменьшения общей суммы заемных средств в пуле. Годовая процентная ставка по займу = процент по займу (стоимость заимствования конкретного токена) - доходность заимствования при майнинге (токен LARIX)',
        walletBalanceTip:'Баланс кошелька — это баланс различных активов в вашем кошельке. Баланс кошелька может быть использован для предоставления или погашения заемных средств и соответствующих процентов',
        liquidityTip:'Ликвидность — это текущий баланс актива, который доступен для вывода/заимствования. Достаточная ликвидность также означает, что вы можете снять свои депозиты в любое время',
        borrowFeeTip:'Larix придерживается политики «нулевой комиссии за кредит». Комиссия за пользование кредитом — это дополнительные расходы при заимствовании, которые отличаются от процентов по займу. Комиссия за кредит = Сумма кредита * Ставка комиссии за кредит. Большая сумма кредита приведет к более высокой ставке комиссии по кредиту, что приведет к увеличению комиссии по кредиту. Комиссия за кредит взимается каждый раз при совершении транзакции',
        collateralTip:'после внесения депозита вам нужно включить кнопку обеспечения, чтобы взять кредит. Чем выше стоимость стейкинга актива, тем выше сумма (т. е. лимит займа), которую вы можете занять',
        availableTip:'Доступен ваш текущий максимальный заемный актив',
        myRewardTip:'Предоставление/заимствование отдельных токенов или стейкинг LP в Larix может приносить доход от майнинга (токен LARIX). Вот ваш доход от майнинга',
        myStakeTip:'сумма LARIX/USDC LP, поставленная на Raydium, когда вы выбираете увеличенные вознаграждения x5/x10',
        lockedDateTip:'Дата блокировки — это дата, когда вы выбираете увеличенные вознаграждения x5/x10 и ставите LARIX/USDC LP. Вы не можете вывести поставленные LARIX/USDC LP в течение периода блокировки (90 дней или 180 дней)',
        unlockingDateTip:'Время разблокировки — это дата, когда вы можете получить 5x/10x усиленные награды LARIX и разблокировать LP через 90 дней/180 дней стейкинга',
        stakeAmountTip:'Сумма – это количество LARIX/USDC LP, поставленное после выбора усиленных вознаграждений x5/x10',
        raydiumRewardTip:'После выбора усиленных вознаграждений x5/x10 и добавления ликвидности LARIX/USDC вы получите вознаграждения LARIX за добычу ликвидности, которые вы можете получить в Larix в любое время без периода блокировки, просто нажмите кнопку «Получить», чтобы собрать все. Часть вознаграждения RAY будет отправлена на ваш кошелек после того, как вы удалите ликвидность из Raydium',
        dailyTip:'Ежедневный майнинг — это ежедневное количество токенов LARIX, заработанное за счет предложения и заимствования активов',
        collateralFactorTip:'максимальный коэффициент ссудного актива на единицу залога. Например, если вы предоставили USDC на сумму 100 долларов США и можете занять BTC на сумму до 85 долларов США, коэффициент обеспечения USDC составляет 85%',
        larixRewards:'LARIX Награда',
        dialogAPR1X:'Исходный 1X APR',
        dialogAPR5X:'усиленный 5X APR',
        APRTip:'Optional boosted APR if LARIX rewards are staked for 10x',
        baseRewardTip:'Receive local currency APR and LARIX APR',
        boostedAPRTip:'Optional boosted APR if LARIX rewards are staked for 5x',
        maxAPRTip:'(10x boosted staking)',
        baseAPRTip:'(Supply APR + LARIX rewards)',
        baseAPRTip2:'(Borrow APR - LARIX rewards)',
        interest:'interest',
        doubleRewardTip:'(Награды {x} будут распределяться непосредственно на ваш кошелек каждые 24 часа)',
        claimStakeRewardTip:'Click any \'Claim\' button to claim all LP rewards (5x and 10x included).  If there\'s still remaining unclaimed rewards, keep clicking \'Claim\' until all rewards are claimed.',
        liquidateTip:'Ликвидация происходит, когда коэффициент долга превышает 100%.',
    },
    positions:{
        mySupply:'Моя поставка',
        myBorrow:'Мой кредит',
        myStake:'Моя ставка',
        myReward:'Моя награда',
        liquidationThreshol:'Порог ликвидации',
        claim:'получать ',
        noSupplyYet:'Пока нет предложения',
        noBorrowYet:'Пока нет займа',
        noStakeYet:'Пока нет ставки',
        supplyNow:'Депозит сейчас',
        borrowNow:'Занять сейчас',
        stakeNow:'Сделать ставку сейчас',
    },
    launchpad:{
        whatLaunchpad:'Что такое панель запуска кредитования Larix?',
        details1:'Larix Lending Launchpad предоставляет отдельный пул финансирования для нативных токенов SPL.',
        details2:'Larix запустил изолированный пул средств на основе существующего пула перекрестного кредитования, чтобы поддерживать больше токенов для реализации функции кредитования.',
        details3:'Если вы хотите разместить какие-либо новые активы на Lending Launchpad, не стесняйтесь заполнять форму заявки!',
        apply:'Применять'
    }
}
