export const moduleReward = {
    state: () => ({
        rewardDialogVisible: false,
    }),
    getters: {
    },
    mutations: {
        updateRewardDialogVisible(state: any, value: boolean) {
            state.rewardDialogVisible = value
        },

    },
    actions: {
    }
}