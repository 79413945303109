export const moduleLarix = {
    state: () => ({
        larixTokenAccount: {},
    }),
    getters: {
    },
    mutations: {
        updateLarixTokenAccount(state:any,value:any){
            state.larixTokenAccount = value
        },
    },
    actions: {
    }
}