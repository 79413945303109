
import {mapState} from "vuex";
import {defineComponent} from 'vue'
import { useI18n } from 'vue-i18n'
import {getWallet} from "@/api/context/wallet";
import {formatBigNumberStr} from "@/utils/helpers";
import BigNumber from "bignumber.js";
import txFailed from "@/controller/txFailed";
import txSuccess from "@/controller/txSuccess";
import openTxDialog from "@/controller/openTxDialog";
import {claimMineAndStake} from "@/api/actions/claimMineAndStake";
import { claimMine } from "@/api/actions/claimMine";
import {
  LENDING_MARKET,
  LENDING_PROGRAM_ID,
} from "@/api/constants/config";
import {PublicKey} from "@solana/web3.js";
import {Detail, Reserve} from "@/api/models";
import appController from "@/controller/appController";


export default defineComponent({
  name: "Reward",
  data() {
    return {
      step: 1,
      rewardMultiplier: 10,
      confirmingClaim: false,
      sliderStakePercent: 0,
      userStakeLarixAmount:new BigNumber(0),
      userTotalStakeLarixAmount:new BigNumber(0),
      needUSDC:new BigNumber(0),
      formatBigNumberStr:formatBigNumberStr,
      poolType:'main',
    }
  },
  watch: {
    rewardDialogVisible: function (newVal) {
      if (!newVal) {
        this.step = 1
        this.confirmingClaim = false
      }
    }
  },
  setup(){
    const { t } = useI18n()
    return {
      t,
      larixImg: require('../assets/reward_LARIX@2x.png'),
      rayImg: require('../assets/coin/asset_RAY.svg'),
    }
  },
  computed: {
    userLarixLaunchpadDetails(){
      //@ts-ignore
      return this.userLaunchpadDetails.find((details)=>details.poolType===this.poolType)!
    },
    dialogTitle():string {
      if (this.step === 2) return this.$t('reward.reward')+' × '+this.rewardMultiplier
      return this.$t('reward.larixRewards')
    },
    walletLarixBalance():BigNumber{
      if (this.larixTokenAccount)
      {
        //@ts-ignore
        return  new BigNumber(this.larixTokenAccount[0].info.amount).times(new BigNumber(10).pow(-6))
      }else {
        return new BigNumber(0)
      }

    },
    larixStakeLpDetals(){
      //@ts-ignore
      return this.mineCollateralLpDetails.find((item:any) => item.pairsName === 'LARIX/USDC')
    },
    stakePeriod():number{
      return this.rewardMultiplier/5*90
    },
    userUsdcBalance(){
      //@ts-ignore
      return this.allReservesDetails.find((item:any) => item.symbol === 'USDC').userAssetDetails.walletBalanceInTokenUnit
    },
    borrowButtonText:function (){

      if (!this.userUsdcBalance.isGreaterThan(0)||this.needUSDC.isGreaterThan(this.userUsdcBalance)) {
        return this.$t('reward.insufficientUsdcBalance')
      }
      return this.$t('reward.stakeForLP')
    },
    rewardDialogVisible:{
      get(){
        return this.$store.state.reward.rewardDialogVisible
      },
      set(value) {
        this.sliderStakePercent = 0
        this.needUSDC = new BigNumber(0)
        this.userTotalStakeLarixAmount = new BigNumber(0)
        this.userStakeLarixAmount = new BigNumber(0)
        this.rewardMultiplier = 10
        this.$store.commit('updateRewardDialogVisible',value)
      }
    },
    ...mapState({
      walletAddress: (state:any) => state.wallet.walletAddress,
      isLoadingInfo:(state:any) =>state.market.isLoadingInfo,
      lendingMarket: (state:any) => state.market.lendingMarket,
      userLarixReward: (state:any) => state.market.userLarixReward,
      larixTokenAccount: (state:any) => state.larix.larixTokenAccount,
      userObligation:(state:any) => state.market.userObligation,
      mining:(state:any) => state.market.mining,
      allReservesDetails: (state:any) => state.market.allReservesDetails,
      lendingReserveArray: (state:any) => state.market.lendingReserveArray,
      larixPrice:(state:any)=>state.market.larixPrice,
      mineCollateralLpDetails:(state:any)=>state.market.mineCollateralLpDetails,
      userLarixPositions:(state:any)=>state.market.userLarixPositions,
      userBonfidaPoolDetails:(state:any)=>state.market.userBonfidaPoolDetails,
      userBonfidaMining:(state:any)=>state.market.userBonfidaMining,
      userBonfidaObligations:(state:any)=>state.market.userBonfidaObligations,
      userXSolPoolDetails:(state:any)=>state.market.userXSolPoolDetails,
      userXSolMining:(state:any)=>state.market.userXSolMining,
      userXSolObligations:(state:any)=>state.market.userXSolObligations,
      userObligationIndex:(state:any)=> state.market.userObligationIndex,
      userLaunchpadDetails: (state:any) => state.market.userLaunchpadDetails,
    })
  },
  methods: {
    setRewardMultiplier(multiplier:number){
      this.rewardMultiplier = multiplier
      this.sliderStakePercent = 0
      this.userStakeLarixAmount = new BigNumber(0)
      this.userTotalStakeLarixAmount = new BigNumber(0)
      this.needUSDC = new BigNumber(0)
    },
    getUserUsdcAccount (){
      return  this.allReservesDetails.find((item:any) => item.symbol === 'USDC').userAssetDetails.userLiquidityTokenAccount
    },
    sliderStakePercentChange(){
      // console.log('price',this.mineCollateralLpDetails.price.toString())
      this.userStakeLarixAmount = this.userLarixReward.times(this.sliderStakePercent/100)
      this.userTotalStakeLarixAmount = this.userLarixReward.times(this.sliderStakePercent/100).times(this.rewardMultiplier)
      this.needUSDC = this.userTotalStakeLarixAmount.times(this.larixStakeLpDetals.price).div(0.95)
      // console.log('needUSDC',this.needUSDC.toString())
      // console.log('userStakeLarixAmount',this.userStakeLarixAmount.toString())
    },
    stakeButtonDisable(){
      if (!this.userStakeLarixAmount.isGreaterThan(0)) return false
      if (!this.userUsdcBalance.isGreaterThan(0)) return false
      if (this.needUSDC.isGreaterThan(this.userUsdcBalance)) return false
      return true
    },
    getUserMining(){
      if (this.poolType==='main'){
        return this.mining
      }else{
        return  this.userLarixLaunchpadDetails.userMining
      }

    },
    getUserObligations(){
      if (this.poolType==='main'){
        return this.userObligation
      }else{
        return  this.userLarixLaunchpadDetails.userObligations
      }
    },
    getLendingMarket(){
      if (this.poolType==='main'){
        return LENDING_MARKET
      }else{
        return  this.userLarixLaunchpadDetails.lendingMarket
      }
    },
    claimReward: async function (poolType:string) {
      let wallet = getWallet()
      let that = this
      this.getUserMining()
      let lendingProgramID :PublicKey
      let reserveArray :Detail<Reserve>[]
      if (poolType==='main'){
        lendingProgramID = LENDING_PROGRAM_ID
        reserveArray = this.lendingReserveArray.filter((reserve:Detail<Reserve>)=>{
          return reserve.info.liquidity.poolType==='main'
        })
      }else{
        lendingProgramID = this.userLarixLaunchpadDetails.lendingProgramID
        reserveArray = this.lendingReserveArray.filter((reserve:Detail<Reserve>)=>{
          return reserve.info.liquidity.poolType===this.poolType
        })
      }
      // let hasLp
      openTxDialog('updateRewardDialogVisible')
      await claimMine(
          that.getUserMining(),
          that.getUserObligations(),
          wallet,
          that.getLendingMarket(),
          reserveArray,
          10000,
          lendingProgramID,
          that.larixTokenAccount?.pubkey,
      ).then(()=>{
        txSuccess('updateRewardDialogVisible')
      }).catch((e)=>{
        txFailed('updateRewardDialogVisible',e)
      })
      await appController.updateDataRefactor(this.userObligationIndex)
    },
    stakeLarix:async function(){
      let wallet = getWallet()
      let that = this
      const claimRatio = Number(this.sliderStakePercent.toFixed(2)) *100
      // let hasLp
      openTxDialog('updateRewardDialogVisible')
      await claimMineAndStake(
          that.mining,
          that.userObligation,
          that.getUserUsdcAccount(),
          wallet,
          that.lendingMarket,
          LENDING_PROGRAM_ID,
          that.lendingReserveArray,
          100*this.rewardMultiplier,
          claimRatio,
          that.larixTokenAccount?.pubkey,
      ).then(()=>{
        txSuccess('updateRewardDialogVisible')
      }).catch((e)=>{
        txFailed('updateRewardDialogVisible',e)
      })
    },
    getUnclaimedMineAmount:async function(){

    },
    goStakingList() {
      this.rewardDialogVisible = false
      this.$store.commit('updateLarixStakingDialogVisible', true)
    },
  },
})
