import {NeedToHandleCTokenAccounts, NeedToHandleUserFeeLarixInfo } from "../controller/type/userDetails";
import {WithdrawLpAccount} from "@/api/models/state/withdrawLpAccount";
import {Detail, Obligation, ObligationCollateral} from "@/api/models";
import {ReserveDetails} from "@/controller/type/reserveDetails";
import {getUserFeeLarixInfoByOwner} from "@/api/provider/userLarixInfoProvider";
import {UserLarixInfo} from "@/api/models/state/userLarixInfo";
import {eX} from "@/utils/helpers";
import { BigNumber } from "bignumber.js";
import store from '../store'

export class UnsettledAsset{
    public needToHandleUserFeeLarixInfo = [] as NeedToHandleUserFeeLarixInfo[];
    public needToHandleCTokenAccounts = [] as NeedToHandleCTokenAccounts [];
    public needToHandleLpAccounts = [] as Detail<WithdrawLpAccount>[];
    public userFeeLarixInfo: Detail<UserLarixInfo>[]
    private allReserveData : ReserveDetails[]
    private userAllObligation: Detail<Obligation>[]

    constructor(
        allReserveData: ReserveDetails[], needToHandleLpAccounts: Detail<WithdrawLpAccount>[]
        , userFeeLarixInfo: Detail<UserLarixInfo>[],
        userAllObligation: Detail<Obligation>[]
    ) {

        this.allReserveData = allReserveData
        this.needToHandleLpAccounts = needToHandleLpAccounts
        this.userFeeLarixInfo = userFeeLarixInfo
        this.userAllObligation = userAllObligation
        this.formatUnsettledAsset()
    }
    private formatUnsettledAsset(){
        this.formatNeedToHandleUserFeeLarixInfo()
        this.allReserveData.forEach((reserveDetails)=>{
            this.formatNeedToHandleLpAccounts(reserveDetails)
            this.formatNeedToHandleCTokenAccounts(reserveDetails)
        })
    }
    private formatNeedToHandleLpAccounts(reserveDetails:ReserveDetails){
        const needToWithdrawLpArray = [] as any
        this.needToHandleLpAccounts.forEach((pool:any)=>{
            if (new BigNumber(pool.info.amount).isGreaterThan(0)){
                needToWithdrawLpArray.push(pool)
            }
            if (!reserveDetails.isLP){
                return
            }
            else {
                needToWithdrawLpArray.forEach((item:any)=>{
                    if (item.info.poolId.equals(reserveDetails.reserveDetail.info.liquidity.params_1)){
                        item.info.symbol = reserveDetails.fullName
                        item.info.logoSource = reserveDetails.logoSource
                        item.info.amount = eX(item.info.amount.toString(),-reserveDetails.decimals)
                        item.info.userLiquidityTokenAccount = reserveDetails.userAssetDetails.userLiquidityTokenAccount
                        item.info.reserveDetails = reserveDetails.reserveDetail
                    }
                })
            }
        })
        this.needToHandleLpAccounts = needToWithdrawLpArray
    }
    private formatNeedToHandleCTokenAccounts(reserveDetails:ReserveDetails){
        //@ts-ignore
        if (new BigNumber(reserveDetails.userAssetDetails.userCollateralTokenAccount?.info.amount).isGreaterThan(0)){
            let target
            this.userAllObligation.forEach((userObligation:any)=>{
                userObligation.info?.deposits.forEach((deposit:ObligationCollateral)=>{
                    if (reserveDetails.reserveDetail.pubkey.equals(deposit.depositReserve)){
                        target = userObligation
                    }
                })
            })
            this.needToHandleCTokenAccounts.push({
                isInMining:reserveDetails.userAssetDetails.miningSupplyAmount.isGreaterThan(0),
                isEnterMarket:!!target,
                account:reserveDetails.userAssetDetails.userCollateralTokenAccount,
                logoSource:reserveDetails.logoSource,
                amount:eX(reserveDetails.userAssetDetails.userCollateralTokenAccount?.info.amount.toString()||'0',-1*Number(reserveDetails.decimals)),
                symbol:reserveDetails.fullName,
                userLiqiudityAccount:reserveDetails.userAssetDetails.userLiquidityTokenAccount,
                reserveDetail:reserveDetails.reserveDetail,
                mintPubkey:reserveDetails.reserveDetail.pubkey.toString(),
                targetObligation:target?target:null
            })
        }
    }
    private formatNeedToHandleUserFeeLarixInfo(){
        this.needToHandleUserFeeLarixInfo = this.userFeeLarixInfo.map((info:any)=>{
            const larixImg = require('../assets/reward_LARIX@2x.png')

            const larixAmount = eX(new BigNumber(info.info.larixAmount).toString(),-6)
            const symbol = 'LARIX'
            return{
                info,
                symbol,
                larixImg,
                larixAmount
            }
        })
    }
    public setVuex(){
        store.commit('updateNeedToHandleCtokenAccounts',this.needToHandleCTokenAccounts)
        store.commit('updateNeedToHanldeUserFeeLarixInfo',this.needToHandleUserFeeLarixInfo)
        store.commit('updateNeedToWithdrawLpArray',this.needToHandleLpAccounts)
    }

}