
export default {
    name: '中文',
    headers:{
        dashBoard:'首頁',
        market:'市場',
        liquidation:'清算',
        larix:'LARIX',
        Positions:'倉位',
        stake:'開倉',
        About:'關於',
        dao:'DAO',
        connect:'連接錢包',
        reward:'領取獎勵',
        launchpad:'啓動台',
        main:'主池'
    },
    reward:{
        larixBalance:'Larix餘額',
        larixRewards:'LARIX 獎勵',
        insufficientUsdcBalance:'USDC餘額不足',
        stake:'質押',
        stakeForLP:'質押生成LP',
        earn2x:'質押得{x}倍LARIX',
        claimDirectly:'直接領取 LARIX',
        myStaking: '我的質押',
        seeDetails: '查看詳情',
        youWillLose1: '直接領取，你將會失去',
        youWillLose2: '{x} LARIX的額外獎勵',
        reward:'獎勵',
        walletBalance:'* 錢包餘額:',
        maximumReceive:'最多可領取 {x} LARIX',
        expiredReward:'到期獎勵',
        days:'天',
        currentRewards:'當前獎勵',
        stakeTime:'質押時間',
        claimLARIX:'領取 LARIX',
        needUSDC:'需要的USDC ≈',
        chooseToReceive:'選擇領取',
        main:"主池",
    },
    wallet:{
        reward:'領取獎勵',
        connect:'連接錢包',
        faucet:'测试币水龙头',
        connectToAWallet:'連接錢包',
        yourWallet:'你的錢包地址',
        disconnect:'斷開連接',
        connected:'已連接',
        harvest:'領取',
        walletBalance: '錢包餘額',
        unclaimedBalance: '待領取獎勵',
        price: 'LARIX價格',
        nothingToClaim: '沒有可領取的',
        subAccount:'我的倉位',
    },
    footer:{
        doc:'文檔'
    },
    home:{
        totalSupply:'縂存款',
        totalBorrow:'縂借款',
        totalMined:'縂流通量',
        larixPrice:'Larix價格',
        supplyMarket:'存款市場',
        borrowMarket:'借款市場',
        myBill:'我的賬單',
        asset:'資產',
        collateral:'質押',
        allMarket:'所有市場',
        supplyAPY:'存款收益',
        wallet:'錢包',
        borrowAPY:'借款成本',
        liquidity:'流動性',
        operation:'操作',
        borrowBalance:'借款餘額',
        supplyBalance:'存款餘額',
        withdraw:"提現",
        repay:"還款",
        supply:"存款",
        borrow:"借款",
        loading:"正在加載數據",
        connectToAWallet:'連接錢包',
        liquidityMining:'流動性挖礦',
        totalAPR:'縂年利率',
        more:'更多',
        pool:'資金池',
        raydiumLP:'Raydium LP',
        unsettledAssets:'未結算的資產',
        handleTip:'交易未完成,請點擊這裏繼續 >',
        singleAsset:'單幣資產',
        myPosition:'我的倉位',
        tip:'提示',
        noBorrowFee:'沒有借款手續費',
    },
    supplyAndBorrow:{
        borrowLimit:"借款限額",
        netAPY:"净收益率",
        borrowBalance:'借款餘額',
        supplyBalance:'存款餘額',
        netValue:'资产淨值',
        borrowRatio:'借款率',
    },
    supplyDialog: {
        supply: "存款",
        withDraw: "提現",
        wallet: "錢包",
        balance: "餘額",
        walletBalance: "錢包餘額",
        liquidity: "流動性",
        max: "最大值",
        limit85: "85%額度限制",
        supplyApy: "存款收益",
        supplyBalance: "存款餘額",
        borrowLimit: "借入限額",
        supplyRate: "存款比例",
        withdrawRate: "取款比例",
        invalidNumber:"輸入無效",
        insufficientLiquidity:"流動性不足",
        stillWithdraw:"仍要提現",
        borrowLimitUsedPercent:"額度占用",
        collateralFactor:"質押因子",
        insufficientFunds:"餘額不足",
        solBalanceIsLow:'SOL餘額過低',
        cancel:'取消',
        interestRate: "利率",
        miningAPY: "挖礦收益",
        supplyMaxLimit100M:"最大縂存款限額為{x}",
        currentSupply:"當前縂存款",
        reachLpLimit:"達到當前子賬戶LP存款限制",
        reachLimit:"達到存款限制",
        baseReward:'基礎獎勵',
        boostedAPR:'提升后LARIX年利率',
        APR:'年利率',
        supplyMaxAPR:'最大 APR',
        baseAPR:'基礎 APR',
        baseLARIXRewards:'基礎 LARIX 獎勵',
        borrowingMinAPR:'最低 APR',
    },
    borrowDialog: {
        borrow: "借款",
        repay: "還款",
        wallet: "錢包",
        balance: "余餘額",
        walletBalance: "錢包餘額",
        liquidity: "剩餘可借",
        borrowMax: "最大值",
        Max:'最大值',
        borrowApy: "成本",
        borrowBalance: "借款額",
        borrowLimit: "借入限額",
        borrowRate: "借款比例",
        repayRate: "还款比例",
        borrowMaxInTokenUnit:"最高限額",
        borrowLimitUsedPercent:"額度占用",
        collateralFactor:"質押因子",
        invalidNumber:"輸入無效",
        insufficientLiquidity:"流動性不足",
        stillBorrow:"仍要借款",
        noBalanceToRepay:"沒有借款可償還",
        insufficientFunds:"餘額不足",
        cancel:'取消',
        interestRate: "利率",
        miningAPY: "挖礦收益",
        reachPositionLimit:"抵押和借款币种总数最多为5",
    },
    market:{
        supply:"存款",
        borrow:"借款",
        top3Markets:"排行前三",
        asset:"資產",
        totalSupply:"縂存款",
        supplyAPY:"存款利率",
        totalBorrow:"縂借款",
        borrowAPY:"借款利率",
        details:"查看詳情",
        supplyBorrow:'存借總額',
        loading:"正在加載數據",
        connectToAWallet:'連接錢包',
        totalSupplyApy:"縂存款/利率",
        totalBorrowApy:"縂借款/利率",
        depositLimit:"存款限額",
        supplyIR:'存款利率',
        borrowIR:'借款利率',
        supplyDistributionAPR:'存款挖礦 APR',
        borrowDistributionAPR:'借款挖礦 APR',
        supplyIRHelpContext:'存款APY = 存款利率 + 存款挖礦 APY',
        borrowIRHelpContext:'借款APY = 借款利率 - 借款挖礦 APY',
        stake:'質押',
        swap:'兌換',
    },
    marketId:{
        market:"市場",
        supply:"存款",
        borrow:"借款",
        netRate:"净利率",
        supplyAPY:"存款收益",
        distributionSupplyAPY:"存款挖礦APY",
        distributionBorrowAPY:"借款挖礦APY",
        totalSupply:"縂存款",
        borrowAPY:"借款成本",
        totalBorrow:"縂借款",
        interestRateModel:"利率模型",
        marketDetails:"市場詳情",
        Price:"價格",
        marketLiquidity:"市場流動性",
        collateralFactor:"質押因子",
        exchangeRate:"匯率",
        supplyMiningAPR:"存款挖礦年利率",
        borrowMiningAPR:"借款挖礦年利率",
        utilization:"使用率",
        supplyChartAPY:"存款利率",
        borrowChartAPY:"借款利率",
        mndeAPR:"年利率"
    },
    larix:{
        amountinCirculation:"流通量",
        circulatingMarketCap:"流通市值",
        totalSupply:"代幣總量",
        lARIXDistribution:"LARIX分配",
        market:"市場",
        perDay:"挖礦日產出",
        supplyMiningAPR:"存款挖礦年利率",
        borrowMiningAPR:"借款挖礦年利率",
        dailyDistribution:"每日分配",
        other:"其他",
        distribution:"分配",
        larixPrice:'Larix價格',
    },
    About:{
        aboutLarix:"關於 Larix",
        aboutLarixContext:"Larix是Solana上首個元宇宙金融服務平台，采用動態利率模型，創建資本高效的風險管理資金池，使得資產以安全的方式被充分使用。 此外，基於代幣經濟獎勵制度， Larix能夠持續激勵分配，調節實際借貸需求。 一切資產價值標的，如加密貨幣、穩定幣、合成資產、 NFTs和其他資產類型均將被納入資產抵押系列。",
        auditReport:"審計報告",
        whyLarix:"爲什麽選擇Larix",
        reason1:"第一個上綫挖礦的借貸協議",
        reason2:"第一個將會支持P2P NFT借貸的協議",
        reason3:"第一個開源的借貸協議",
        roadmap:"路徑圖",
        roadmapStep1:"第一階段: 1月-9月 2021",
        roadmapStep2:"第二階段: 2021年底-2022年",
        roadmapStep3:"第三階段：2022年以后",
        roadmapStep1Context:"Larix 將從傳統的超質押借貸模式開始，滿足Solana生態用戶的基礎借貸需求。 在這個階段, Larix將接受數字貨幣, 穩定幣,以及合成資產作爲抵押物.  Larix 在兼顧安全性和資金使用效率中找到平衡，增强了系統風險管理能力，降低了協議級別的潛在損失。",
        roadmapStep2Context:"与Pyth预言机合作，将高保真的 (HiFi)金融市场与去中心化金融 (DeFi) 世界连接起来。 该合作伙伴关系使 Solana 的参与者能够在可靠、灵敏、及时的市场数据支持下， 在 Larix 平台通过抵押如，股票、债券和更传统的基础资产等合成资产，获得 DeFi 类流流动性的加密资产标的。",
        roadmapStep3Context:"Larix 协议将扩大抵押物范围，接受非同质化代币 (NFT) 作为抵押，并允许所有抵押资产类别的点对点借贷： 数字钱包中的所有价值资产标的，都将以合理的价值作为释放流动性的抵押物。 Larix 将满足通过质押 NFT 资产来获得流动性和赚取收益的用户，及更多激进策略的需求。",
        seedRoundInvestor:'投資人',
        partnership:"生態夥伴",
        audit:"審計",
    },
    liquidation: {
        liquidation : '清算',
        liquidateTips : '清算提示',
        account: '賬戶',
        amountTip: '輸入您想還多少',
        assetToRepay :'償還的資產',
        assetsToRepay :'償還的資產',
        maxRepayAmount: '最高償還金額',
        assetToSeize : '獲取的資產',
        assetsToSeize : '獲取的資產',
        maxSeizeAmount : '最大可獲取金額',
        debtRatio : '負債率',
        action : '操作',
        liquidate : '清算',
    },
    noticeDialog: {
        notice : '通知',
        text : '挖礦開啟倒數計時',
        confirm: '我已知曉'
    },
    pendingDialog: {
        transactionPending: "等待交易",
        viewOnEtherscan: "查看交易",
        confirm: "交易確認中...",
        broadcast: "交易廣播中...",
        waitingEvm: "等待EVM結果...",
        errorTips:"交易失敗",
        checkTips:"交易成功"
      },
    handleLpDialog:{
        unsettledAssets:'未結算的資產',
        withdraw:'提現',
        keepMining:'繼續挖礦',
        tooltip:'請選擇要處理的訂單'
    },
    openObligationDialog:{
        openChoose:'創建或選擇一個倉位',
        usable:'可用倉位',
        unavailable:'沒有可用的倉位',
        rulesLine1:'* lp質押規則:',
        rulesLine2:'一個倉位只能質押一種LP資產',
        action:'創建新倉位並質押',
    },
    borrowFeeTipDialog:{
        tip:'提示',
        noBorrowFee:'Larix沒有借貸手續費',
        gotIt:'知道了',
    },
    larixStakingDialog:{
        expireDate:'到期時間',
        stakedLP:'已質押 LP',
        assets:'資產',
        amount:'數量',
        unlockingTime:'領取時間',
        stakeTime:'質押時間',
        actions:'操作',
        unlocking:'解鎖',
        oneClickClaim:'領取Raydium獎勵',
        claim:'領取',
        raydiumReward:'Raydium 獎勵',
    },
   tip:{
       borrowLimitTip:'借款限额是您可以借出资产的最大值，由橙色線條標記。存入的资产价值越大，借款限額越高',
       borrowBalanceTip:'借款餘額是您所借資產之和',
       supplyBalanceTip:'存款餘額是您所存資產之和。增加存款可提高借款限額',
       netAPRTip:'淨收益率=年總收益 /资产淨值 ( 年總收益=存款总收益-借款总利息 )',
       netValueTip:'资产淨值=存款餘額-借款餘額',
       borrowRatioTip:'借款率=借款總額/存款余额。借款率反应了您的资金使用率，上限是借款限額',
       liquidationTip:'帳戶觸發清算時的借款總額，由紅色線條標記。當借款總額>=清算線時，帳戶進入可被清算的狀態。增加存款或降低借款可以解除帳戶的清算狀態',
       supplyAPRTip:'存款所赚取的年收益率。存款APR是動態的，會隨著资金池中总存款的增加/減少而波動。存款收益=存款利息（本幣收益）+存款挖礦收益（LARIX收益）',
       borrowAPRTip:'借出資產所支付的年利息。借款APR是動態的，會隨著资金池中总借款的增加/減少而波動。借款成本=借款利息-借款挖礦收益（LARIX收益）',
       walletBalanceTip:'錢包餘額是您錢包裡各種資產对应的餘額。錢包餘額可用來支付借款及利息，或者繼續存入',
       liquidityTip:'流動性是指資產當前可供取出/借出的余额。充足的流動性也意味著您可以隨時取出您的存款',
       borrowFeeTip:'在Larix借款不會被收取砍頭費。砍頭費即借款費用，它不同於借款利息。砍頭費=借款金額*砍頭息，借款金額越大，砍頭息越高，砍頭費越大。砍頭費每次借款都會被收取',
       collateralTip:'存款之後打開質押按鈕才能借款，質押的資產價值越高，可借出的資產額度（即借款限額）就越高',
       availableTip:'您當前最多还可借出的資產',
       myRewardTip:'在Larix存/借單幣資產或者質押LP都可以獲得挖礦收益即LARIX代幣，這裏展示了您的存/借挖礦收益',
       myStakeTip:'您選擇x5/x10多倍獎勵時質押在Raydium的LARIX/USDC LP的數量',
       lockedDateTip:'選擇x5/x10多倍獎勵並質押LARIX/USDC LP的日期。在鎖倉期間（90天或者180天）您無法撤出質押的LARIX/USDC LP',
       unlockingDateTip:'質押滿90天/180天之後，可以領取5倍/10倍LARIX多倍獎勵并解锁LP的日期',
       stakeAmountTip:'選擇x5/x10多倍獎勵之後質押的LARIX/USDC LP數量',
       raydiumRewardTip:'選擇x5/x10多倍獎勵並添加LARIX/USDC流動性之後，您將會獲得流動性挖礦的LARIX獎勵，这部分獎勵您可以隨時在Larix平台領取，沒有鎖倉，點擊"Claim"按鈕即可全部領取。RAY部分的獎勵會在您撤出流動性之後發送到您的錢包',
       dailyTip:'挖礦日產出指該资产每日存款&借款所產出的LARIX代幣的总量',
       collateralFactorTip:'單位抵押物最大可借出的資產比率。例如，您存入價值100美金的USDC，最多可借出價值85美金的BTC，則USDC的質押因子為85%',
       larixRewards:'LARIX 獎勵',
       dialogAPR1X:'初始 1X APR',
       dialogAPR5X:'提升 5X APR',
       APRTip:'Optional boosted APR if LARIX rewards are staked for 10x',
       baseRewardTip:'Receive local currency APR and LARIX APR',
       boostedAPRTip:'Optional boosted APR if LARIX rewards are staked for 5x',
       maxAPRTip:'質押LARIX獲得10倍收益',
       baseAPRTip:'(本幣收益 + LARIX 獎勵)',
       baseAPRTip2:'(本幣利息 + LARIX 獎勵)',
       interest:'利息',
       doubleRewardTip:'({x}奖励将每隔24小时直接发送至您的钱包)',
       claimStakeRewardTip:"點擊任意'領取'按鈕以領取所有的LP獎勵(包括5倍和10倍).如果仍有未領取的獎勵，請繼續點擊'領取'，直到領取所有獎勵",
       liquidateTip:' 您只能在負債率大於100%時進行清算',
   },
    positions:{
        mySupply:'我的存款',
        myBorrow:'我的借款',
        myStake:'我的質押',
        myReward:'我的獎勵',
        liquidationThreshol:'清算門檻',
        claim:'領取 ',
        noSupplyYet:'暫無存款',
        noBorrowYet:'暫無借款',
        noStakeYet:'暫無質押',
        supplyNow:'立即存款',
        borrowNow:'立即借款',
        stakeNow:'立即質押',
    },
    launchpad:{
        whatLaunchpad:'什麽是Larix Lending Launchpad?',
        details1:'Larix Lending Launchpad 為原生的 SPL 代幣提供獨立的資金池。',
        details2:'Larix在現有的交叉抵押借貸池的基礎上推出隔離資金池，目的是爲了支持更多的代幣實現借貸的功能。',
        details3:'如果您想 Lending Launchpad 上綫任何新的資產，請不要猶豫，填寫申請表！',
        apply:'申請'
    }
  }
