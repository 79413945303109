export default {
    name: 'Türkçe',
    headers:{
        dashBoard:'Gösterge',
        market:'Pazar',
        liquidation:'Likidasyon',
        larix:'LARİX',
        Positions:'Pozisyonlar',
        stake:'Hasada Yatır',
        dao:'MOO',
        connect:'Cüzdan Bağla',
        About:'Hakkımızda',
        reward:'Ödül',
        launchpad:'Launchpad',
        main:'Main Pool'
    },
    reward:{
        larixBalance:'LARİX Bakiyesi',
        larixRewards:'LARIX Ödülleri',
        mSOLReward:'mSOL Ödülü',
        insufficientUsdcBalance:'Insufficient USDC balance',
        stake:'Bahis',
        stakeForLP:'LP Üretmek İçin Bahis',
        earn2x:'{x}X LARIX Ödülü Kazanmak için Bahis Yapın ',
        claimDirectly:'Talep ',
        myStaking: 'Stakingim',
        seeDetails: 'Ayrıntılara bakınız',
        youWillLose1: 'Hemen Talep Edin Ekstra {x} LARIX',
        youWillLose2: 'Ödülü Kazanma Şansınızı Kaybedeceksiniz',
        reward:'Ödüller',
        walletBalance:'* Cüzdan Bakiyesi :',
        maximumReceive:'MAX Talep Edilebilir {x} LARIX',
        expiredReward:'Kilitleme Süresinden Sonra Ödüller',
        days:'gün',
        currentRewards:'Mevcut Ödüller',
        stakeTime:'Kilitleme Süresi',
        claimLARIX:'Claim LARIX',
        needUSDC:'Estimated USDC ≈',
        chooseToReceive:'Choose to receive',
        main:"Main",
    },
    wallet:{
        reward:'Ödül',
        connect:'Cüzdan Bağla',
        faucet:'Musluk',
        connectToAWallet:'Cüzdana bağla',
        yourWallet:'Cüzdanın',
        disconnect:'Bağlantıyı Kes',
        connected:'Bağlandı',
        harvest:'HASADI TOPLA',
        walletBalance: 'Cüzdan Bakiyesi',
        unclaimedBalance: 'Talep Edilmemiş Bakiye',
        price: 'Fiyat',
        nothingToClaim: 'Talep edecek bir şey yok',
        subAccount :'My position',
        recentTransactions : 'Son İşlemler',
        wallet : 'Cüzdan'
    },
    footer:{
        doc:'Belge'
    },
    home:{
        totalSupply:'Toplam Arz',
        totalBorrow:'Toplam Borç',
        totalMined:'Toplam Bitmiş Madencilik',
        larixPrice:'Larix Fiyatı',
        supplyMarket:'Arz Piyasası',
        borrowMarket:'Borç Piyasası',
        myBill:'Faturam',
        asset:'Varlık',
        collateral:'Teminat',
        allMarket:'tüm pazarlar',
        supplyAPY:'Arz Yıllık Yüzde Getirisi',
        wallet:'Cüzdan',
        borrowAPY:"Borçlanma Yıllık Yüzde Getirisi",
        liquidity:"Likidite",
        operation:"Operasyon",
        borrowBalance:"Borç Bakiyesi",
        supplyBalance:"Arz Bakiyesi",
        withdraw:"Çek",
        repay:"Geri Ödeme",
        supply:"Arz",
        borrow:"Borç Almak",
        loading:"Yükleniyor",
        connectToAWallet:'Cüzdana bağla',
        liquidityMining:'likidite Madencilik',
        totalAPR:'toplam APR',
        more:'hepsi',
        pool:'sermaye havuzu',
        raydiumLP:'Raydium LP',
        unsettledAssets:'yerleşmemiş Varlıklar',
        handleTip:'Transaction undone, please click here to process >',
        singleAsset:'Tek Varlık',
        myPosition:'Pozisyonum',
        tip:'Ipucu',
        noBorrowFee:'Borç Ücreti Yok',
    },
    supplyAndBorrow:{
        borrowLimit:"Borç Limiti",
        netAPY:"Net Yıllık Yüzde Getiri",
        borrowBalance:"Borç Bakiyesi",
        supplyBalance:"Arz Bakiyesi",
        netValue:'Net Value',
        borrowRatio:'Borrow Ratio',
    },
    supplyDialog: {
        supply: "Arz",
        withDraw: "Çekme",
        wallet: "Cüzdan",
        balance: "Bakiye",
        walletBalance: "Cüzdan",
        liquidity: "Likidite",
        max: "MAKS",
        limit85: "%85 LİMİT",
        supplyApy: "Arz Yıllık Yüzde Getirisi",
        supplyBalance: "Arz Bakiyesi",
        borrowLimit: "Borç Limiti",
        supplyRate: "Arz Oranı",
        withdrawRate: "Çekme Oranı",
        invalidNumber:"Geçersiz Numara",
        insufficientLiquidity:"Likidite Yetersiz",
        stillWithdraw:"Yine de Çek",
        borrowLimitUsedPercent:"Borç Limiti Kullanım Oranı",
        collateralFactor:"Teminat Faktörü",
        insufficientFunds:"Yetersiz Bakiye",
        solBalanceIsLow:'SOL Denge düşük',
        cancel:'İptal',
        interestRate: "Faiz Oranı",
        miningAPY: "Madencilik Yıllık Yüzde Getirisi",
        supplyMaxLimit100M:"Maksimum arz miktarı {x} ile sınırlıdır",
        currentSupply:"Current supply amount is",
        reachLpLimit:"Reach LP deposit Limit",
        reachLimit:"Reach deposit Limit",
        baseReward:'Temel ödül',
        boostedAPR:'Artırılmış LARIX APR',
        APR:'APR',
        supplyMaxAPR:'Max APR',
        baseAPR:'Base APR',
        baseLARIXRewards:'Base LARIX rewards',
        borrowingMinAPR:'Min APR',
    },
    borrowDialog: {
        borrow: "Borç Almak",
        repay: "Geri Ödeme",
        wallet: "Cüzdan",
        balance: "Bakiye",
        walletBalance: "Cüzdan",
        liquidity: "Likidite",
        borrowMax: "MAKS",
        max:'MAKS',
        borrowApy: "Borçlanma Yıllık Yüzde Getirisi",
        borrowBalance: "Borç Bakiyesi",
        borrowLimit: "Borç Limiti",
        borrowRate: "Borçlanma Oranı",
        repayRate: "Geri Ödeme Oranı",
        borrowMaxInTokenUnit:"Maksimum Borçlanma",
        borrowLimitUsedPercent:"Borç Limiti Kullanım Oranı",
        collateralFactor:"Teminat Faktörü",
        invalidNumber:"Geçersiz Numara",
        insufficientLiquidity:"Yetersiz Likidite",
        stillBorrow:"Yine de Borç Al",
        noBalanceToRepay:"Geri Ödenecek Bakiye Yok",
        insufficientFunds:"Yetersiz Bakiye",
        cancel:'İptal',
        interestRate: "Faiz Oranı",
        miningAPY: "Madencilik Yıllık Yüzde Getirisi",
        reachPositionLimit:'Her hesap, aynı anda toplam 5 teminat ve borçlanma etkileşimi ile sınırlıdır.',
    },
    market:{
        supply:"Arz",
        borrow:"Borç",
        top3Markets:"İlk 3 Pazar",
        asset:"Varlık",
        totalSupply:"Toplam Arz",
        supplyAPY:"Arz Yıllık Yüde Getirisi",
        totalBorrow:"Toplam Borç",
        borrowAPY:"Borç Yıllık Yüzde Getirisi",
        details:"Detaylar",
        supplyBorrow:'Toplam Piyasa Değeri',
        loading:"Yükleniyor",
        connectToAWallet:'Bir cüzdana bağlanın',
        totalSupplyApy:"Toplam Arz/Yıllık Yüzde Getirisi",
        totalBorrowApy:"Toplam Borç/Yıllık Yüzde Getirisi",
        depositLimit:"Mevduat Yatırma Limiti",
        supplyIR:'Arz Faizi',
        borrowIR:'Borç Faizi',
        supplyDistributionAPR:'Arz Dağıtımı YYO',
        borrowDistributionAPR:'Borç Dağıtımı YYO',
        supplyIRHelpContext:'Arz YYG = Arz Faiz + Arz Madenciliği YYO',
        borrowIRHelpContext:'Borç YYG = Borç Faiz - Borç Madencilik YYO',
        stake:'Stake',
        swap:'Swap',
    },
    marketId:{
        market:"Pazar",
        supply:"Arz",
        borrow:"Borç",
        netRate:"Net YYG",
        supplyAPY:"Arz YYG",
        distributionSupplyAPY:"Dağıtım Arz YYG",
        distributionBorrowAPY:"Dağıtım Borç YYG",
        totalSupply:"Toplam Arz",
        borrowAPY:"Borçlanma YYG",
        totalBorrow:"Toplam Borç",
        interestRateModel:"Faiz Oran Modeli",
        marketDetails:"Piyasa Detayları",
        Price:"Fiyat",
        marketLiquidity:"Pazar Likiditesi",
        collateralFactor:"Teminat Faktörü",
        exchangeRate:"Takas Kuru",
        supplyMiningAPR:"Arz Madenciliği  YYO",
        borrowMiningAPR:"Borç Madenciliği YYO",
        utilization:"Kullanım",
        supplyChartAPY:"Arz YYG",
        borrowChartAPY:"Borç YYG",
        mndeAPR:"YYO"
    },
    larix:{
        amountinCirculation:"Dolaşımdaki Tutar",
        circulatingMarketCap:"Dolaşımdaki Piyasa Değeri",
        totalSupply:"Toplam Tutar",
        lARIXDistribution:"LARİX Dağıtımı",
        market:"Pazar",
        perDay:"Günlük Madencilik",
        supplyMiningAPR:"Arz Madenciliği YYO",
        borrowMiningAPR:"Borç Madenciliği YYO",
        dailyDistribution:"Günlük Dağıtım",
        other:"Diğer",
        distribution:"Dağıtım",
        larixPrice:'Larix Fiyatı',
    },
    About:{
        aboutLarix:"Larix Hakkında",
        aboutLarixContext:"Larix, Solana'daki ilk Metaverse tabanlı Finans Protokolüdür. Dinamik bir faiz oran modelini benimsemiştir ve çok çeşitli teminat türleri, kripto tokenleri, sabit koinler, sentetik varlıklar, NFT'ler ve diğer varlık türleri gibi daha fazla sermaye için verimli risk yönetimi havuzları yaratmıştır. Tamamen güvenli bir şekilde kullanılabilir.",
        auditReport:"Teftiş Raporu",
        whyLarix:"Neden Larix",
        reason1:"Canlı madencilik işlevine sahip ilk borç verme protokolü",
        reason2:"NFT'lerin kredi vermesini destekleyecek ilk kredi protokolü",
        reason3:"Solana' açık kod kaynaklı ilk borç verme protokolü",
        roadmap:"Yol Haritası",
        roadmapStep1:"Faz 1: Haziran – Eylül 2021",
        roadmapStep2:"Faz 2: 2021 ve 2022 arasında",
        roadmapStep3:"Faz 3: 2022 ve sonrasında",
        roadmapStep1Context:"Larix, otomatikleştirilmiş defi kredilendirmesinin temel ihtiyaçlarını karşılamakla başlar. Bu aşamada kripto tokenlerı, sabit koinler ve sentetik varlıklar teminat olarak kabul ediliyor. Risk yönetimi ve varlık güvenliği, Larix'in temel taşlarıdır.",
        roadmapStep2Context:"Yüksek kaliteli (HiFi) finans piyasalarını, merkeziyetsiz finans (Defi) dünyasına bağlamak için PYTH ağı ile ortaklık kurdu. Bu daha geniş bir yelpazedeki geleneksel finansal varlıkların daha yüksek sermaye kullanımını mümkün kılıyor.",
        roadmapStep3Context:"Protokol, teminat tabanını, tüm varlık sınıflarında eşler arası ödünç verme yoluyla Değiştirilebilir Tokenleri (NFT'ler) kabul edecek şekilde genişletiyor. Larix, gerçek ekonomiye güvenli, özelleştirilebilir ve neredeyse anında likidite sağlamak için birden fazla ortakla birlikte çalışacak.",
        seedRoundInvestor:"Yatırımcılarımız ",
        partnership:"Ortaklar",
        audit:"Denetim",
    },
    liquidation: {
        liquidation : 'Tasfiye',
        liquidateTips : 'Tasfiye İpuçları',
        account: 'Hesap',
        amountTip: 'Geri ödemek istediğiniz tutar',
        assetToRepay :'Geri Ödenecek Varlık',
        assetsToRepay :'Geri Ödenecek Varlıklar',
        maxRepayAmount: 'Maksimum Geri Ödeme Tutarı',
        assetToSeize : 'Ele Geçirilecek Varlık',
        assetsToSeize : 'Ele Geçirilecek Varlıklar',
        maxSeizeAmount : 'Maksimum gelir',
        debtRatio : 'Borç Oranı',
        action : 'Eylem',
        liquidate : 'Likide et',
    },
    noticeDialog: {
        notice : 'Bildirim',
        text : 'Madenciliğe başlamak için geri sayım',
        confirm: 'Onayla'
    },
    pendingDialog: {
        transactionPending: "Bekliyor",
        viewOnEtherscan: "Heco'da Görüntüle",
        confirm: "İşlem onaylanıyor...",
        broadcast: "İşlem yayını...",
        waitingEvm: "Ethereum sanal makinesi sonuçları bekleniyor...",
        errorTips:"İşlem Başarısız",
        checkTips:"İşlem Başarılı"
    },
    handleLpDialog:{
        unsettledAssets:'yerleşmemiş Varlıklar',
        withdraw:'Çek',
        keepMining:'madencilik',
        tooltip:'Lütfen işlemek istediğiniz bir siparişi seçin'
    },
    openObligationDialog:{
        openChoose:'Bir alt hesap oluşturun veya seçin',
        usable:'kullanılabilir alt hesap:',
        unavailable:'Kullanılabilir alt hesap yok',
        rulesLine1:'* lp Teminat kural:',
        rulesLine2:'Bir alt hesap yalnızca bir LP varlığı rehin edebilir',
        action:'Yeni bir hesap ve teminat açın',
    },
    borrowFeeTipDialog:{
        tip:'Ipucu',
        noBorrowFee:"Larix'te ödünç alma ücreti yoktur",
        gotIt:'Anladım',
    },
    larixStakingDialog:{
        expireDate:'Expire date',
        stakedLP:'Stake Edilen LP',
        assets:'Varlıklar',
        amount:'Miktar',
        unlockingTime:'Kilit Açma Süresi',
        stakeTime:'Locked Date',
        actions:'Eylemler',
        unlocking:'Unlocking',
        oneClickClaim:'Claim LARIX rewards from Raydium',
        claim:'Claim',
        raydiumReward:'Raydium Ödül',
    },
    tip:{
        borrowLimitTip:'Borç Limiti borçlanılabilecek en yüksek varlık değerini gösterir. Bu portakal çubukla gösteriliyor. Ne kadar fazla arz edilen varlık varsa, borç limitide o kadar yüksek olur',
        borrowBalanceTip:'Borç bakiyesi bütün borç alınan varlıkların toplamıdır',
        supplyBalanceTip:'Arz bakiyesi, bütün arz varlıkların toplamıdır. Mevduatınızın (arz) artması, borç alma limitinizi yükseltir',
        netAPRTip:'Net YYO = Toplam Yıllık Getiri / Net Değer ( Toplam Yıllık Getiri = Toplam Arz Getirisi – Toplam Borç Tutarı(YYO – Yıllık Yüzdelik Oran))',
        netValueTip:'Net Değer = Arz Bakiyesi – Borç Bakiyesi',
        borrowRatioTip:'Borç Oranı = Toplam Borç / Arz Bakiyesi. Borç oranı varlılarınızın kullanım oranını gösterir',
        liquidationTip:'Hesap likidasyonu tetiklediğindeki toplam borç miktarı. Bu kırmızı çubuk ile gösterilir. Toplam borç miktarı => likidasyon eşiği; hesap likidasyon olabileceği bir duruma girer. Mevduatın (arz) arttırılması yada borçların düşürülmesi hesabınızı likidasyondan kurtarabilir',
        supplyAPRTip:'Yıllık Yüzdelik Oran (YYO) arz edilen varlıklardan sağlanır. YYO’lar dinamiktir ve havuzdaki toplam arz arttık/azaldıkça değişir',
        borrowAPRTip:'Yıllık Yüzdelik Oran (YYO) borç olarak alınan varlıklara karşı ödenir. YYO’lar dinamiktir ve havuzdaki toplam arz arttık/azaldıkça değişir.Borç YYO = borç faizi (spesifik varlık borçlanma maliyeti) – borçlanma madencilik getirisi (Larix coin)',
        walletBalanceTip:'Cüzdan bakiyesi hesabınızdaki değişik varlıkların bakiyesidir. Cüzdan bakiyesi varlık arzı yada borç ödemesinde yada ilgili faiz ödemelerinde kullanılabilir',
        liquidityTip:'Likidite bir varlığın çekim/borç alım için kullanılabilecek miktarıdır',
        borrowFeeTip:'Larix’in sıfır borç alma ücret politikası vardır. Borç alma ücreti, borç almalarda kullanılan faizden farklı ve ekstra bir ücrettir. Borç Alma Ücreti = Borç alınan miktar * Borç ücret oranı. Yüksek bir borç alma miktarı, yüksek bir borç alma ücretine yol açar. Borç alma ücreti her borç alma işlemi yapıldığında kesilir',
        collateralTip:'Borç almak için, varlık yatırdıktan (arz) sonra teminat butonunu açmanız gerekir. Varlığın yatırılma değeri ne kadar fazlaysa, o kadar fazla borç alınabilir',
        availableTip:'Maksimum mevcut borçlanılabilecek varlık',
        myRewardTip:'Tekil varlık arz-etme/borçlanma yada LP yatırılıp madencilik getirisi (Larix token ile) sağlanabilir, arz-etme/borçlanma madencilik getirileri',
        myStakeTip:' x5/x10 Larix ödülleri seçtiğinizde Raydium’daki LARIX/USDC LP hasad miktarı',
        lockedDateTip:' x5/x10 Larix ödülleri seçtiğinizdeki tarih. Kilitli Larix’lerinizi kitlenme dönemi boyunca çekemezsiniz (90-180 gün)',
        unlockingDateTip:' x5/x10 Larix ödülleri seçtiğinizde, ödüllerin çekilebilme zamanı',
        stakeAmountTip:'x5/x10 Larix ödülleri seçildikten sonra hasaddaki LARIX/USDC LP miktarı',
        raydiumRewardTip:'x5/x10 Larix ödülleri seçilip LARIX/USDC likiditesi sağlandıktan sonra likidite madenciliği ile LARIX ödülü alacaksınız. Bu ödülleri Larix’ te kilit zamanı olmadan istediğiniz zaman çekebilir/talep edebillirsiniz. Sadece “Çek” butonuna tıklamanız yeterli. Ödüllerin RAY token ile yapılan kısmı likidite Raydium’dan çekildikten sonra alınabilir',
        dailyTip:'Arz-edilen/Borç alınan varlıklardan günlük LARIX token kazancı',
        collateralFactorTip:'Spesifik bir varlığın en yüksek borçlanılabilecek oranı. Örnek olarak, USDC teminat faktörü %85; eğer 100 dolarlık USDC mevduat yatırdıysanız, 85 dolarlık borçlanma yapabilirsiniz',
        larixRewards:'LARIX Ödül',
        dialogAPR1X:'İlk 1X APR',
        dialogAPR5X:'Artırılmış 5X APR',
        APRTip:'Optional boosted APR if LARIX rewards are staked for 10x',
        baseRewardTip:'Receive local currency APR and LARIX APR',
        boostedAPRTip:'Optional boosted APR if LARIX rewards are staked for 5x',
        maxAPRTip:'(10x boosted staking)',
        baseAPRTip:'(Supply APR + LARIX rewards)',
        baseAPRTip2:'(Borrow APR - LARIX rewards)',
        interest:'interest',
        doubleRewardTip:'({x} ödülleri her 24 saatte bir doğrudan cüzdanınıza dağıtılacaktır)',
        claimStakeRewardTip:'Click any \'Claim\' button to claim all LP rewards (5x and 10x included).  If there\'s still remaining unclaimed rewards, keep clicking \'Claim\' until all rewards are claimed.',
        liquidateTip:'Borç oranı %100\'ü aştığında tasfiyeler meydana gelir.',
    },
    positions:{
        mySupply:'Kaynağım',
        myBorrow:'Benim borcum',
        myStake:'Benim hissem',
        myReward:'Benim ödülüm',
        liquidationThreshol:'Tasfiye Eşiği',
        claim:'Alacak ',
        noSupplyYet:'Henüz Tedarik Yok',
        noBorrowYet:'Henüz Borç Yok',
        noStakeYet:'Henüz Bahis Yok',
        supplyNow:'Şimdi yatır',
        borrowNow:'Şimdi Ödünç Alın',
        stakeNow:'Şimdi Bahis Yap',
    },
    launchpad:{
        whatLaunchpad:'Larix Lending Launchpad nedir?',
        details1:'Larix Lending Launchpad, yerel SPL belirteçleri için ayrı bir finansman havuzu sağlar.',
        details2:'Larix, borç verme işlevini gerçekleştirmek için daha fazla tokeni desteklemek için mevcut çapraz teminatlı borç verme havuzuna dayalı izole bir fon havuzu başlattı.',
        details3:'Lending Launchpad\'de yeni varlıkları listelemek isterseniz, lütfen başvuru formunu doldurmaktan çekinmeyin!',
        apply:'Uygulamak'
    }
}
