import { TokenAccount } from "@/api/models/state/tokenAccount";
import {ReserveDetails} from "@/controller/type/reserveDetails";
import BigNumber from "bignumber.js";
import {UserAssetDetails, UserDetails} from "../controller/type/userDetails";
import {Detail, LendingMarket, Obligation, Reserve} from "@/api/models";
import {BIG_NUMBER_ONE, eX} from "@/utils/helpers";
import {Mining} from "@/api/models/state/mining";
import Positions from "@/views/Positions.vue";
import { Position } from "@/api/models/state/position";
import { LarixLockPool } from "@/api/models/state/larixLockPool";
import store from "@/store";
import {calculateAllMine} from "@/api/utils/calculateAllMine";
import {UserLaunchpadData} from "@/factory/userLaunchpadData";
import {UserLaunchpadObligationIndexDetail} from "@/controller/appController";
//format userAsset
export class UserData {
    public userAllTokenAccounts: Map<string, TokenAccount[]>;
    public allReserveDetails : ReserveDetails[];
    public userAllObligations : Array<Detail<Obligation>>;
    public userObligationIndex :number;
    public userAllMining:Detail<Mining>[];
    public userLaunchpadMining: Map<string, Detail<Mining>[]>;
    public userLaunchpadObligation: Map<string, Detail<Obligation>[]>;
    public lendingMarket :Detail<LendingMarket>
    public lendingReserveArray:Detail<Reserve>[];
    public userBorrowLimit = new BigNumber(0);
    public userTotalBorrow = new BigNumber(0);
    public userTotalSupply = new BigNumber(0);
    public userLarixReward = new BigNumber(0)
    private yearSupplyInterest = new BigNumber(0);
    private yearBorrowInterest = new BigNumber(0);
    private yearMiningInterest = new BigNumber(0);
    private userLariTokenAccount: TokenAccount[] | undefined = undefined
    public userLiquidationThreshold = new BigNumber(0);
    public userNetApr = new BigNumber(0)
    public userDetails = {} as UserDetails
    public userAssetDetails = {} as UserAssetDetails
    constructor(
        allReserveDetails:ReserveDetails[],
        userAllTokenAccounts:Map<string, TokenAccount[]>,
        userAllObligations:Array<Detail<Obligation>>,
        userAllMining:Detail<Mining>[],
        userLaunchpadMining: Map<string, Detail<Mining>[]>,
        userLaunchpadObligation: Map<string, Detail<Obligation>[]>,
        userObligationIndex:number,
        lendingReserveArray:Detail<Reserve>[],
        lendingMarket:Detail<LendingMarket>
    ) {
        this.allReserveDetails = allReserveDetails
        this.userAllTokenAccounts = userAllTokenAccounts
        this.userAllObligations = userAllObligations
        this.userObligationIndex = userObligationIndex
        this.userAllMining = userAllMining
        this.userLaunchpadMining = userLaunchpadMining
        this.userLaunchpadObligation = userLaunchpadObligation
        this.lendingReserveArray = lendingReserveArray
        this.userDetails.userAllAssetDetails = {} as UserAssetDetails []
        this.lendingMarket = lendingMarket
        // this.userDetails.userBonfidaPoolDetails = {
        //     userBorrowLimit:new BigNumber(0),
        //     netValue:new BigNumber(0),
        //     netApr:new BigNumber(0),
        //     userLiquidationThreshold:new BigNumber(0),
        //     yearSupplyInterest:new BigNumber(0),
        //     yearBorrowInterest:new BigNumber(0),
        //     yearMiningInterest:new BigNumber(0),
        //     userTotalSupply:new BigNumber(0),
        //     userTotalBorrow:new BigNumber(0),
        //     userLarixReward:new BigNumber(0),
        // }
        // this.userDetails.userXsolPoolDetails = {
        //     userBorrowLimit:new BigNumber(0),
        //     netValue:new BigNumber(0),
        //     netApr:new BigNumber(0),
        //     userLiquidationThreshold:new BigNumber(0),
        //     yearSupplyInterest:new BigNumber(0),
        //     yearBorrowInterest:new BigNumber(0),
        //     yearMiningInterest:new BigNumber(0),
        //     userTotalSupply:new BigNumber(0),
        //     userTotalBorrow:new BigNumber(0),
        //     userLarixReward:new BigNumber(0),
        // }
        this.getUserAssetDetails()
        this.getUserOtherDetails()
    }
    private getUserAssetDetails(){
        const userAllAssetDetails = [] as any
        const userLaunchpadObligationIndexDetails = store.state.market.userLaunchpadObligationIndexDetails as UserLaunchpadObligationIndexDetail[]
        this.allReserveDetails.map((reserveDetails)=>{
            this.userAssetDetails = {} as UserAssetDetails
            const info = reserveDetails.reserveDetail.info
            const poolType = info.liquidity.poolType
            let mining
            let userObligation
            if (poolType==='main'){
                mining = this.userAllMining?.[0]
                userObligation = this.userAllObligations?.[this.userObligationIndex]
            }else {

                const targetPool = userLaunchpadObligationIndexDetails.find((detail)=>detail.poolType===poolType)
                mining = this.userLaunchpadMining.get(poolType)?.[0]
                if (targetPool){
                    userObligation = this.userLaunchpadObligation.get(poolType)?.[targetPool.obligationIndex]
                }else {

                    userObligation = this.userLaunchpadObligation.get(poolType)?.[0]
                }

            }
            const liquidityMintPubkey = info.liquidity.mintPubkey.toString()
            const collateralMintPubkey = info.collateral.mintPubkey.toString()
            const decimals = info.liquidity.mintDecimals
            //TODO check this change
            const isEnterMarket = userObligation?userObligation.info.deposits.filter(liquidity=>liquidity.depositReserve.equals(reserveDetails.reserveDetail.pubkey)).length>0:false
            const userLiquidityTokenAccount = this.userAllTokenAccounts.get(liquidityMintPubkey)?.[0]
            const userCollateralTokenAccount = this.userAllTokenAccounts.get(collateralMintPubkey)?.[0]
            const liquidationThreshold = info.config.liquidationThreshold / 100
            const walletBalanceInTokenUnit = eX(userLiquidityTokenAccount?.info.amount.toString()||'0',-1*Number(decimals))
            const walletBalance = walletBalanceInTokenUnit.times(reserveDetails.liquidityPrice)
            let miningSupplyAmount = eX(
                mining?.info.miningIndices.filter(
                    miningIndex=>miningIndex.reserve.equals(reserveDetails.reserveDetail.pubkey)
                )[0]?.unCollLTokenAmount.toString()||'0',

                -1*Number(decimals)
            )
            const depositAmount = eX(
                userObligation?.info.deposits.filter(
                    liquidity => liquidity.depositReserve.equals(reserveDetails.reserveDetail.pubkey)
                )[0]?.depositedAmount.toString() || '0',
                -1 * Number(decimals)
            )
            if (miningSupplyAmount.isGreaterThan(0)&&depositAmount.isGreaterThan(0)){
                if (isEnterMarket&&depositAmount.isGreaterThan(0)){
                    miningSupplyAmount = new BigNumber(0)
                }
            }
            const collateralTokenBalanceInTokenUnit = miningSupplyAmount.plus(depositAmount)
            // @ts-ignore
            let userCumulativeBorrowRateWads = new BigNumber(userObligation?.info.borrows.filter(item =>{
                return item.borrowReserve.equals(reserveDetails.reserveDetail.pubkey)
            })[0]?.cumulativeBorrowRateWads)
            userCumulativeBorrowRateWads = (!userCumulativeBorrowRateWads.isNaN()?eX(userCumulativeBorrowRateWads.toString(),-(18)):new BigNumber(0))
            const compoundedInterestRate = userCumulativeBorrowRateWads.isZero()?new BigNumber(0):reserveDetails.reserveCumulativeBorrowRateWads.div(userCumulativeBorrowRateWads)
            const supplyBalanceInTokenUnit = (reserveDetails.mintTotalSupply.isZero()||reserveDetails.totalLiquidityAmount.isZero())?collateralTokenBalanceInTokenUnit.times(BIG_NUMBER_ONE):collateralTokenBalanceInTokenUnit.div(reserveDetails.mintTotalSupply).times(reserveDetails.totalLiquidityAmount)
            const supplyBalance = supplyBalanceInTokenUnit.times(reserveDetails.liquidityPrice)
            let borrowBalanceInTokenUnit = eX(userObligation?.info.borrows.filter(liquidity=>liquidity.borrowReserve.equals(reserveDetails.reserveDetail.pubkey))[0]?.borrowedAmountWads.toString()||'0',-1*Number(decimals)-18)

            borrowBalanceInTokenUnit = borrowBalanceInTokenUnit.times(compoundedInterestRate)
            const borrowBalance = borrowBalanceInTokenUnit.times(reserveDetails.liquidityPrice)
            this.userAssetDetails.borrowBalance = borrowBalance
            this.userAssetDetails.borrowBalanceInTokenUnit  = borrowBalanceInTokenUnit
            this.userAssetDetails.supplyBalance = supplyBalance
            // console.log(reserveDetails.symbol,'mining',mining)
            // console.log(reserveDetails.symbol,'obligation',userObligation)
            this.userAssetDetails.supplyBalanceInTokenUnit  = supplyBalanceInTokenUnit
            this.userAssetDetails.walletBalance = walletBalance
            this.userAssetDetails.walletBalanceInTokenUnit = walletBalanceInTokenUnit
            this.userAssetDetails.reserveSymbol = reserveDetails.symbol
            this.userAssetDetails.isEnterMarket = isEnterMarket
            this.userAssetDetails.collateralFactor = reserveDetails.collateralFactor
            this.userAssetDetails.liquidationThreshold = reserveDetails.liquidationThreshold
            this.userAssetDetails.userCollateralTokenAccount = userCollateralTokenAccount
            this.userAssetDetails.userLiquidityTokenAccount = userLiquidityTokenAccount
            this.userAssetDetails.miningSupplyAmount = miningSupplyAmount
            this.userAssetDetails.poolType  = poolType
            if (reserveDetails.poolType==='main'){
                this.userTotalBorrow = this.userTotalBorrow.plus(borrowBalance)
                this.userTotalSupply = this.userTotalSupply.plus(supplyBalance)
            }
            userAllAssetDetails.push(this.userAssetDetails)
            reserveDetails.userAssetDetails = this.userAssetDetails
        })
        this.userDetails.userAllAssetDetails = userAllAssetDetails

    }
    private getUserOtherDetails() {
        this.allReserveDetails.forEach((reserveDetails) => {
            this.accumulativeUserInterest(reserveDetails)
            this.getUserBorrowLimit(reserveDetails.userAssetDetails)
            this.getUserLiquidationThreshold(reserveDetails.userAssetDetails)
        })
        this.getUserNetApr()
        this.getUserLarixAccount()
        this.getUserLarixReward()
        const userLaunchpadData = new UserLaunchpadData(this.allReserveDetails,this.userLaunchpadMining,this.userLaunchpadObligation,this.lendingReserveArray)
        userLaunchpadData.setVuex()
    }
    private getUserBorrowLimit(userAssetDetails:UserAssetDetails){
        if (userAssetDetails.poolType==='main'){
            this.userBorrowLimit = this.userBorrowLimit.plus(
                userAssetDetails.isEnterMarket
                    ? userAssetDetails.supplyBalance.times(userAssetDetails.collateralFactor)
                    : 0
            )
        }
    }
    private getUserLiquidationThreshold(userAssetDetails:UserAssetDetails){
        if (userAssetDetails.poolType==='main'){
            this.userLiquidationThreshold = this.userLiquidationThreshold.plus(
                userAssetDetails.isEnterMarket
                    ? userAssetDetails.supplyBalance.times(userAssetDetails.liquidationThreshold)
                    : 0
            )
        }
    }
    private accumulativeUserInterest(reserveDetails:ReserveDetails){
        if (reserveDetails.isLP){
            // TODO: lp double reward
            this.yearSupplyInterest = this.yearSupplyInterest.plus(reserveDetails.userAssetDetails.supplyBalance.times(reserveDetails.lpInfo.lpApr.div(100).plus(reserveDetails.lpInfo.doubleRewardApy)))
            this.yearSupplyInterest = this.yearSupplyInterest.plus(reserveDetails.userAssetDetails.supplyBalance.times(reserveDetails.lpInfo.lpFeesApr/100))
        }else if(!reserveDetails.isLP&&reserveDetails.poolType==='main'){
            this.yearSupplyInterest = this.yearSupplyInterest.plus(reserveDetails.userAssetDetails.supplyBalance.times(reserveDetails.supplyApy))
            this.yearSupplyInterest = this.yearSupplyInterest.plus(
                reserveDetails.userAssetDetails.supplyBalance.times( reserveDetails.singleTokenDoubleRewardApy||0)
            );
            this.yearBorrowInterest =  this.yearBorrowInterest.minus(
                reserveDetails.userAssetDetails.borrowBalance.times( (reserveDetails.singleTokenDoubleBorrowRewardApy||new BigNumber(0)).div(100))
            )
            this.yearBorrowInterest =  this.yearBorrowInterest.plus(
                reserveDetails.userAssetDetails.borrowBalance.times( reserveDetails.borrowApy)
            );
            this.yearMiningInterest = this.yearMiningInterest.plus(
                reserveDetails.userAssetDetails.supplyBalance.times(reserveDetails.supplyDistributionApy)
            ).plus(
                reserveDetails.userAssetDetails.borrowBalance.times(reserveDetails.borrowDistributionApy)
            )
        }
    }
    private getUserNetApr(){
        const netValue = this.userTotalSupply.minus(this.userTotalBorrow)
        const netApy = !this.userTotalSupply.isZero()
            ? this.yearSupplyInterest
                .minus(this.yearBorrowInterest)
                .div(netValue)
                .toFixed(4)
            : 0
        // 计算存借款挖矿年收益
        const miningApy = !this.userTotalSupply.isZero()
            ? this.yearMiningInterest
                .div(netValue)
                .toFixed(4)
            : 0
        this.userNetApr = new BigNumber(netApy).plus(miningApy)
    }
    public getUserLarixReward(){
        const result = calculateAllMine(
            this.userAllMining?.[0],
            this.userAllObligations?.[this.userObligationIndex],
            this.lendingReserveArray.filter((reserve)=>{
                return reserve.info.liquidity.poolType==='main'
            })
        )
        this.userLarixReward =  result
    }
    private getUserLarixAccount(){
        this.userLariTokenAccount = this.userAllTokenAccounts.get(this.lendingMarket.info.mineMint.toString());
    }

    public setVueX(){
        store.commit('updateUserObligation', this.userAllObligations?.[this.userObligationIndex])
        store.commit('updateMining', this.userAllMining?.[0])
        store.commit('updateAllMining', this.userAllMining)
        store.commit('updateUserAllObligation', this.userAllObligations)
        // store.commit('updateUserBonfidaMining', this.userBonfidaMining?.[0])
        // store.commit('updateUserBonfidaObligations', this.userBonfidaObligations[0])
        // store.commit('updateUserXSolMining', this.userXSolMining?.[0])
        // store.commit('updateUserXSolObligations', this.userXSolObligations[0])

        store.commit('updateUserTotalSupply',this.userTotalSupply)
        store.commit('updateUserTotalBorrow',this.userTotalBorrow)
        store.commit('updateUserLarixReward',this.userLarixReward)
        store.commit('updateUserBorrowLimit',this.userBorrowLimit)
        store.commit('updateUserLiquidationThreshold',this.userLiquidationThreshold)
        store.commit('updateNetRate',this.userNetApr)
        store.commit('updateUserLarixReward', this.userLarixReward)
        store.commit('updateLarixTokenAccount',this.userLariTokenAccount)
        // store.commit('updateUserBonfidaPoolDetails',this.userDetails.userBonfidaPoolDetails)
        // store.commit('updateUserXSolPoolDetails',this.userDetails.userXsolPoolDetails)
    }
}
