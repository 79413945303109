import {Account, PublicKey, Signer, TransactionInstruction} from "@solana/web3.js";
import {getConnection, sendTransaction} from "@/api/context/connection";
import {Token} from "@solana/spl-token";
import {ensureSplAccount, getOrCreateAssociatedTokenAccount} from "@/api/actions/utils/account";
import {TokenAccount} from "@/api/models";
import BN from "bn.js";
import {WalletAdapter} from "@/api/wallets";
import { stakeStSolInstructions } from "../models/instructions/stakeStSolInstructions";
import {getWallet} from "@/api/context/wallet";
import {eX} from "@/utils/helpers";
import { stakeStSolLiquidity } from "./utils/stakeStSolLiquidity";


export const stakeStSol = async (
    wallet:WalletAdapter,
    liquidityAmount: BN,
) =>{
    const connection = await getConnection()
    const signers: Account[] = [];
    const instructions: TransactionInstruction[] = [];
    const cleanupInstructions: TransactionInstruction[] = [];
    // const balanceNeeded = await Token.getMinBalanceRentForExemptAccount(connection)
    // const sourcePubkey = ensureSplAccount(
    //     instructions,
    //     cleanupInstructions,
    //     source,
    //     wallet.publicKey!!,
    //     liquidityAmount.toNumber()+balanceNeeded,
    //     signers
    // )
    const associatedTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        instructions,
        wallet.publicKey!!,
        new PublicKey("7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj"),
        wallet.publicKey!!
    )
    stakeStSolLiquidity(
        instructions,
        wallet.publicKey!!,
        associatedTokenAccount,
        liquidityAmount
    )
    await sendTransaction(
        connection,
        wallet,
        instructions.concat(cleanupInstructions),
        signers,
        true,
    )
}