import { BigNumber } from "bignumber.js"
import { PublicKey } from "@solana/web3.js";
import {ReserveDetails} from "@/controller/type/reserveDetails";
import store from "@/store";
let lastUpdateTimes : number
export class StakeSolProvider{
    stSOLExchangeRate  = new BigNumber(0);
    stSOLApy = new BigNumber(0);
    mSOLExchangeRate = new BigNumber(0);
    mSOLApy = new BigNumber(0);
    xSOLReserveDetails:ReserveDetails[];
    solPrice:BigNumber;


    constructor(xSOLReserveDetails:ReserveDetails[]) {
        this.xSOLReserveDetails = xSOLReserveDetails
        this.solPrice = xSOLReserveDetails.find(item=>item.symbol==='SOL')!!.liquidityPrice
    }

    public async getStakeInfo (){
        const nowUpdateTimes = new Date().valueOf()
        if (!lastUpdateTimes){
            await Promise.all([
                this.getStSOLApy(),
                this.getMSOLApy()
            ])
            this.getStSOLExchangeRate()
            this.getMSOLExchangeRate()
            const stakeSOLInfo = {
                mSOLApy:this.mSOLApy,
                stSOLExchangeRate:this.stSOLExchangeRate,
                stSOLApy:this.stSOLApy,
                mSOLExchangeRate:this.mSOLExchangeRate
            }
            store.commit('updateStakeSOLInfo',stakeSOLInfo)
            lastUpdateTimes = new Date().valueOf()
        }
        if (nowUpdateTimes-lastUpdateTimes<1200000){
            return
        }

    }

    private getStSOLExchangeRate(){
        const stSOLPrice = this.xSOLReserveDetails.find(item =>item.symbol==='stSOL')!!.liquidityPrice
        this.stSOLExchangeRate = new BigNumber(stSOLPrice).div(this.solPrice)
    }

    private async getStSOLApy(){
        try {
            const response = await fetch(
                "https://solana.lido.fi/api/apy/apy?days=14"
            );
            const result = await response.json()
            this.stSOLApy = new BigNumber(result.annual_percentage_yield)
        }catch (e) {
            this.stSOLApy = new BigNumber(0)
        }
    }


    private async getMSOLApy(){

        try {
            const response = await fetch(
                "https://api.marinade.finance/msol/apy/1y"
            );
            const result = await response.json()
            this.mSOLApy = new BigNumber(result.value).times(100)
        }catch (e) {
            this.mSOLApy = new BigNumber(0)
        }
    }

    private getMSOLExchangeRate(){
        const mSOLPrice = this.xSOLReserveDetails.find(item =>item.symbol==='mSOL')!!.liquidityPrice
        this.mSOLExchangeRate = new BigNumber(mSOLPrice).div(this.solPrice)
    }
}