
import {defineComponent} from "vue";
import {mapState} from "vuex";
import {getWallet} from "@/api/context/wallet";
import {withdrawLp} from "@/api/actions/withdrawLp";
import openTxDialog from "@/controller/openTxDialog";
import txSuccess from "@/controller/txSuccess";
import txFailed from "@/controller/txFailed";
import {handleCToken} from "@/api/actions/handleCToken";
import {useI18n} from "vue-i18n";
import {stakeLarixUserInfo} from "@/api/actions/stakeLarixUserInfo";
import {PublicKey} from "@solana/web3.js";
import {
  LENDING_MARKET,
  LENDING_PROGRAM_ID,
  LENDING_PROGRAM_ID_BONFIDA,
  LENDING_PROGRAM_ID_XSOL
} from "@/api/constants/config";
import {Detail, Reserve} from "@/api/models";
import {UserLaunchpadDetails} from "@/factory/userLaunchpadData";

export default defineComponent({
name: "HandleLpDialog",
  data(){
    return{
      isCToken:true,
      selectLPDetails:null ,
      selectCTokenDetails:null,
    }
  },
  setup(){
    const { t } = useI18n()
    return {
      t,
    }
  },
  computed: {
    handleLpDialogVisible:{
      //@ts-ignore
      get(){
        //@ts-ignore
        return this.$store.state.dialog.handleLpDialogVisible
      },
      set(value:boolean) {
        this.$store.commit('updateHandleLpDialogVisible',value)
      }
    },
    ...mapState({
      walletAddress: (state:any) => state.wallet.walletAddress,
      isLoadingInfo:(state:any) =>state.market.isLoadingInfo,
      lendingMarket: (state:any) => state.market.lendingMarket,
      larixTokenAccount: (state:any) => state.larix.larixTokenAccount,
      userObligation:(state:any) => state.market.userObligation,
      userAllObligation:(state:any) => state.market.userAllObligation,
      mining:(state:any) => state.market.mining,
      allReservesDetails: (state:any) => state.market.allReservesDetails,
      lendingReserveArray: (state:any) => state.market.lendingReserveArray,
      needToHandleCtokenAccounts: (state:any) => state.market.needToHandleCtokenAccounts,
      needToWithdrawLpArray: (state:any) => state.market.needToWithdrawLpArray,
      needToHanldeUserFeeLarixInfo: (state:any) => state.market.needToHanldeUserFeeLarixInfo,
      mineCollateralLpDetails: (state:any) => state.market.mineCollateralLpDetails,
      userXSolMining:(state:any)=>state.market.userXSolMining,
      userBonfidaMining:(state:any)=>state.market.userBonfidaMining,
      userLaunchpadDetails: (state:any) => state.market.userLaunchpadDetails,
    //
    })
  },
  methods:{
    getLendingMarket(reserveDetail:Detail<Reserve>){
      if (reserveDetail.info.liquidity.poolType==='main'){
        return LENDING_MARKET
      }else{
        return this.userLaunchpadDetails.find((details:UserLaunchpadDetails)=>details.poolType===reserveDetail.info.liquidity.poolType)!.lendingMarket
      }
    },
    getUserMining(reserveDetail:Detail<Reserve>){
      if (reserveDetail.info.liquidity.poolType==='main'){
        // console.log('return main')
        return this.mining
      }else{
        // console.log('return larix')
        return this.userLaunchpadDetails.find((details:UserLaunchpadDetails)=>details.poolType===reserveDetail.info.liquidity.poolType)!.userMining
      }
    },
    changeTabs(value:boolean){
      this.isCToken=value
    },
    needUsdc(userLarixInfo:any){
      return userLarixInfo.larixAmount.times(this.mineCollateralLpDetails[0].price).div(0.95)
    },
    getUserUsdcAccount (){
      return  this.allReservesDetails.find((item:any) => item.symbol === 'USDC').userAssetDetails.userLiquidityTokenAccount
    },
    stakeButtonEnable(){
      if (!this.getUserUsdcAccount()) return false
      return true
    },
    async withdrawLpProof(lpPoofDetails:any){
      const wallet = getWallet()
      openTxDialog('updateHandleLpDialogVisible')
      await withdrawLp(
          lpPoofDetails.info,
          wallet,
          lpPoofDetails.pubkey,
          lpPoofDetails.info.reserveDetails,
          lpPoofDetails.info.userCollateralTokenAccount
      ).then(()=>{
        txSuccess()
      }).catch((e)=>{
        txFailed(undefined,e)
      })
    },
    async stakeLarix(userLarixInfo:any){
      const wallet = getWallet()
      openTxDialog('updateHandleLpDialogVisible')
      await stakeLarixUserInfo(
        this.getUserUsdcAccount(),
          wallet,
          userLarixInfo.info
      ).then(()=>{
        txSuccess()
      }).catch((e)=>{
        txFailed(undefined,e)
      })
    },
    async handleCTokenAsset(type:string,cTokenDetails:any){
      let targetObligation
      if (type==='depositObligation'&&cTokenDetails.isEnterMarket){
         targetObligation = cTokenDetails.targetObligation
        console.log(targetObligation)
      }
      const reserveDetail = cTokenDetails.reserveDetail
      let lendingProgaramId :PublicKey
      const poolType = reserveDetail.info.liquidity.poolType
      if (poolType==='main'){
        lendingProgaramId = LENDING_PROGRAM_ID
      }else if(poolType==='xSol'){
        lendingProgaramId = LENDING_PROGRAM_ID_XSOL
      }else {
        lendingProgaramId = LENDING_PROGRAM_ID_BONFIDA
      }
      // console.log('lendingProgaramId',lendingProgaramId.toString())
      const lendingMarket = this.getLendingMarket(cTokenDetails.reserveDetail)
      const userMining = this.getUserMining(cTokenDetails.reserveDetail)
      // console.log('after get user mining')
      const wallet = await getWallet()
      openTxDialog('updateHandleLpDialogVisible')
      await handleCToken(
          cTokenDetails.account,
          userMining,
          reserveDetail,
          lendingMarket,
          wallet,
          type,
          lendingProgaramId,
          targetObligation?targetObligation:undefined,
          cTokenDetails.userLiqiudityAccount,
      ).then(()=>{
        txSuccess()
      }).catch((e)=>{
        txFailed(undefined,e)
      })
    }
  },
})
