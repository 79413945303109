
import { useI18n } from 'vue-i18n'
import { defineComponent } from 'vue'
import {mapState} from "vuex";
import {formatDollars, getBigNumberStr, formatBigNumberStr, formatDollarsKmb} from "@/utils/helpers";
import BigNumber from "bignumber.js";
import Dashboard from "@/components/Echarts/Dashboard.vue";
import appController from "@/controller/appController";
import {ReserveDetails} from "@/controller/type/reserveDetails";
export default defineComponent({
  name: "SupplyAndBorrow",
  components:{
    Dashboard
  },
  data() {
    return {
      refreshDiff:0,
      icon_information: require('../assets/icon_information.svg'),
    }
  },
  props: {
    pageType:{
      type:String,
      require:true,
    },
    poolType:{
      type:String,
      require:true,
    }
  },
  setup() {
    const {t} = useI18n()
    return {
      t,
      getBigNumberStr: getBigNumberStr,
      formatDollars: formatDollars,
      formatDollarsKmb: formatDollarsKmb,
      formatBigNumberStr:formatBigNumberStr,

    }
  },
  methods:{
    async activeRefresh(){
      await appController.updateDataRefactor(this.userObligationIndex)
    },
    getNetRate(){
      if (this.netRate){
        return this.netRate.times(100)
      }else {
        return new BigNumber(0)
      }

    },
  },
  mounted() {
    setInterval(()=>{
      this.refreshDiff = new Date().valueOf()
    },1000)
  },
  computed: {
    poolBackground(){
      switch (this.poolType){
        case 'xSol':{
          return 'xSolBg'
        }
        case 'bonfida':{
          return 'bonfidaBg'
        }
        case 'larix':{
          return 'larixBg'
        }
        case 'stepn':{
          return 'stepnBg'
        }
        case 'step':{
          return 'stepBg'
        }
        default:{
          return 'mainBg'
        }
      }
    },
    userLarixLaunchpadDetails(){
      // console.log('this.userLaunchpadDetails',this.userLaunchpadDetails)
      //@ts-ignore
      return this.userLaunchpadDetails.find((details)=>details.poolType===this.poolType)
    },
    userPoolBorrowLimit(){
      if (this.poolType==='main'){
        return this.userBorrowLimit
      } else{
        //@ts-ignore
        return this.userLarixLaunchpadDetails?.userBorrowLimit || new BigNumber(0)
      }
    },
    userPoolLiquidationThreshold(){
      if (this.poolType==='main'){
        return this.userLiquidationThreshold
      }else {
        //@ts-ignore
        return this.userLarixLaunchpadDetails?.userLiquidationThreshold || new BigNumber(0)
      }
    },
    allMarketSupplyAndBorrow:function ():BigNumber {
      if (this.poolType==='main'){
        return this.allMarketTotalSupply.plus(this.allMarketTotalBorrow).plus(this.launchpadTotalBorrow).plus(this.launchpadTotalSupply)
      }
        else {
          let currentPoolSupplyAndBorrow = new BigNumber(0)
        this.allReservesDetails.filter((reserve:ReserveDetails) => this.poolType===reserve.poolType).forEach((reserve:ReserveDetails)=>{
          currentPoolSupplyAndBorrow = currentPoolSupplyAndBorrow.plus(reserve.totalLiquidityInUsd).plus(reserve.totalBorrowedInUsd)
        })
        return currentPoolSupplyAndBorrow
      }
    },
    poolTotalSupply():BigNumber{
      if (this.poolType==='main'){
        return this.allMarketTotalSupply.plus(this.launchpadTotalSupply)
      }else {
        let currentPoolTotalSupply = new BigNumber(0)
        this.allReservesDetails.filter((reserve:ReserveDetails) =>
            this.poolType === reserve.poolType).forEach((reserve:ReserveDetails) => {
          currentPoolTotalSupply = currentPoolTotalSupply.plus(reserve.totalLiquidityInUsd)
        })
        return currentPoolTotalSupply
      }
    },
    poolTotalBorrow():BigNumber{

      if (this.poolType==='main'){
        return this.allMarketTotalBorrow.plus(this.launchpadTotalBorrow)
      }else {
        let currentPoolTotalBorrow = new BigNumber(0)
            this.allReservesDetails.filter((reserve:ReserveDetails) =>
            this.poolType === reserve.poolType).forEach((reserve:ReserveDetails) => {
              currentPoolTotalBorrow = currentPoolTotalBorrow.plus(reserve.totalBorrowedInUsd)
            })
        return currentPoolTotalBorrow
      }

    },
    refreshProgress:function () :number{
      //@ts-ignore
      const diffValue = (this.refreshDiff - this.autoFreshTime)/1000
      if (diffValue %16 ===0&&this.autoFreshTime!==0){
        return 100
      }else if (this.autoFreshTime===0) {
        return 0
      }else {
        return Number((diffValue / 16 * 100).toFixed(0))
      }
    },
    ...mapState(
        {
          walletAddress: (state: any) => state.wallet.walletAddress,
          userTotalSupply: (state: any) => state.market.userTotalSupply,
          userTotalBorrow:(state:any) => state.market.userTotalBorrow,
          userBorrowLimit:(state:any) => state.market.userBorrowLimit,
          netRate:(state:any) => state.market.netRate,
          isLoadingInfo:(state:any) =>state.market.isLoadingInfo,
          autoFreshTime:(state:any) => state.market.autoFreshTime,
          userLiquidationThreshold: (state: any) => state.market.userLiquidationThreshold,
          userLarixPositions:(state:any)=>state.market.userLarixPositions,
          allMarketTotalSupply:(state:any) => state.market.allMarketTotalSupply,
          allMarketTotalBorrow:(state:any) => state.market.allMarketTotalBorrow,
          userObligationIndex:(state:any)=> state.market.userObligationIndex,
          isLoadingUserDetails:(state:any)=>state.market.isLoadingUserDetails,
          userBonfidaPoolDetails:(state:any)=>state.market.userBonfidaPoolDetails,
          userXSolPoolDetails:(state:any)=>state.market.userXSolPoolDetails,
          userLaunchpadDetails: (state:any) => state.market.userLaunchpadDetails,
          launchpadTotalSupply:(state:any)=>state.market.launchpadTotalSupply,
          launchpadTotalBorrow:(state:any)=>state.market.launchpadTotalBorrow,
          allReservesDetails: (state: any) => state.market.allReservesDetails,
        }
    )
  },
})
