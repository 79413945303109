
// @ts-nocheck
import {defineComponent} from "vue";
import {useI18n} from "vue-i18n";

//@ts-ignore
import SupplyAndBorrow from "../components/SupplyAndBorrow";
//@ts-ignore
import SupplyDialog from "../components/SupplyDialog";
//@ts-ignore
import BorrowDialog from "../components/BorrowDialog";
import {mapState} from "vuex";
import {formatBigNumberStr, formatDollars, formatDollarsKmb, getBigNumberStr} from "@/utils/helpers";
import {UserLaunchpadDetails} from "@/factory/userLaunchpadData";

export default defineComponent({
name: "Launchpad",
  components:{
    SupplyAndBorrow,SupplyDialog,
    BorrowDialog,
  },
  data(){
     return {
       dexType:'Bonfida',
       icon_information: require('../assets/icon_information.svg'),
       getBigNumberStr: getBigNumberStr,
       formatDollars: formatDollars,
       formatDollarsKmb: formatDollarsKmb,
       formatBigNumberStr:formatBigNumberStr,
       noFee:require('../assets/icon_nofee@2x.png'),
       enableFor: '',
       selectedReserveDetail: {},
       larixLogo:require('../assets/Larix.ico.png'),
       poolType:'larix',
     }
  },
  setup(){
    const { t } = useI18n()
    return {
      t,
    }
  },
  computed:{
    userLaunchpadTotalLarixReward(){
      return this.userLaunchpadDetails.filter((details)=>details.poolType===this.poolType)
    },
    walletDialogVisible:{
      get() {
        return this.$store.state.wallet.walletDialogVisible
      },
      set(value) {
        this.$store.commit('updateWalletDialogVisible',value)
      }
    },
    ...mapState({
      walletAddress: (state: any) => state.wallet.walletAddress,
      lendingMarket: (state: any) => state.market.lendingMarket,
      lendingReserveArray: (state: any) => state.market.lendingReserveArray,
      userObligation: (state: any) => state.market.userObligation,
      allReservesDetails: (state: any) => state.market.allReservesDetails,
      isLoadingInfo:(state:any) =>state.market.isLoadingInfo,
      allMarketTotalSupply:(state:any) =>state.market.allMarketTotalSupply,
      allMarketTotalBorrow:(state:any) =>state.market.allMarketTotalBorrow,
      isFullWithdraw:(state:any)=>state.dialog.isFullWithdraw,
      isFullRepay:(state:any)=>state.dialog.isFullRepay,
      mining:(state:any)=>state.market.mining,
      larixCirculation:(state:any)=>state.market.larixCirculation,
      larixPrice:(state:any)=>state.market.larixPrice,
      userTotalSupply: (state: any) => state.market.userTotalSupply,
      userObligationIndex:(state:any)=> state.market.userObligationIndex,
      errorContext:(state:any)=> state.txDialog.errorContext,
      isLoadingUserLarixStakeInfo: (state:any) => state.market.isLoadingUserLarixStakeInfo,
      mineCollateralLpDetails: (state:any) => state.market.mineCollateralLpDetails,
      isLoadingUserDetails: (state:any) => state.market.isLoadingUserDetails,
      userLaunchpadDetails: (state:any) => state.market.userLaunchpadDetails as UserLaunchpadDetails[],
    })
  },
  methods:{
    sortMarket(){
      return this.allReservesDetails.filter((reserve:any)=>{
        switch (this.poolType){
          case "xSol":{
            return reserve.poolType ==='xSol'
          }
          case "bonfida":{
            return reserve.poolType ==='bonfida'
          }
          case "larix":{
            return reserve.poolType ==='larix'
          }
          case "stepn":{
            return reserve.poolType ==='stepn'
          }
          case "step":{
            return reserve.poolType ==='step'
          }
          default:{
            return reserve.poolType ==='larix'
          }
        }
      }).sort((b:any,a:any)=>{
        if (a.sortWeight- b.sortWeight ===0){
          if (a.isLP&&b.isLP){
            return a.totalLiquidityInUsd - b.totalLiquidityInUsd
          }else {
            return a.totalBorrowedInUsd - b.totalBorrowedInUsd
          }
        }
        else {
          return a.sortWeight - b.sortWeight
        }
      })
    },
    openSupplyMarketsDialog(reserveDetail:any,status:any){
      if (this.isLoadingUserDetails){
        this.walletDialogVisible = true
      }else {
        this.enableFor = status
        this.selectedReserveDetail = reserveDetail
        this.$store.commit('updateSelectedReserveDetail', reserveDetail)
        this.$store.commit('updateSupplyDialogVisible', true)
        this.$store.commit('updateIsFullWithdraw',false)
      }

    },
    openBorrowMarketsDialog(reserveDetail:any,status:any){
      if (this.isLoadingUserDetails){
        this.walletDialogVisible = true
      }else {
        this.enableFor = status
        this.selectedReserveDetail = reserveDetail
        this.$store.commit('updateSelectedReserveDetail', reserveDetail)
        this.$store.commit('updateBorrowDialogVisible', true)
        this.$store.commit('updateIsFullRepay',false)
      }
    },
  }
})
