import {UpdateDataNoWallet} from "@/state/updateDataNoWallet";
import {Detail, LendingMarket, Obligation, Reserve, TokenAccount} from "@/api/models";
import {LpApyProvider, LpFeeApyProvider} from "@/api/provider/lpApyProvider";
import {ReserveDetails} from "@/controller/type/reserveDetails";
import { UserData } from "../factory/userData";
import {Mining} from "@/api/models/state/mining";
import {WithdrawLpAccount} from "@/api/models/state/withdrawLpAccount";
import {UserLarixInfo} from "@/api/models/state/userLarixInfo";
import {UnsettledAsset} from "@/factory/unsettledAsset";
import {UserLarixPositionDetails} from "@/factory/userLarixPositionDetails";
import {Position} from "@/api/models/state/position";
import {LarixLockPool} from "@/api/models/state/larixLockPool";
import store from "@/store";
export class UpdateDataWithWallet {
    public allReserveDetails:ReserveDetails[] = [];
    public userAllObligations:Detail<Obligation>[];

    public userLaunchpadMining: Map<string, Detail<Mining>[]>;
    public userLaunchpadObligation: Map<string, Detail<Obligation>[]>;

    private reserveArrayInner:Detail<Reserve>[];
    // private allPrice:any;
    // private allDecimals:any;
    constructor(
        reserveArrayInner:Detail<Reserve>[],
        // larixPrice:number,
        // allPrice:any,
        // allDecimals:any,
        allReserveDetails:ReserveDetails[],
        userAllObligation:Detail<Obligation>[],
        userLaunchpadMining: Map<string, Detail<Mining>[]>,
        userLaunchpadObligation: Map<string, Detail<Obligation>[]>,
        // userBonfidaObligations:Detail<Obligation>[],
        // userXSolObligations:Detail<Obligation>[],
        // userLarixObligations:Detail<Obligation>[]
    ) {
        this.allReserveDetails = allReserveDetails
        this.userAllObligations = userAllObligation

        this.userLaunchpadMining = userLaunchpadMining
        this.userLaunchpadObligation = userLaunchpadObligation
        // this.userBonfidaObligations  = userBonfidaObligations
        // this.userXSolObligations = userXSolObligations
        // this.userLarixObligations = userLarixObligations
        this.reserveArrayInner = reserveArrayInner

        // this.allPrice = allPrice
        // this.allDecimals = allDecimals
    }
    public getUserData(
        userAllTokenAccounts:Map<string, TokenAccount[]>,
        userAllMining:Detail<Mining>[],
        userObligationIndex:number,
        lendingMarket:Detail<LendingMarket>
        ){
        const userDataController = new UserData(
            this.allReserveDetails,
            userAllTokenAccounts,
            this.userAllObligations,
            userAllMining,
            this.userLaunchpadMining,
            this.userLaunchpadObligation,
            userObligationIndex,
            this.reserveArrayInner,
            lendingMarket

        )
        userDataController.setVueX()
        return userDataController
    }
    public getUserUnsettledAsset(
        allReserveData:ReserveDetails[],needToHandleLpAccounts:Detail<WithdrawLpAccount>[]
        ,userFeeLarixInfo:Detail<UserLarixInfo>[],
    ){
        const userUnsettledAsset = new UnsettledAsset(
            this.allReserveDetails,
            needToHandleLpAccounts,
            userFeeLarixInfo,
            this.userAllObligations
        )
        userUnsettledAsset.setVuex()
        return userUnsettledAsset
    }
    public getUserLarixPositionDetails(userLarixPositions :Detail<Position>[],mineCollateralLp : any,lockLarixPool : LarixLockPool){
        const userlatixPositionDetails = new UserLarixPositionDetails(
            userLarixPositions,
            mineCollateralLp,
            lockLarixPool
        )
        userlatixPositionDetails.setVuex()
        return userlatixPositionDetails
    }
}