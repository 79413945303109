
import { useI18n } from 'vue-i18n'
import { defineComponent } from 'vue'
import {mapState} from 'vuex'
import BigNumber from "bignumber.js";
import {formatDollars, formatDollarsKmb, getBigNumberStr, formatBigNumberStr} from "@/utils/helpers";
import Dashboard from "@/components/Echarts/Dashboard.vue";
import {getWallet} from "@/api/context/wallet";
import {exitObligation} from "@/api/actions/exitObligation";
import txSuccess from "@/controller/txSuccess";
import txFailed from "@/controller/txFailed";
import {intoObligation} from "@/api/actions/intoObligation";
import appController from "@/controller/appController";
import {ObligationCollateral} from "@/api/models";
import {
  LP_RESERVE_ID_ARRAY,
} from "@/api/constants/config";
import {WalletAdapter} from "@/api/wallets";
import openTxDialog from "@/controller/openTxDialog";
import {withdrawPosition} from "@/api/actions/withdrawPosition";
import {claimPosition} from "@/api/actions/claimPosition";
import SupplyDialog from "@/components/SupplyDialog.vue";
import BorrowDialog from "@/components/BorrowDialog.vue";
import OpenObligationDialog from "@/components/OpenObligationDialog.vue";
import {UserLaunchpadDetails} from "@/factory/userLaunchpadData";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
export default defineComponent({
  name: "StepnPositions",
  components:{
    Dashboard,SupplyDialog,
    BorrowDialog,OpenObligationDialog
  },
  setup(){
    const { t } = useI18n()
    const $q = useQuasar()
    return {
      t,
      $q
    }
  },
  data() {
    return {
      getBigNumberStr: getBigNumberStr,
      formatDollars: formatDollars,
      formatDollarsKmb: formatDollarsKmb,
      formatBigNumberStr:formatBigNumberStr,
      viewPort:0,
      noFee:require('../assets/icon_nofee@2x.png'),
      larixLogo:require('../assets/Larix.ico.png'),
      MNDELogo:require('../assets/coin/asset_MNDE.svg'),
      icon_information: require('../assets/icon_information.svg'),
      selectedReserveDetail: {} as any,
      larixImg: require('../assets/reward_LARIX@2x.png'),
      usdcImg: require('../assets/coin/asset_USDC.svg'),
      enableFor: '',
      positionType:'mySupply',
    }
  },
  created(){
    window.addEventListener('resize', this.ViewPortAndStyle)
    this.ViewPortAndStyle()
  },

  computed: {
    userBorrowRatio():number|BigNumber{
      if(!this.isLoadingUserDetails){
        return this.userLarixLaunchpadDetails.userTotalSupply.isGreaterThan(0)?Number(this.userLarixLaunchpadDetails.userTotalBorrow.div(this.userLarixLaunchpadDetails.userTotalSupply).times(100).toFixed(2)):0
      }else {
        return new BigNumber(0)
      }
    },
    userBorrowLimitRatio():number{
      if(!this.isLoadingUserDetails){
        return this.userLarixLaunchpadDetails.userTotalSupply.isGreaterThan(0)?Number(this.userLarixLaunchpadDetails.userBorrowLimit.div(this.userLarixLaunchpadDetails.userTotalSupply).times(100).toFixed(2)):0
      }else {
        return 0
      }

    },
    userLiquidationThresholdRatio():number{
      if(!this.isLoadingUserDetails){
        return this.userLarixLaunchpadDetails.userLiquidationThreshold.isGreaterThan(0)?Number(this.userLarixLaunchpadDetails.userLiquidationThreshold.div(this.userLarixLaunchpadDetails.userTotalSupply).times(100).toFixed(2)):0
      }else {
        return 0
      }

    },
    positionsTotalRewardAmount:function (){
      let totalAmount = new BigNumber(0)
      if (this.userLarixPositions.length>0){
        this.userLarixPositions.forEach((position:any)=>{
          totalAmount = totalAmount.plus(position.larixRewardAmount)
        })
      }
      return totalAmount

    },
    userTotalStakeLp(){
      let totalLp = new BigNumber(0)
      if (this.userLarixPositions.length>0){
        this.userLarixPositions.forEach((position:any)=>{
          totalLp = totalLp.plus(position.lpAmount)
        })
      }
      return totalLp
    },
    mySupply(){
      const supplyDetails = this.allReservesDetails as []
      if (this.walletAddress!==''&&!this.isLoadingUserDetails){
        return supplyDetails.
        filter((item:any)=> item.userAssetDetails.supplyBalance.isGreaterThan(0)&&item.poolType==='stepn').sort((b:any,a:any)=>{
          return a.userAssetDetails.supplyBalance - b.userAssetDetails.supplyBalance
        })
      }else {
        return []
      }

    },
    myBorrow(){
      const borrowDetails = this.allReservesDetails as []
      if (this.walletAddress!==''&&!this.isLoadingUserDetails){
        return borrowDetails.
        filter((item:any)=> item.userAssetDetails.borrowBalance.isGreaterThan(0)&&item.poolType==='stepn').sort((b:any,a:any)=>{
          return a.userAssetDetails.borrowBalance - b.userAssetDetails.borrowBalance
        })
      }else {
        return []
      }
    },
    myStake(){
      const res = this.userLarixPositions as []
      if (res&&res.length>0&&this.walletAddress!==''&&!this.isLoadingUserDetails){
        return res.sort((b:any,a:any)=>{
          return a.endTime - b.endTime
        })
      }else {
        return []
      }

    },
    rewardDialogVisible:{
      get() {
        return this.$store.state.reward.rewardDialogVisible
      },
      set(value) {
        this.$store.commit('updateRewardDialogVisible',value)
      }
    },
    userLarixLaunchpadDetails(){
      //@ts-ignore
      return this.userLaunchpadDetails.find((details)=>details.poolType==='stepn')!
    },
    needToHandle(){
      if (this.needToWithdrawLpArray.length>0||this.needToHandleCtokenAccounts.length>0||this.needToHanldeUserFeeLarixInfo.length>0){
        return true
      }else {
        return false
      }
    },
    ...mapState(
        {
          walletAddress: (state: any) => state.wallet.walletAddress,
          allReservesDetails: (state: any) => state.market.allReservesDetails,
          isLoadingInfo:(state:any) => state.market.isLoadingInfo,
          clientViewPort:(state:any) => state.clientViewPort,
          autoFreshTime:(state:any) => state.market.autoFreshTime,
          userObligation: (state: any) => state.market.userObligation,
          userLiquidationThreshold: (state: any) => state.market.userLiquidationThreshold,
          userLarixPositions:(state:any)=>state.market.userLarixPositions,
          mining:(state:any)=>state.market.mining,
          userObligationIndex:(state:any)=> state.market.userObligationIndex,
          errorContext:(state:any)=> state.txDialog.errorContext,
          lendingMarket: (state: any) => state.market.lendingMarket,
          lendingReserveArray: (state: any) => state.market.lendingReserveArray,
          needToHandleCtokenAccounts: (state:any) => state.market.needToHandleCtokenAccounts,
          needToWithdrawLpArray: (state:any) => state.market.needToWithdrawLpArray,
          isLoadingUserLarixStakeInfo: (state:any) => state.market.isLoadingUserLarixStakeInfo,
          mineCollateralLpDetails: (state:any) => state.market.mineCollateralLpDetails,
          needToHanldeUserFeeLarixInfo: (state:any) => state.market.needToHanldeUserFeeLarixInfo,
          userRawPositionData:(state:any)=>state.market.userRawPositionData,
          userLarixReward:(state:any)=>state.market.userLarixReward,
          isLoadingUserDetails:(state:any)=>state.market.isLoadingUserDetails,
          userLaunchpadDetails: (state:any) => state.market.userLaunchpadDetails as UserLaunchpadDetails[],
        }
    )
  },

  methods:{
    goToStakeDetails(){
      const target = document.getElementById("myStake") as HTMLElement
      target.scrollIntoView(true)
    },
    goToDashboard(){
      this.$store.commit('updateAppTab','Launchpad')
    },
    openHandleLpDialog(){
      this.$store.commit('updateHandleLpDialogVisible', true)
    },
    unStakeButtonEnable(position:any){
      const current = this.mineCollateralLpDetails[0].currentTime
      if (position.endTime - current >0){
        return false
      }else {
        return true
      }
    },
    openSupplyMarketsDialog(reserveDetail :any,status :string){
      this.enableFor = status
      this.selectedReserveDetail = reserveDetail
      this.$store.commit('updateSelectedReserveDetail', reserveDetail)
      this.$store.commit('updateSupplyDialogVisible', true)
      this.$store.commit('updateIsFullWithdraw',false)
    },
    openBorrowMarketsDialog(reserveDetail:any,status:string){
      this.enableFor = status
      this.selectedReserveDetail = reserveDetail
      this.$store.commit('updateSelectedReserveDetail', reserveDetail)
      this.$store.commit('updateBorrowDialogVisible', true)
      this.$store.commit('updateIsFullRepay',false)
    },
    isReachLpLimit(){
      let isReach = false
      this.userObligation?.info?.deposits.forEach((deposit:ObligationCollateral)=>{
        if (LP_RESERVE_ID_ARRAY.includes(deposit.depositReserve.toString()))
        {
          isReach = true
        }
      })
      return isReach
    },

    closeOrOpenMortgage: async function (reserveDetail:any) {
      if (!reserveDetail.userAssetDetails.supplyBalanceInTokenUnit.isGreaterThan(0))return
      let wallet = getWallet() as WalletAdapter
      this.selectedReserveDetail = reserveDetail
      if (reserveDetail.userAssetDetails.isEnterMarket)
      {
        await exitObligation(
            this.userLarixLaunchpadDetails.userObligations,
            this.selectedReserveDetail.userAssetDetails.userCollateralTokenAccount,
            this.selectedReserveDetail.reserveDetail,
            wallet,
            this.lendingReserveArray,
            this.userLarixLaunchpadDetails.userMining,
            this.userLarixLaunchpadDetails.lendingMarket,
            this.userLarixLaunchpadDetails.lendingProgramID
        ).then(()=>{
          txSuccess()
        }).catch((e)=>{
          txFailed(undefined,e)
        })
      }else {
        if (this.isReachLpLimit()&&this.selectedReserveDetail.isLP){
          this.$store.commit('updateOpenObligationDialogVisible',true)
          return
        }
        await intoObligation(
            this.selectedReserveDetail.reserveDetail,
            wallet,
            this.userLarixLaunchpadDetails.lendingMarket,
            this.userLarixLaunchpadDetails.lendingProgramID,
            this.selectedReserveDetail.userAssetDetails.userCollateralTokenAccount,
            this.userLarixLaunchpadDetails.userMining,
            this.lendingReserveArray,
            this.userLarixLaunchpadDetails.userObligations,
        ).then(()=>{
          if (this.errorContext==='')
            txSuccess()
        }).catch((e)=>{
          txFailed(undefined,e)
        })
      }
      await  appController.updateDataRefactor(this.userObligationIndex)
    },
    async unStakePosition(position:any){
      if (!this.unStakeButtonEnable(position)) {
        this.$q.notify({
          message:'Sorry, you can\'t unlock now, please unlock LP after the unlocking time.',
          color:'red',
          position:'bottom',
          timeout:2000
        })
        return
      }
      let wallet = await getWallet()
      openTxDialog()
      await withdrawPosition(
          wallet,
          position.position
      ).then(()=>{
        txSuccess()
      }).catch((e)=>{
        txFailed(undefined,e)
      })
      await  appController.updateDataRefactor(this.userObligationIndex)
    },
    async claimAllReward(){
      let wallet = await getWallet()
      openTxDialog()
      await claimPosition(
          wallet,
          this.userRawPositionData
      ).then(()=>{
        txSuccess()
      }).catch((e)=>{
        txFailed(undefined,e)
      })
      await  appController.updateDataRefactor(this.userObligationIndex)
    },
    ViewPortAndStyle(){
      this.viewPort = this.clientViewPort
    },
  },
})
