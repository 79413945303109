
import { useI18n } from 'vue-i18n'
import {defineComponent } from 'vue'
import {mapState} from "vuex";
import {formatDollars, formatDollarsKmb, getBigNumberStr, formatBigNumberStr,formatKmb} from "@/utils/helpers";
import myEchart from '@/components/Echarts/myEchart.vue';
import StakeSOLDialog from "@/components/StakeSOLDialog.vue";
import BigNumber from "bignumber.js";
import {ReserveDetails} from "@/controller/type/reserveDetails";
import getDetailService from "@/api/services/getDetailService";
import {HIDDEN_ASSET} from "@/api/constants/hiddenAsset";

export default defineComponent({
  name: "Market",
  data() {
    return {
      getBigNumberStr: getBigNumberStr,
      formatDollars: formatDollars,
      formatDollarsKmb: formatDollarsKmb,
      formatBigNumberStr:formatBigNumberStr,
      formatKmb:formatKmb,
      larixLogoSource:require('../assets/coin/asset_LARIX@2x.png'),
      icon_information: require('../assets/icon_information.svg'),
      viewPort:0,
      iconBackgroundColorArr:['#E49BFF', '#B788FF', '#8BD7FF', '#FFDE9F', '#9CFFE0', '#B9B9B9'],
      releaseTime:1626350400000,
      testReleaseTime:1626264000000,
      top5Token:[] as any,
      poolType:'main',
      stakePools:[
        {
          dex:'Raydium',
          dexLogoSource:require('../assets/dex/project_icon_raydium@2x.png'),
          totalAPR:'',
          pairs:'LARIX/USDC',
          token1LogoSource:require('../assets/coin/asset_USDC.svg'),
          stakeURL:'https://raydium.io/farms/',
          APR:'----%'
        },
        {
          dex:'ORCA',
          dexLogoSource:require('../assets/dex/project_icon_orca@2x.png'),
          totalAPR:'',
          pairs:'LARIX/USDC',
          token1LogoSource:require('../assets/coin/asset_USDC.svg'),
          stakeURL:'https://www.orca.so/whirlpools/browse',
          APR:'----%'
        },
        {
          dex:'Raydium',
          dexLogoSource:require('../assets/dex/project_icon_raydium@2x.png'),
          totalAPR:'',
          pairs:'LARIX/RAY',
          token1LogoSource:require('../assets/coin/asset_RAY.svg'),
          stakeURL:'https://raydium.io/farms/',
          APR:'----%'
        },

      ],
      IntegrationProjects:[
        {
          projectName:'Marinade',
          content:'(SOL-mSOL)',
          introduce:'Solana tastes better with Marinade.',
          action:this.t('market.stake'),
          clickType:0,
          dialogType:0,
          projectImg: require('../assets/marinade.svg'),
        },
        {
          projectName:'Lido',
          content:'(SOL-stSOL)',
          introduce:'Lido is a liquid-staking solution.',
          action:this.t('market.stake'),
          clickType:0,
          dialogType:1,
          projectImg: require('../assets/Lido.svg'),
        },
        {
          projectName:'Jupiter',
          content:'',
          introduce:'The best swap aggregator & infrastructure for Solana - powering best price, token selection and UX for all users and devs.',
          action:this.t('market.swap'),
          clickType:1,
          projectImg: require('../assets/jupiter.svg'),
          url:'https://jup.ag/',
        },
        {
          projectName:'Wormhole',
          content:'',
          introduce:'Wormhole builds bridges between chains, facilitating transfers of any kind of information from one chain to another.',
          action:this.t('market.swap'),
          clickType:1,
          projectImg: require('../assets/whrmhole.svg'),
          url:'https://wormholenetwork.com/',
        },
      ],
      dialogType:0,
      showHiddenAsset: true,
    }
  },
  components:{
    myEchart,
    StakeSOLDialog,
  },
  setup(){
    const { t } = useI18n()
    return {
      t,
    }
  },
  created(){
    window.addEventListener('resize', this.ViewPortAndStyle)
    this.ViewPortAndStyle()
  },
  mounted() {
          //图表自适应
    this.top5Token = this.getTop5Token()
    getDetailService.getOrcaPoolApy().then((res: any) => {
      this.stakePools[1].APR = new BigNumber(res).times(100).toFixed(2) + '%'
    })
  },
  methods: {
    handleProjectBtnClick(item:any){
      if (item.clickType===0){
        this.dialogType = item.dialogType
        this.stakeSOLDialogVisible = true
      }else {
        window.open(item.url)
      }
    },
    raydiumStakePoolDetails:function(pairName:string){
      return this.mineCollateralLpDetails.find((item:any) => item.pairsName === pairName)
    },
    gotoStakePools(url:string){
      window.open(url)
    },
    ViewPortAndStyle(){
      this.viewPort = this.clientViewPort
    },
    getTop5Token(){
      // @ts-ignore
      let top5TokenList = []
      // @ts-ignore
      let otherTokenList = []
      let otherTokenTotal = {
        symbol:'Other',
        dailyMining:new BigNumber(0),
      }
      this.allReservesDetails.sort((b:any, a:any) => {
      return a.dailyMining - b.dailyMining
      }).map((item:any,index:number)=>{
        if (index<5)
        {
          top5TokenList.push(item)
        }else {
          otherTokenList.push(item)
        }
      })
      if (otherTokenList.length>0){
        // @ts-ignore
        otherTokenList.map((item)=>{
          otherTokenTotal.dailyMining = item.dailyMining.plus(otherTokenTotal.dailyMining)
        })
        // @ts-ignore
        top5TokenList.push(otherTokenTotal)
      }
      return top5TokenList
    },
    goToMarketDetail(val:any){
      window.scroll(0,0)
      this.$store.commit('updateSelectedReserveDetail', val)
      this.$store.commit('updateAppTab','MarketId')
    },
  },
  computed: {
    stakeSOLDialogVisible:{
      get():boolean{
        return this.$store.state.market.stakeSOLDialogVisible
      },
      set(value:boolean) {
        this.$store.commit('updateStakeSOLDialogVisible',value)
      }
    },
    myEchartData(){
      const arr = this.allReservesDetails.map((item:any)=>{
        let obj = {
          value:item.dailyMining.toNumber().toFixed(2),
          name:`${item.symbol}`,
          liquidityPrice:item.liquidityPrice
        }
        return obj
      }).sort((a:any, b:any) => {

        return b.value - a.value
      }) as []
      return arr
    },
    circulationInUsd():BigNumber{
      return new BigNumber(this.larixCirculation).times(this.larixPrice)
    },
    circulationRatio():Number{
      return Number(new BigNumber(this.larixCirculation).div(10000000000).times(100).toFixed(2))
    },
    sortMarket:function (){
      const res = this.allReservesDetails as []
      return res.filter((reserve:ReserveDetails)=>{
        //@ts-ignore
        return reserve.poolType === this.poolType&&(this.showHiddenAsset?(!HIDDEN_ASSET.includes(reserve.symbol)):true)
      }).sort((b:any,a:any)=>{
        if (a.dailyMining- b.dailyMining ===0){
          return a.totalLiquidityInUsd - b.totalLiquidityInUsd
        }
        else {
          return a.dailyMining - b.dailyMining
        }
      })
    },
    ...mapState(
        {
          walletAddress: (state: any) => state.wallet.walletAddress,
          allReservesDetails: (state: any) => state.market.allReservesDetails,
          isLoadingInfo:(state:any) => state.market.isLoadingInfo,
          clientViewPort:(state:any) => state.clientViewPort,
          larixPrice:(state:any) => state.market.larixPrice,
          larixCirculation:(state:any) => state.market.larixCirculation,
          totalLarixDaily:(state:any) => state.market.totalDailyMining,
          mineCollateralLpDetails: (state:any) => state.market.mineCollateralLpDetails,
        }
    ),
  },
  watch: {
  }
})
