
export default {
    name: 'English',
    headers:{
        dashBoard:'Dashboard',
        market:'Market',
        liquidation:'Liquidation',
        larix:'LARIX',
        Positions:'Positions',
        stake:'Stake',
        dao:'DAO',
        connect:'Connect',
        About:'About',
        reward:'Reward',
        launchpad:'Launchpad',
        main:'Main Pool',
    },
    reward:{
        larixBalance:'LARIX Balance',
        larixRewards:'LARIX Rewards',
        insufficientUsdcBalance:'Insufficient USDC balance',
        stake:'Stake',
        stakeForLP:'Stake To Generate LP',
        earn2x:'Stake to Earn {x}X LARIX Rewards ',
        claimDirectly:'Claim Now ',
        myStaking: 'My Staking',
        seeDetails: 'See Details',
        youWillLose1: 'Claim Now You will Lose The Chance of',
        youWillLose2: 'Earning An Extra of {x} LARIX Rewards',
        reward:'Rewards',
        walletBalance:'* Wallet Balance :',
        maximumReceive:'MAX Claimable {x} LARIX',
        expiredReward:'Unlocked Rewards',
        days:'days',
        currentRewards:'Current Rewards',
        stakeTime:'Lock-up Period',
        claimLARIX:'Claim LARIX',
        needUSDC:'Estimated USDC ≈',
        chooseToReceive:'Choose to receive',
        main:"Main",
    },
    wallet:{
        reward:'Reward',
        connect:'Connect',
        faucet:'Faucet',
        connectToAWallet:'Connect to a wallet',
        yourWallet:'Your Wallet',
        disconnect:'Disconnect',
        connected:'Connected',
        harvest:'Claim Now',
        walletBalance: 'Wallet Balance',
        unclaimedBalance: 'Claimable Rewards',
        price: 'LARIX Price',
        nothingToClaim: 'Nothing to claim',
        subAccount :'My position',
    },
    footer:{
        doc:'Doc'
    },
    home:{
        totalSupply:'Total Supply',
        totalBorrow:'Total Borrow',
        totalMined:'Total Mined',
        larixPrice:'Larix Price',
        supplyMarket:'Supply Market',
        borrowMarket:'Borrow Market',
        myBill:'My Bill',
        asset:'Asset',
        collateral:'Collateral',
        allMarket:'All market',
        supplyAPY:'Supply APR',
        wallet:'Wallet',
        borrowAPY:"Borrow APR",
        liquidity:"Liquidity",
        operation:"Operation",
        borrowBalance:"Borrow Balance",
        supplyBalance:"Supply Balance",
        withdraw:"Withdraw",
        repay:"Repay",
        supply:"Supply",
        borrow:"Borrow",
        loading:"Loading",
        connectToAWallet:'Connect to a wallet',
        liquidityMining:'Liquidity Mining',
        totalAPR:'Total APR',
        more:'More',
        pool:'Pool',
        raydiumLP:'Raydium LP',
        unsettledAssets:'Unsettled Assets',
        handleTip:'Transaction undone, please click here to process >',
        singleAsset:'Single Asset',
        myPosition:'My Positions',
        tip:'Tip',
        noBorrowFee:'No Borrow Fee',
    },
    supplyAndBorrow:{
        borrowLimit:"Borrow Limit",
        netAPY:"Net APR",
        borrowBalance:"Borrow Balance",
        supplyBalance:"Supply Balance",
        netValue:'Net Value',
        borrowRatio:'Borrow Ratio',
    },
    supplyDialog: {
        supply: "Supply",
        withDraw: "Withdraw",
        wallet: "Wallet",
        balance: "Balance",
        walletBalance: "wallet",
        liquidity: "liquidity",
        max: "MAX",
        limit85: "85% LIMIT",
        supplyApy: "Supply APR",
        supplyBalance: "Supply Balance",
        borrowLimit: "Borrow Limit",
        supplyRate: "Supply Rate",
        withdrawRate: "Withdraw Rate",
        invalidNumber:"Invalid Number",
        insufficientLiquidity:"Insufficient Liquidity",
        stillWithdraw:"Withdraw Anyway",
        borrowLimitUsedPercent:"Borrow Limit Utilization Rate",
        collateralFactor:"Collateral Factor",
        insufficientFunds:"Insufficient Funds",
        solBalanceIsLow:'SOL Balance is Low',
        cancel:'Cancel',
        interestRate: "Interest Rate",
        miningAPY: "Mining APR",
        supplyMaxLimit100M:"The maximum supply amount is limited to {x}",
        currentSupply:"Current supply amount is",
        reachLpLimit:"Reach LP deposit Limit",
        reachLimit:"Reach deposit Limit",
        baseReward:'Base reward',
        boostedAPR:'Boosted LARIX APR',
        APR:'APR',
        supplyMaxAPR:'Max APR',
        baseAPR:'Base APR',
        baseLARIXRewards:'Base LARIX rewards',
        borrowingMinAPR:'Min APR',
    },
    borrowDialog: {
        borrow: "Borrow",
        repay: "Repay",
        wallet: "Wallet",
        balance: "Balance",
        walletBalance: "wallet",
        liquidity: "liquidity",
        borrowMax: "MAX",
        max:'MAX',
        borrowApy: "Borrow APR",
        borrowBalance: "Borrow Balance",
        borrowLimit: "Borrow Limit",
        borrowRate: "Borrow Rate",
        repayRate: "Repay Rate",
        borrowMaxInTokenUnit:"Borrow Max",
        borrowLimitUsedPercent:"Borrow Limit Utilization Rate",
        collateralFactor:"Collateral Factor",
        invalidNumber:"Invalid Number",
        insufficientLiquidity:"Insufficient Liquidity",
        stillBorrow:"Borrow Anyway",
        noBalanceToRepay:"No Balance To Repay",
        insufficientFunds:"Insufficient Funds",
        cancel:'Cancel',
        interestRate: "Interest Rate",
        miningAPY: "Mining APR",
        reachPositionLimit:'Each account is limited to 5 collateral and borrowing interactions in total, simultaneously.',
    },
    market:{
        supply:"Supply",
        borrow:"Borrow",
        top3Markets:"Top 3 Markets",
        asset:"Asset",
        totalSupply:"Total Supply",
        supplyAPY:"Supply APR",
        totalBorrow:"Total Borrow",
        borrowAPY:"Borrow APR",
        details:"Details",
        supplyBorrow:'Total Market Value',
        loading:"Loading",
        connectToAWallet:'Connect to a wallet',
        totalSupplyApy:"Total Supply/APR",
        totalBorrowApy:"Total Borrow/APR",
        depositLimit:"Deposit Limit",
        supplyIR:'Supply Interest',
        borrowIR:'Borrow Interest',
        supplyDistributionAPR:'Supply Distribution APR',
        borrowDistributionAPR:'Borrow Distribution APR',
        supplyIRHelpContext:'Supply APR = Supply Interest + Supply Mining APR',
        borrowIRHelpContext:'Borrow APR = Borrow Interest - Borrow Mining APR',
        stake:'Stake',
        swap:'Swap',
    },
    marketId:{
        market:"Market",
        supply:"Supply",
        borrow:"Borrow",
        netRate:"Net APR",
        supplyAPY:"Supply APR",
        distributionSupplyAPY:"Distribution APR",
        distributionBorrowAPY:"Distribution APR",
        totalSupply:"Total Supply",
        borrowAPY:"Borrow APR",
        totalBorrow:"Total Borrow",
        interestRateModel:"Interest Rate Model",
        marketDetails:"Market Details",
        Price:"Price",
        marketLiquidity:"Market Liquidity",
        collateralFactor:"Collateral Factor",
        exchangeRate:"Exchange Rate",
        supplyMiningAPR:"Supply Mining APR",
        borrowMiningAPR:"Borrow Mining APR",
        utilization:"Utilization",
        supplyChartAPY:"Supply APR",
        borrowChartAPY:"Borrow APR",
        mndeAPR:"APR"
    },
    larix:{
        amountinCirculation:"Amount in Circulation",
        circulatingMarketCap:"Circulating Market Cap",
        totalSupply:"Total Amount",
        lARIXDistribution:"LARIX Distribution",
        market:"Market",
        perDay:"Daily Mining",
        supplyMiningAPR:"Supply Mining APR",
        borrowMiningAPR:"Borrow Mining APR",
        dailyDistribution:"Daily Distribution",
        other:"Other",
        distribution:"Distribution",
        larixPrice:'Larix Price',
        stake:'Stake'
    },
    About:{
        aboutLarix:"About Larix",
        aboutLarixContext:"Larix is the first Metaverse based Finance Protocol on Solana, adopted a dynamic interest rate model and created more capital-efficient risk management pools, as such a broad selection of collateral types, crypto tokens, stablecoins, synthetic assets, NFTs, and other kind of assets can be fully utilized in a safe way.",
        auditReport:"Audit Report",
        whyLarix:"Why Larix",
        reason1:"The first lending protocol with live mining functionality",
        reason2:"The first lending protocol will support NFTs peer to peer lending",
        reason3:"The first lending protocol Open-Sourced on Solana",
        roadmap:"Roadmap",
        roadmapStep1:"Phase 1: Jun – Sep 2021",
        roadmapStep2:"Phase 2: Late 2021 to 2022",
        roadmapStep3:"Phase 3: 2022 onwards",
        roadmapStep1Context:"Larix starts from meeting the essential needs of automated Defi lending. At this stage, crypto tokens, stablecoins, and synthetic assets are accepted as collaterals. Risk management and asset security are the cornerstones of Larix.",
        roadmapStep2Context:"Partnering with PYTH network to connect high-fidelity (HiFi) financial markets to the world of decentralized finance (Defi), which enables higher capital utilization of a wider range of conventional financial assets.",
        roadmapStep3Context:"The protocol extends the collateral base to accept Non-Fungible Tokens (NFTs) via peer-to-peer lending across all asset classes. Larix will work with multiple partners to provide secure, customizable, and almost instant liquidity to the real economy.",
        seedRoundInvestor:"Our Investors ",
        partnership:"Partnership",
        audit:"Audit",
    },
    liquidation: {
        liquidation : 'Liquidation',
        liquidateTips : 'Liquidate Tips',
        account: 'Account',
        amountTip: 'Amount you want to repay in',
        assetToRepay :'Asset To Repay',
        assetsToRepay :'Assets To Repay',
        maxRepayAmount: 'Max Repay Amount',
        assetToSeize : 'Asset To Seize',
        assetsToSeize : 'Assets To Seize',
        maxSeizeAmount : 'Max Seize Amount',
        debtRatio : 'Debt Ratio',
        action : 'Action',
        liquidate : 'Liquidate',
    },
    noticeDialog: {
        notice : 'Notice',
        text : 'Countdown to start mining',
        confirm: 'Confirm'
    },
    pendingDialog: {
        transactionPending: "Pending",
        viewOnEtherscan: "View On Heco",
        confirm: "Confirming the transaction...",
        broadcast: "Transaction broadcast...",
        waitingEvm: "Waiting for EVM results...",
        errorTips:"Transaction Failed",
        checkTips:"Successful Transaction"
      },
    handleLpDialog:{
        unsettledAssets:'Unsettled assets',
        withdraw:'Withdraw',
        keepMining:'Keep mining',
        tooltip:'Please select an order you want to process'
    },
    openObligationDialog:{
        openChoose:'Open or choose position',
        usable:'usable position :',
        unavailable:'No position available',
        rulesLine1:'* lp collateral rules:',
        rulesLine2:'A position can only collateral one LP asset',
        action:'Open a new position and collateral',
    },
    borrowFeeTipDialog:{
        tip:'Tip',
        noBorrowFee:'There are no borrow fees on Larix',
        gotIt:'Got it',
    },
    larixStakingDialog:{
        expireDate:'Expire date',
        stakedLP:'Staked LP',
        assets:'Assets',
        amount:'Amount',
        unlockingTime:'Unlocking Time',
        stakeTime:'Locked Date',
        actions:'Actions',
        unlocking:'Unlocking',
        oneClickClaim:'Claim LARIX rewards from Raydium',
        claim:'Claim',
        raydiumReward:'Raydium Reward',
    },
    tip:{
        borrowLimitTip:'The borrow limit is the maximum amount of assets you can borrow. This is marked by a orange bar. The higher the value of the supplied assets, the higher the borrowing limit will be.',
        borrowBalanceTip:'Borrow balance is the sum of all borrowed assets.',
        supplyBalanceTip:'Supply balance is the sum of all supplied assets. Increasing deposits can increase your borrowing limits.',
        netAPRTip:'Net APR = Total Annual Yields / Net Value (Total annual yields = total supply yields - total borrow cost).',
        netValueTip:'Net Value = Supply Balance - Borrow Balance.',
        borrowRatioTip:'Borrow ratio = Total borrow / Supply balance. The borrow rate reflects the utilization rate of your assets, and the upper limit is the borrow limit',
        liquidationTip:'The total borrowed amount when the account triggers liquidation.This is marked by a red bar. When the total borrowed amount => liquidation threshold, the account will enter a state that can be liquidated. Increasing deposits or decreasing borrowings can release your account from liquidation.',
        supplyAPRTip:'Annual percentage rate (APR) earned for supplying assets. APRs is dynamic and fluctuates as the total supply in the pool increase / decrease. Supply APR= supply interest (the yield of the supplied asset) + supply mining yield (LARIX token).',
        borrowAPRTip:'Annual percentage rate (APR) paid for borrowing assets. APRs is dynamic and fluctuates as the total borrowed funds in the pool increase / decrease. Borrow APR = borrowing interest (the cost to borrow the specific token) - borrowing mining yield (LARIX token).',
        walletBalanceTip:'Wallet balance is the balance of various assets in your wallet. Wallet balance can be used to supply or repay borrowed assets and corresponding interests.',
        liquidityTip:'Liquidity is the current balance of an asset that is available for withdrawal/borrow. Ample liquidity also means that you can withdraw your deposits at any time.',
        borrowFeeTip:'Larix has a ‘zero borrow fee’ policy. The borrow fee is an additional cost when borrowing, which is different from the borrowing interest. Borrow fee = Borrow amount * Borrow fee rate. A larger borrow amount will lead to higher borrow fee rate, which will result in a greater borrow fee. Borrow fee is charged every time a transaction is made.',
        collateralTip:'After depositing you need to turn on the collateral button to borrow. The higher the staking value of an asset, the higher the amount (i.e. borrow limit) you can borrow.',
        availableTip:'Available is your current maximum loanable asset.',
        myRewardTip:'Supply/borrow single tokens or stake LPs at Larix can earn mining yields (LARIX token), here is your supply/borrow mining yields.',
        myStakeTip:'The amount of LARIX/USDC LP staked at Raydium when you choose x5/x10 boosted rewards.',
        lockedDateTip:'Locked date is the date when you choose x5 / x10 boosted rewards and stake LARIX/USDC LP .You cannot withdraw the staked LARIX/USDC LP during the lock-up period (90 days or 180 days).',
        unlockingDateTip:'Unlocking Time is the date when you can claim 5x / 10x LARIX boosted rewards and unlock LP after 90 days/180 days of staking.',
        stakeAmountTip:'Amount is the quantity of LARIX/USDC LP staked after selecting x5/x10 boosted rewards.',
        raydiumRewardTip:'After selecting x5/x10 boosted rewards and adding LARIX/USDC liquidity, you will receive LARIX rewards for liquidity mining, which you can claim at Larix at any time without lock-up period, just click the "Claim" button to harvest all. The RAY portion of the rewards will be sent to your wallet after you remove the liquidity from Raydium.',
        dailyTip:'Daily Mining is the daily amount of LARIX tokens earned by supply & borrow of assets.',
        collateralFactorTip:'The maximum loanable asset ratio of a unit of collateral. For example, if you supplied USDC worth $100 and can borrow up to $85 worth of BTC, the USDC collateral factor is 85%.',
        larixRewards:'LARIX rewards',
        APRTip:'Optional boosted APR if LARIX rewards are staked for 10x',
        baseRewardTip:'Receive local currency APR and LARIX APR',
        boostedAPRTip:'Optional boosted APR if LARIX rewards are staked for 5x',
        dialogAPR1X:'Initial 1X APR:',
        dialogAPR5X:'Boosted 5X APR:',
        maxAPRTip:'(10x boosted staking)',
        baseAPRTip:'(Supply APR + LARIX rewards)',
        baseAPRTip2:'(Borrow APR - LARIX rewards)',
        interest:'interest',
        doubleRewardTip:'(The {x} rewards will be distributed directly to your wallet every 24 hours)',
        claimStakeRewardTip:'Click any \'Claim\' button to claim all LP rewards (5x and 10x included).  If there\'s still remaining unclaimed rewards, keep clicking \'Claim\' until all rewards are claimed.',
        liquidateTip:' Liquidations occur when debt ratio exceeds 100%.',

    },
    positions:{
        mySupply:'My Supply',
        myBorrow:'My Borrow',
        myStake:'My Stake',
        myReward:'My Rewards',
        liquidationThreshol:'Liquidation Threshold',
        claim:'Claim',
        noSupplyYet:'No Supply Yet',
        noBorrowYet:'No Borrow Yet',
        noStakeYet:'No Stake Yet',
        supplyNow:'Supply Now',
        borrowNow:'Borrow Now',
        stakeNow:'Stake Now',
    },
    launchpad:{
        whatLaunchpad:'What is Larix lending launchpad?',
        details1:'The Larix Lending Launchpad offers isolated asset pools for  native SPL tokens.',
        details2:'Larix has decided to launch isolated asset pools alongside it’s existing cross-collateral pools, enabling more tokens to implement a lending functionality.',
        details3:'If you want any new assets to be listed on Lending Launchpad, please don\'t hesitate and fill out the application form!',
        apply:'Apply'
    }
  }
