export enum LendingInstruction {
  InitLendingMarket = 0,
  SetLendingMarketOwner = 1,
  InitReserve = 2,
  RefreshReserve = 3,
  DepositReserveLiquidity = 4,
  RedeemReserveCollateral = 5,
  InitObligation = 6,
  RefreshObligation = 7,
  DepositObligationCollateral = 8,
  WithdrawObligationCollateral = 9,
  BorrowObligationLiquidity = 10,
  RepayObligationLiquidity = 11,

  FlashLoan = 13,
  InitMining = 16,
  RefreshMining = 17,
  DepositMining = 18,
  WithdrawMining = 19,
  ClaimMiningMine = 20,
  ClaimObligationMine = 21,
  RefreshReserves = 24,
  LiquidateObligation = 25,
  ClaimMine = 26,
}
