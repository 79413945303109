
import {mapState} from "vuex";
import {defineComponent} from 'vue'
import { useI18n } from 'vue-i18n'
import {eX, formatBigNumberStr} from "@/utils/helpers";
import BigNumber from "bignumber.js";
import {getWallet} from "@/api/context/wallet";
import {WalletAdapter} from "@/api/wallets";
import openTxDialog from "@/controller/openTxDialog";
import BN from "bn.js";
import txSuccess from "@/controller/txSuccess";
import txFailed from "@/controller/txFailed";
import appController from "@/controller/appController";
import {stakeMSOL} from "@/api/actions/stakeMSOL";
import {stakeStSol} from "@/api/actions/stakeStSol";



export default defineComponent({
  name: "StakeSOLDialog",
  props:{
    dialogType:Number,
  },
  data() {
    return {
      stakeAmount:'',
      formatBigNumberStr:formatBigNumberStr,
    }
  },
  watch: {
  },
  setup(){
    const { t } = useI18n()
    return {
      t,
      changeIcon: require('../assets/pop_icon_cutover.svg'),
      solImg :require('../assets/coin/asset_SOL.svg')
    }
  },
  computed: {
    userSOLBalance(){
      //@ts-ignore
      const solReserve = this.allReservesDetails.find((item:any)=>item.symbol==='SOL'&&item.poolType==='main')
      return solReserve.userAssetDetails.walletBalanceInTokenUnit
    },
    stakeSOLDialogVisible:{
      get():boolean{
        return this.$store.state.market.stakeSOLDialogVisible
      },
      set(value:boolean) {
        this.stakeAmount = ''
        this.$store.commit('updateStakeSOLDialogVisible',value)
      }
    },
    dialogTitle(){
      if (this.dialogType===0) return 'Marinade'
      return 'Lido'
    },
    stakeApy(){
      let apy
      //@ts-ignore
      this.dialogType===0?apy = this.stakeSOLInfo.mSOLApy: apy = this.stakeSOLInfo.stSOLApy
      return apy
    },
    stakeExchangeRate(){
      let apy
      //@ts-ignore
      this.dialogType===0?apy = this.stakeSOLInfo.mSOLExchangeRate: apy = this.stakeSOLInfo.stSOLExchangeRate
      return apy
    },
    receiveSymbol(){
      //@ts-ignore
      return this.dialogType===0?'mSOL':'stSOL'
    },
    receiveAmount(){
      //@ts-ignore
      const amount = new BigNumber(this.stakeAmount).isNaN()?new BigNumber(0): new BigNumber(this.stakeAmount)
      //@ts-ignore
      return amount.div(this.stakeExchangeRate)
    },
    stakeButtonText: function () :string{

      const amount = this.stakeAmount
      //@ts-ignore
      if (amount === '' || amount === null) return this.t('market.stake')
      //@ts-ignore
      if (amount === '' || isNaN(Number(amount))) return this.t('supplyDialog.invalidNumber')
      //@ts-ignore
      if(new BigNumber(amount).isLessThan(0)) return this.t('supplyDialog.invalidNumber')
      //@ts-ignore
      if (new BigNumber(amount).isGreaterThan(this.userSOLBalance)) return this.t('supplyDialog.insufficientFunds');
      if (this.userSOLBalance.minus(amount).minus(new BigNumber(0.00005).times(1000)).isLessThan(0))
      {
        //@ts-ignore
        return this.t('supplyDialog.solBalanceIsLow')
      }
      //@ts-ignore
      return this.t('market.stake')
    },
    stakeButtonEnable: function () {
      const amount = this.stakeAmount
      if (amount === '') return false
      //@ts-ignore
      if (isNaN(amount)) return false
      if (!new BigNumber(amount).isGreaterThan(0)) return false
      if (new BigNumber(amount).isGreaterThan(this.userSOLBalance
          .toFixed(6))) return false
      return true
    },
    ...mapState({
      walletAddress: (state:any) => state.wallet.walletAddress,
      allReservesDetails: (state: any) => state.market.allReservesDetails,
      lendingMarket: (state:any) => state.market.lendingMarket,
      isLoadingUserDetails: (state:any) => state.market.isLoadingUserDetails,
      userObligationIndex:(state:any)=> state.market.userObligationIndex,
      stakeSOLInfo:(state:any)=> state.market.stakeSOLInfo,
    })
  },
  methods: {
    stakeAmountInput(value:string){
      this.stakeAmount = value
    },
    async stakeAction (){
      const wallet = getWallet() as WalletAdapter
      const interceptedAmount = new BigNumber(this.stakeAmount).toFixed(9,1)
      const method = this.dialogType===0?stakeMSOL:stakeStSol
      openTxDialog('updateStakeSOLDialogVisible')
      await method(
          wallet,
          new BN(eX(interceptedAmount,9).toString())
      ).then(()=>{
        txSuccess('updateStakeSOLDialogVisible')
      }).catch((e)=>{
        txFailed('updateStakeSOLDialogVisible',e)
      })
      await appController.updateDataRefactor( this.userObligationIndex)
    }
  },
})
