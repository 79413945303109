import {getConnection} from "@/api/context/connection";
import {Detail, Obligation, ObligationParser} from "../models"
import BufferLayout from "buffer-layout";

import {PublicKey} from "@solana/web3.js";
import {Mining, MiningLayout, MiningParser} from "@/api/models/state/mining";
import {LENDING_MARKET_CONFIGS} from "@/api/constants/config";
export async function getMining(ownerAddress:PublicKey,lendingMarketID:PublicKey,lendingProgaramID:PublicKey):Promise<Detail<Mining>[]>{
    const connection = await getConnection()
    const accountInfos = await connection.getProgramAccounts(lendingProgaramID,{
        filters:[
            {
                dataSize: MiningLayout.span
            },
            {
                memcmp: {
                    offset: BufferLayout.u8('version').span,
                    /** data to match, as base-58 encoded string and limited to less than 129 bytes */
                    bytes: ownerAddress.toBase58()
                }
            }

        ]
    })

    // const resultArray = new Array<Detail<Mining>>();
    // accountInfos.map(function (item):any{
    //
    //     const value = MiningParser(item.pubkey,item.account)
    //     if (value.info.lendingMarket.equals(lendingMarketID)){
    //         resultArray.push(value)
    //     }
    // })
    // return resultArray
    const resultArray = new Array<Detail<Mining>>();
    accountInfos.map(function (item):any{
        const value = MiningParser(item.pubkey,item.account)
        if (value.info.lendingMarket.equals(lendingMarketID)){
            resultArray.push(value)
        }
    })
    return resultArray
}
export async function getMiningByPublicKey(ownerAddress:PublicKey,lendingMarketIDArray:PublicKey[],lendingProgaramID:PublicKey):Promise<Map<string, Detail<Mining>[]>>{
    const connection = await getConnection()
    const accountInfos = await connection.getProgramAccounts(lendingProgaramID,{
        filters:[
            {
                dataSize: MiningLayout.span
            },
            {
                memcmp: {
                    offset: BufferLayout.u8('version').span,
                    /** data to match, as base-58 encoded string and limited to less than 129 bytes */
                    bytes: ownerAddress.toBase58()
                }
            }

        ]
    })

    // const resultArray = new Array<Detail<Mining>>();
    // accountInfos.map(function (item):any{
    //
    //     const value = MiningParser(item.pubkey,item.account)
    //     if (value.info.lendingMarket.equals(lendingMarketID)){
    //         resultArray.push(value)
    //     }
    // })
    // return resultArray
    const resultMap = new Map<string,Detail<Mining>[]>()
    lendingMarketIDArray.forEach((lendingMarketID)=>{
        const resultArray = new Array<Detail<Mining>>();
        accountInfos.map(function (item):any{
            const value = MiningParser(item.pubkey,item.account)
            if (value.info.lendingMarket.equals(lendingMarketID)){
                resultArray.push(value)
            }
        })
        resultArray.sort( ( a, b)=>{
            return b.pubkey.toString().localeCompare(a.pubkey.toString())
        })
        resultMap.set(LENDING_MARKET_CONFIGS.find((config)=>config.lendingMarket.pubkey.equals(lendingMarketID))!.poolType,resultArray)
    })
    return resultMap
}
