class errorType{
    public errorContext = {
        0:{
            type11: "Input amount is invalid",
            type20:"Insufficient liquidity available",
            type23:"Withdraw amount too small",
            type24:"Withdraw amount too large",
            type25:"Borrow amount too small to receive liquidity after fees",
            type26:"Borrow amount too large for collateral amount",
            type27:"Repay amount too small to transfer liquidity",
            type28:"Liquidation amount too small to receive collateral",
            type31:"Each account is limited to 10 collateral and borrowing interactions in total, simultaneously.",
            type32:"Obligation owner is invalid",
            type33:"Obligation deposits are empty",
            type34:"Obligation borrows are empty",
            type37:"Invalid obligation collateral",
            type38:"Invalid obligation liquidity",
            type39:"Obligation collateral is empty",
            type40:"Obligation liquidity is empty",
            type56:"Deposit amount too large for account balance",
            type57:"Redeem amount too large for deposit balance",
            type58:"Withdraw amount too large for deposit balance",
            type59:"Repay amount too large for borrow amount",
            type60:"Deposit is now paused",
            type61:"Borrow is now paused",
            type62:"Liquidation is now paused",
            type63:"Can not withdraw all deposit",
            type68:"Exceed reserve deposit limit",
            type312:"Insufficient USDC balance",
            type1000:"Each account is limited to 5 collateral and borrowing interactions in total, simultaneously.",
            type1001:"Insufficient SOL balance",
            type1002:"Solana network is busy, Transaction was not confirmed in 30.00s",
            type1003:"Transaction too large",
        },
        1: {
            type11:"輸入金額無效",
            type20:"可用流動性不足",
            type23:"取款金額太小",
            type24:"取款金額太大",
            type25:"借款金額太小",
            type26:"質押物價值不足",
            type27:"還款金額太小不足以完成轉賬",
            type28:"清算金額不足以收到抵押物",
            type31:"超過抵押和借款幣種數量的限制",
            type32:"只有倉位所有人才可以操作倉位",
            type33:"沒有開啓任何抵押",
            type34:"沒有借款",
            type37:"質押物無效",
            type38:"債務流動性無效",
            type39:"沒有開啓任何抵押",
            type40:"無債務流動性",
            type56:"錢包餘額不足",
            type57:"提款金額超過存款餘額",
            type58:"提款金額超過存款餘額",
            type59:"還款金額大於借款餘額",
            type60:"存款已暫停",
            type61:"借款已暫停",
            type62:"清算已暫停",
            type63:"無法提取所有存款",
            type68:"達到當前資金池的最大存款總額限制",
            type312:"USDC餘額不足",
            type1000:"抵押和借款币种总数最多为5",
            type1001:"SOL餘額不足以支付交易手續費",
            type1002:"Solana網絡繁忙，交易未在30秒内確認",
            type1003:"交易體積太大",
        },
        2:{
            //in
            type11: "Jumlah masukan tidak valid",
            type20:"Likuiditas yang tersedia tidak mencukupi",
            type23:"Jumlah penarikan terlalu kecil",
            type24:"Jumlah penarikan terlalu besar",
            type25:"Jumlah pinjaman terlalu kecil untuk menerima likuiditas setelah biaya",
            type26:"Jumlah pinjaman terlalu besar untuk jumlah jaminan",
            type27:"Jumlah pembayaran terlalu kecil untuk mentransfer likuiditas",
            type28:"Jumlah likuidasi terlalu kecil untuk menerima agunan",
            type31:"Kewajiban cadangan melebihi batas",
            type32:"Pemilik obligasi tidak valid",
            type33:"Setoran Obligasi kosong",
            type34:"Obligasi pinjaman kosong",
            type37:"Kewajiban jaminan tidak valid",
            type38:"Likuiditas kewajiban tidak valid",
            type39:"Jaminan obligasi kosong",
            type40:"Likuiditas obligasi kosong",
            type56:"Jumlah setoran terlalu besar untuk saldo akun ",
            type57:"Jumlah tebusan terlalu besar untuk saldo setoran",
            type58:"Jumlah penarikan terlalu besar untuk saldo deposit",
            type59:"Jumlah pembayaran kembali terlalu besar untuk jumlah pinjaman",
            type60:"Setoran telah dijeda",
            type61:"Pinjaman telah dijeda ",
            type62:"Likuidasi telah dijeda",
            type63:"Tidak bisa menarik semua setoran",
            type312:"Insufficient USDC balance",
            type1000:"Setiap akun di batasi hingga 5 interaksi agunan dan peminjaman secara total, bersamaan  .",
            type1001:"Saldo SOL tidak mencukupi",
            type1002:"Solana network is busy ,Transaction was not confirmed in 30.00s",
            type1003:"Transaksi terlalu besar",
        },
        3:{
            //vn
            type11: "Số tiền nhập không hợp lệ",
            type20:"Không đủ Thanh khoản",
            type23:"Số tiền cần rút quá nhỏ",
            type24:"Số tiền cần rút quá lớn",
            type25:"Số tiền vay quá nhỏ để nhận thanh khoản sau các khoản phí",
            type26:"Số tiền vay quá lớn so với số tiền thế chấp",
            type27:"Số tiền hoàn trả quá nhỏ để chuyển thanh khoản",
            type28:"Số tiền thanh lý quá nhỏ để nhận tài sản thế chấp",
            type31:"Vượt quá hạn mức nghĩa vụ dự trữ",
            type32:"Nghĩa vụ sở hữu không hợp lệ",
            type33:"Nghĩa vụ tiền gửi trống",
            type34:"Nghĩa vụ vay trống",
            type37:"Nghĩa vụ tài sản thế chấp không hợp lệ",
            type38:"Nghĩa vụ thanh khoản không hợp lệ",
            type39:"Nghĩa vụ tài sản thế chấp trống",
            type40:"Nghĩa vụ thanh khoản trống",
            type56:"Số tiền gửi quá lớn so với số dư tài khoản",
            type57:"Đổi số tiền quá lớn so với số dư tiền gửi",
            type58:"Rút số tiền quá lớn so với số dư tiền gửi",
            type59:"Trả số tiền quá lớn so với số tiền đã vay",
            type60:"Gửi tiền đang tạm ngưng",
            type61:"Vay tiền đang tạm ngưng",
            type62:"Thanh khoản đang tạm ngưng",
            type63:"Không thể rút tất cả tiền gửi",
            type312:"Insufficient USDC balance",
            type1000:"Mỗi tài khoản được giới hạn tổng cộng 5 loại tài sản thế chấp và vay.",
            type1001:"Số dư SOL không đủ",
            type1002:"Solana network is busy , Transaction was not confirmed in 30.00s",
            type1003:"Giao dịch quá lớn",
        },
        4:{
            type11: "입력 금액이 잘못되었습니다",
            type20:"사용 가능한 유동성 부족",
            type23:"인출 수량이 너무 적습니다",
            type24:"인출 수량이 너무 큽니다",
            type25:"수수료를 포함해 유동성을 받기에는 대여금액이 너무 적습니다.",
            type26:"담보금액을 위한 대여 금액이 너무 큽니다",
            type27:"유동성 이전을 위한 재지불 금액이 너무 적습니다",
            type28:"담보물을 받기 위한 청산 금액이 너무 적습니다",
            type31:"의무 적립 한도가 초과 되었습니다",
            type32:"의무 소유자가 잘못되었습니다",
            type33:"의무 보증금이 비어 있습니다",
            type34:"의무 대여금이 비어 있습니다",
            type37:"유효하지 않은 의무 담보",
            type38:"유효하지 않은 의무 유동성",
            type39:"의무 담보물이 비어 있습니다",
            type40:"의무 유동성이 비어 있습니다",
            type56:"입금금액이 계좌 잔액에 비해 너무 큽니다",
            type57:"상환 금액이 예금 잔액에 비해 너무 큽니다",
            type58:"출금액이 입금 잔액에 비해 너무 많습니다",
            type59:"대여금액에 비해 상환금액이 너무 큽니다",
            type60:"입금이 일시 중지되었습니다",
            type61:"대여가 일시 중지되었습니다",
            type62:"청산이 일시 중지되었습니다",
            type63:"모든 예금액을 인출할 수 없습니다",
            type312:"Insufficient USDC balance",
            type1000:"각 계정은 동시에 총 5개의 담보 및 차입 상호 작용으로 제한됩니다",
            type1001:"SOL 잔고 부족",
            type1002:"Solana network is busy , Transaction was not confirmed in 30.00s",
            type1003:"거래량이 너무 큽니다거",

        },
        5:{
            type11: "Неверно введено количество",
            type20:"Недостаточно ликвидности",
            type23:"Слишком маленькая сумма вывода",
            type24:"Слишком большая сумма вывода",
            type25:"Сумма займа слишком мала для получения ликвидности после уплаты комиссии",
            type26:"Сумма займа слишком велика для суммы залога",
            type27:"Сумма погашения слишком мала",
            type28:"Сумма ликвидации слишком мала для получения обеспечения",
            type31:"Превышен лимит резерва по обязательствам",
            type32:"Обязательство владельца недействительно",
            type33:"Обязательства по депозитам недостаточны",
            type34:"Обязательства по займам недостаточны",
            type37:"Недействительное обеспечение обязательства",
            type38:"Недействительная ликвидность обязательства",
            type39:"Недостаточное обеспечение обязательства",
            type40:"Недостаточная ликвидность",
            type56:"Сумма депозита превышает остаток на счете",
            type57:"Сумма погашения превышает размер депозита",
            type58:"Сумма вывода превышает размер депозита",
            type59:"Сумма возврата превышает сумму займа",
            type60:"Депозит приостановлен",
            type61:"Заимствование приостановлено",
            type62:"Ликвидация приостановлена",
            type63:"Невозможно снять весь депозит",
            type312:"Insufficient USDC balance",
            type1000:"Каждая учетная запись ограничена в общей сложности 5 позициями обеспечения и заимствования одновременно.",
            type1001:"Недостаточный баланс SOL",
            type1002:"Solana network is busy , Transaction was not confirmed in 30.00s",
            type1003:"Слишком большая транзакция",
        },
        6:{
            //tr
            type11: "Girilen tutar geçersiz",
            type20:"Yetersiz likidite mevcut",
            type23:"Çekme miktarı çok küçük",
            type24:"Çekme miktarı çok büyük",
            type25:"Borçlanma miktarı, ücretlerden sonra likidite almak için çok küçük",
            type26:"Borçlanma miktarı, teminat tutarı için çok büyük",
            type27:"Geri ödeme miktarı likidite transferi için çok küçük",
            type28:"Likidite (tasfiye) miktarı teminatı almak için çok küçük",
            type31:"Zorunlu karşılık limiti aşıldı",
            type32:"Yükümlülük sahibi geçersiz",
            type33:"Zorunlu karşılık mevduatı yok",
            type34:"Zorunlu karşılık borçları yok",
            type37:"Geçersiz zorunlu karşılık teminatı",
            type38:"Geçersiz zorunlu karşılık likiditesi",
            type39:"Zorunlu karşılık teminatı yok",
            type40:"Zorunlu karşılık likiditesi yok",
            type56:"Mevduat tutarı hesap bakiyesi için çok büyük",
            type57:"Rehin kurtarma tutarı, mevduat bakiyesi için çok büyük",
            type58:"Çekme miktarı mevduat bakiyesi için çok büyük",
            type59:"Geri ödeme miktarı, borç miktarı için çok büyük",
            type60:"Mevduat yatırma duraklatıldı",
            type61:"Borçlanma duraklatıldı",
            type62:"Likidasyon duraklatıldı",
            type63:"Tüm mevduat çekilemez",
            type312:"Insufficient USDC balance",
            type1000:"Her hesap, aynı anda toplam 5 teminat ve borçlanma etkileşimi ile sınırlıdır.",
            type1001:"Yetersiz SOL dengesi",
            type1002:"Solana network is busy , Transaction was not confirmed in 30.00s",
            type1003:"İşlem çok büyük",
        }
    }
    getErrorType(type:number,languageIndex:number){
        // @ts-ignore
        return this.errorContext[languageIndex]["type"+type]
    }
}
export default errorType
