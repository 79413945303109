import {Detail} from "@/api/models";
import {Position} from "@/api/models/state/position";
import {UserLarixPositionsDetail} from "@/controller/type/userDetails";
import {LarixLockPool} from "@/api/models/state/larixLockPool";
import BigNumber from "bignumber.js";
import {eX} from "@/utils/helpers";
import store from "@/store";

export class UserLarixPositionDetails {
    public userLarixPositions :Detail<Position>[];
    private mineCollateralLp : any;
    private lockLarixPool : LarixLockPool;
    public userLarixPositionDetails:UserLarixPositionsDetail[] = []
    constructor(userLarixPositions :Detail<Position>[],mineCollateralLp : any,lockLarixPool : LarixLockPool) {
        this.userLarixPositions = userLarixPositions
        this.mineCollateralLp = mineCollateralLp
        this.lockLarixPool = lockLarixPool
        this.formatterUserLarixPositionDetails()
    }
    public formatterUserLarixPositionDetails(){
        const larixLockPoolTotalLp = new BigNumber(this.mineCollateralLp[0].farmLedger.info.deposited.toString())
        const larixLockPoolRewardDebtB = new BigNumber(this.mineCollateralLp[0].farmLedger.info.rewardDebtB.toString())
        const farmPoolPerShareB = eX(this.mineCollateralLp[0].rewardInfo.info.perShareB.toString(),-15)
        const increaseReward = farmPoolPerShareB.times(larixLockPoolTotalLp).minus(larixLockPoolRewardDebtB)
        const larixLockPoolRewardBPerShare =  eX(this.lockLarixPool.rewardBPerShare.toString(),-18).plus(increaseReward.div(larixLockPoolTotalLp))
        this.userLarixPositionDetails = this.userLarixPositions.map(
            (position)=>{
                return this.getUserLarixPosition(position,larixLockPoolRewardBPerShare)
            }
        )
    }
    private getUserLarixPosition(position:Detail<Position>,larixLockPoolRewardBPerShare:BigNumber):UserLarixPositionsDetail{
        const lpAmount  = eX(position.info.lpAmount.toString(),-6)
        const endTime = position.info.endTime.toNumber()
        const startTime = position.info.startTime.toNumber()
        const endTimeString = new Date(parseInt(String(endTime)) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ').split(' ')[0]
        const startTimeString = new Date(parseInt(String(startTime)) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ').split(' ')[0]
        const larixRewardAmount = eX(larixLockPoolRewardBPerShare.minus(eX(position.info.startRewardBPerShare.toString(),-18)).times(new BigNumber(position.info.lpAmount.toString())).toString(),-6)
        return{
            lpAmount,
            startTime,
            endTime,
            startTimeString,
            endTimeString,
            position,
            larixRewardAmount
        }
    }
    public setVuex(){
        store.commit('updateUserLarixPositions',this.userLarixPositionDetails)
        store.commit('updateUserRawPositionData',this.userLarixPositions)
    }
}