import {ReserveData} from "@/factory/reserveData";
import {Detail, Reserve} from "@/api/models";
import {ReserveDetails} from "@/controller/type/reserveDetails";
import {LpApyProvider, LpFeeApyProvider} from "@/api/provider/lpApyProvider";
import BigNumber from "bignumber.js";
import {LP_REWARD_TOKEN} from "@/api/constants/config";

export class LpReserve extends ReserveData{
    public reserveDetails = {} as ReserveDetails
    constructor(reserveData : Detail<Reserve>) {
        super(reserveData);
    }
    public setLpFeesApy(lpFeeApyProvider: LpFeeApyProvider){
        const apy = lpFeeApyProvider.getPoolFeeApy(this.reserveDetails.lpInfo.amm_id.toString())
        this.reserveDetails.lpInfo.lpFeesApr = apy || 0
    }
    public setLpApy(lpApyProvider:LpApyProvider,allPrice:[],allDecimals:[]){
        const {rewardAPrice,rewardBPrice,rewardADecimals,rewardBDecimals} = this.getLpRewardConfig(allPrice,allDecimals)
        const TVL = this.reserveDetails.lpInfo.farmPoolLpSupply.times(this.reserveDetails.liquidityPrice)
        lpApyProvider.setPool(this.reserveDetails.lpInfo.perBlock,this.reserveDetails.lpInfo.perBlockB)
        const lpApyDetails = lpApyProvider.getLpApyDetails(TVL,rewardAPrice,rewardBPrice,rewardADecimals,rewardBDecimals)
        const apyA = lpApyDetails.apyA
        const apyB = lpApyDetails.apyB
        const totalApy = lpApyDetails.totalApy
        this.reserveDetails.lpInfo.lpApr = new BigNumber(totalApy).times(100)
        this.reserveDetails.lpInfo.lpRewardAprA =  new BigNumber(apyA).times(100)
        this.reserveDetails.lpInfo.lpRewardAprB =  new BigNumber(apyB).times(100)
    }
    private getLpRewardConfig(allPrice:[],allDecimals:[]){
        const rewardAPrice = allPrice[LP_REWARD_TOKEN[this.reserveDetails.symbol].rewardA] ||new BigNumber(0)
        const rewardBPrice = allPrice[LP_REWARD_TOKEN[this.reserveDetails.symbol].rewardB]||new BigNumber(0)
        const rewardADecimals = allDecimals[LP_REWARD_TOKEN[this.reserveDetails.symbol].rewardA]||0
        const rewardBDecimals = allDecimals[LP_REWARD_TOKEN[this.reserveDetails.symbol].rewardB]||0
        return {rewardAPrice:rewardAPrice,rewardBPrice:rewardBPrice,rewardADecimals:rewardADecimals,rewardBDecimals:rewardBDecimals }
    }

}