import {getLendingMarketLocal} from "@/api/provider/lendingMarketProvider";
import {getLendingReserve} from "@/api/provider/lendingReserveProvider";
import getDetailService from "@/api/services/getDetailService";
import {getLarixLockPool} from "@/api/context/larixLock";
import {Detail, Reserve} from "@/api/models";
import {ReserveDetails} from "@/controller/type/reserveDetails";
import {BIG_NUMBER_ONE, BIG_NUMBER_ZERO, eX} from "@/utils/helpers";
import BigNumber from "bignumber.js";
import {getMineRatio} from "@/api/utils/calculateAllMine";
import {getInterest} from "@/api/utils/rateModel";
import {DoubleReward} from "@/data/doubleReward";
import {REAL_SLOTS_PER_DAY} from "@/api/constants/math";
import {LpApyProvider, LpFeeApyProvider} from "@/api/provider/lpApyProvider";
import {LP_REWARD_TOKEN, SORT_WEIGHT} from "@/api/constants/config";

export class ReserveData {
    private readonly reserveData : Detail<Reserve>
    public reserveDetails = {} as ReserveDetails
    constructor(reserveData : Detail<Reserve>) {
        this.reserveData = reserveData
    }
    public formatterReserve(larixPrice:number){
        const info = this.reserveData.info
        this.reserveDetails.reserveDetail = this.reserveData
        this.reserveDetails.symbol = info.liquidity.name
        this.reserveDetails.fullName = info.liquidity.fullName
        this.reserveDetails.poolType = info.liquidity.poolType
        this.reserveDetails.isLP = info.isLP
        this.reserveDetails.lpInfo = info.lpInfo
        this.reserveDetails.liquidityMintPubkey = info.liquidity.mintPubkey.toString()
        this.reserveDetails.collateralMintPubkey = info.collateral.mintPubkey.toString()
        this.reserveDetails.tokenName = info.liquidity.mintPubkey.toBase58()
        this.reserveDetails.liquidityPrice = eX(info.liquidity.marketPrice.toString()||"0",-18)
        this.reserveDetails.decimals = info.liquidity.mintDecimals
    //    isEnterMarket should in user data
        this.reserveDetails.liquidationThreshold = info.config.liquidationThreshold / 100
        this.reserveDetails.reserveCumulativeBorrowRateWads  = eX(info.liquidity.cumulativeBorrowRateWads.toString(),-(18))
        this.reserveDetails.borrowInterestFee = eX(info.config.fees.borrowInterestFeeWad.toString(),-18)
        const totalAvailable = new BigNumber(info.liquidity.availableAmount.toString()).minus(eX(info.liquidity.ownerUnclaimed.toString(),-18)).toString()
        this.reserveDetails.totalAvailableAmount = totalAvailable.startsWith("-")?BIG_NUMBER_ZERO:eX(totalAvailable,-Number(this.reserveDetails.decimals))
        this.reserveDetails.totalLiquidityInUsd = this.reserveDetails.totalAvailableAmount.times(this.reserveDetails.liquidityPrice)
        this.reserveDetails.totalAvailableInUsd =  this.reserveDetails.totalAvailableAmount.times( this.reserveDetails.liquidityPrice)
        let totalBorrowedAmount = eX(info.liquidity.borrowedAmountWads.toString(),-18)
        // 这里可能剩余一点渣，也就是小于1的一个数，此时直接当成0来处理
        if (totalBorrowedAmount.lt(BIG_NUMBER_ONE)) {
            totalBorrowedAmount = BIG_NUMBER_ZERO
        } else {
            totalBorrowedAmount = totalBorrowedAmount.div(10** this.reserveDetails.decimals)
        }

        this.reserveDetails.totalBorrowedAmount = totalBorrowedAmount
        this.reserveDetails.totalBorrowedInUsd = totalBorrowedAmount.times( this.reserveDetails.liquidityPrice)
        this.reserveDetails.totalLiquidityAmount = new BigNumber(eX(info.liquidity.availableAmount.toString(),- this.reserveDetails.decimals)).plus(totalBorrowedAmount).minus(eX(info.liquidity.ownerUnclaimed.toString(),-18- this.reserveDetails.decimals))
        this.reserveDetails.totalLiquidityInUsd =  this.reserveDetails.totalLiquidityAmount.times( this.reserveDetails.liquidityPrice)
        this.reserveDetails.mintTotalSupply = eX(info.collateral.mintTotalSupply.toString(),-1*Number( this.reserveDetails.decimals))


        this.reserveDetails.collateralFactor = new BigNumber(info.config.loanToValueRatio.toString()).div(100)
        const {lTokenMiningRatio,borrowMiningRatio}=getMineRatio( info)
        this.reserveDetails.larixSupplyDistributionRate = lTokenMiningRatio
        this.reserveDetails.larixBorrowDistributionRate = borrowMiningRatio
        this.reserveDetails.larixTotalMiningSpeed = eX(info.bonus.totalMiningSpeed.toString(),-6)
        this.reserveDetails.rateModelArray = getInterest(this.reserveData)
        this.reserveDetails.depositLimit = eX(info.depositLimit.toString(),-this.reserveDetails.decimals)
        this.reserveDetails.borrowApy =  info.config.borrowYearCompoundedInterestRate
        this.reserveDetails.supplyApy =  info.config.supplyYearCompoundedInterestRate
        let exchangeRate
        if (this.reserveDetails.mintTotalSupply.isZero() || this.reserveDetails.totalLiquidityAmount.isZero()){
            exchangeRate = BIG_NUMBER_ONE
        } else {
            exchangeRate = this.reserveDetails.mintTotalSupply.div(this.reserveDetails.totalLiquidityAmount)
        }
        this.reserveDetails.exchangeRate = exchangeRate
        let logoSource
        try {
            logoSource = require(`../assets/coin/asset_${this.reserveDetails.symbol}.${this.reserveDetails.isLP?'png':'svg'}`);
        } catch (e) {
            logoSource = require(`@/assets/coin/token.svg`);
        }
        this.reserveDetails.logoSource = logoSource
        const utilizationRateRaw = this.reserveDetails.totalLiquidityAmount.isZero()?new BigNumber(0):this.reserveDetails.totalBorrowedAmount.div(this.reserveDetails.totalLiquidityAmount)
        const utilizationRate = utilizationRateRaw.isGreaterThan(1) ? new BigNumber(1) : utilizationRateRaw
        this.reserveDetails.utilizationRate = utilizationRate
        if (isNaN(larixPrice)){
            this.reserveDetails.supplyDistributionApy = 0
            this.reserveDetails.borrowDistributionApy = 0
        }else {
            if (this.reserveDetails.poolType==='main'){
                this.reserveDetails.supplyDistributionApy = this.reserveDetails.larixSupplyDistributionRate.times(10).times(this.reserveDetails.larixTotalMiningSpeed).times(REAL_SLOTS_PER_DAY).times(365).times(larixPrice).div(this.reserveDetails.totalLiquidityInUsd.isZero()?1:this.reserveDetails.totalLiquidityInUsd)
                this.reserveDetails.borrowDistributionApy = this.reserveDetails.larixBorrowDistributionRate.times(10).times(this.reserveDetails.larixTotalMiningSpeed).times(REAL_SLOTS_PER_DAY).times(365).times(larixPrice).div(this.reserveDetails.totalBorrowedInUsd.isZero()?1:this.reserveDetails.totalBorrowedInUsd)
            }
            else {
                this.reserveDetails.supplyDistributionApy = this.reserveDetails.larixSupplyDistributionRate.times(this.reserveDetails.larixTotalMiningSpeed).times(REAL_SLOTS_PER_DAY).times(365).times(larixPrice).div(this.reserveDetails.totalLiquidityInUsd.isZero()?1:this.reserveDetails.totalLiquidityInUsd)
                this.reserveDetails.borrowDistributionApy = this.reserveDetails.larixBorrowDistributionRate.times(this.reserveDetails.larixTotalMiningSpeed).times(REAL_SLOTS_PER_DAY).times(365).times(larixPrice).div(this.reserveDetails.totalBorrowedInUsd.isZero()?1:this.reserveDetails.totalBorrowedInUsd)
            }
        }
        //挖矿每日产出
        this.reserveDetails.dailyMining = (this.reserveDetails.larixTotalMiningSpeed.times(REAL_SLOTS_PER_DAY))
        this.reserveDetails.sortWeight = SORT_WEIGHT[this.reserveDetails.symbol]
        // if (this.reserveDetails.poolType==='bonfida'){
        //     console.log('supplyDistributionApy',this.reserveDetails.supplyDistributionApy.toString())
        //     console.log('borrowDistributionApy',this.reserveDetails.borrowDistributionApy.toString())
        //     console.log('supplyApy',this.reserveDetails.supplyApy.toString())
        //     console.log('borrowApy',this.reserveDetails.borrowApy.toString())
        //     // console.log('supplyDistributionApy',this.reserveDetails.supplyDistributionApy.toString())
        //     // console.log('supplyDistributionApy',this.reserveDetails.supplyDistributionApy.toString())
        // }
    }
    public setReserveDoubleReward(allPrice:[]){
        const doubleRewardProvider = new DoubleReward()
        if (this.reserveDetails.isLP){
            doubleRewardProvider.setDoubleRewardConfig(this.reserveDetails,allPrice)
            doubleRewardProvider.getLpDoubleRewardDetails()
        }else {
            doubleRewardProvider.setDoubleRewardConfig(this.reserveDetails,allPrice)
            this.reserveDetails = doubleRewardProvider.getDoubleRewardDetails()
        }
    }

}