import {WalletAdapter} from "@/api/wallets";
import BN from "bn.js";
import {getConnection, sendTransaction} from "@/api/context/connection";
import {Marinade, MarinadeConfig} from "@marinade.finance/marinade-ts-sdk";

export const stakeMSOL = async (
    wallet:WalletAdapter,
    amount:BN,
) =>{
    const connection = getConnection()
    const config = new MarinadeConfig({
        connection: connection,
        publicKey: wallet.publicKey,
    })
    const marinade = new Marinade(config)
    const {
        transaction,
    } = await marinade.deposit(amount)
    const res = await sendTransaction(
        connection,
        wallet,
        transaction.instructions,
        //@ts-ignore
        transaction.signatures
    )
}