import {LpFeeApyProvider} from "@/api/provider/lpApyProvider";
import BigNumber from "bignumber.js";
import {LARIX_STAKE_POOLS} from "@/api/constants/config";

export class StakePools {
    public mineCollateralLp : any
    constructor(mineCollateralLp:any) {
        this.mineCollateralLp = mineCollateralLp
    }
    public formatMineCollateralLp(lpFeeApyProvider:LpFeeApyProvider,larixPrice:number,rayPrice:number){

        this.mineCollateralLp.forEach((pool:any,index:number)=>{
            lpFeeApyProvider.setPool(new BigNumber(pool.rewardInfo.info.perBlock),new BigNumber(pool.rewardInfo.info.perBlockB))
            const larixStakeTvl = lpFeeApyProvider.getTvl(LARIX_STAKE_POOLS[index].amm_Id.toString(),index)?.totalFarmLp!
            const larixStakeLpPrice = lpFeeApyProvider.getTvl(LARIX_STAKE_POOLS[index].amm_Id.toString(),index)?.lpPrice
            const larixStakePoolFeeApy = lpFeeApyProvider.getPoolFeeApy(LARIX_STAKE_POOLS[index].amm_Id.toString())
            const larixStakePoolTotalApy = lpFeeApyProvider.getLpApyDetails(new BigNumber(larixStakeTvl),new BigNumber(rayPrice),new BigNumber(larixPrice),6,6).totalApr
            this.mineCollateralLp[index].feeApy = larixStakePoolFeeApy
            this.mineCollateralLp[index].apy = larixStakePoolTotalApy
            this.mineCollateralLp[index].lpPrice = larixStakeLpPrice
            this.mineCollateralLp[index].currentTime = new Date().valueOf() / 1000
            this.mineCollateralLp[index].pairsName = LARIX_STAKE_POOLS[index].name
        })
    }
}