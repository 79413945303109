import {ReserveDetails} from "@/controller/type/reserveDetails";
import store from '../store'
import BigNumber from "bignumber.js";
import {TOTAL_LARIX_DAILY} from "@/utils/helpers";
import {IEO_LARIX_AMOUNT} from "@/api/constants/math";
import {Detail, LendingMarket, Reserve} from "@/api/models";
import {LpReserve} from "@/factory/lpReserve";
import {ReserveData} from "@/factory/reserveData";
import {LpApyProvider, LpFeeApyProvider} from "@/api/provider/lpApyProvider";
import {LARIX_STAKE_POOLS} from "@/api/constants/config";
import { StakePools } from "../factory/stakePools";

export class UpdateDataNoWallet{
    public allReserveDetails:ReserveDetails[] = [];
    public reserveArrayInner : Detail<Reserve>[];
    private lendingMarket: Detail<LendingMarket>;
    private larixPrice :number;
    private allPrice:any;
    private lpFeeApyProvider:LpFeeApyProvider;
    private lpApyProvider:LpApyProvider;
    private allDecimals : any;
    private mineCollateralLp : any;
    private allMarketTotalSupply:BigNumber = new BigNumber(0);
    private allMarketsTotalBorrow:BigNumber = new BigNumber(0);
    private totalDailyMining:BigNumber = new BigNumber(0);
    private larixCirculation:BigNumber = new BigNumber(0);
    private launchpadTotalBorrow = new BigNumber(0);
    private launchpadTotalSupply = new BigNumber(0);
    constructor(
        lendingMarket: Detail<LendingMarket>,
        reserveArrayInner:Detail<Reserve>[],
        lpFeeApyProvider:LpFeeApyProvider,
        lpApyProvider:LpApyProvider,
        larixPrice:number,
        allPrice:any,
        allDecimals:any,
        mineCollateralLp:any
                ) {
        this.lendingMarket = lendingMarket
        this.reserveArrayInner = reserveArrayInner
        this.lpFeeApyProvider = lpFeeApyProvider
        this.lpApyProvider = lpApyProvider
        this.larixPrice = larixPrice
        this.allPrice = allPrice
        this.allDecimals = allDecimals
        this.mineCollateralLp = mineCollateralLp
    }
    public setVuex(){
        store.commit('updateLendingMarket', this.lendingMarket)
        store.commit('updateLarixPrice', this.larixPrice)
        store.commit('updateLendingReserveArray',this.reserveArrayInner)
        // store.commit('updateAutoFreshTime',autoFreshTime)
        store.commit('updateTotalDailyMining',this.totalDailyMining)
        store.commit('updateAllMarketTotalSupply', this.allMarketTotalSupply)
        store.commit('updateAllMarketTotalBorrow', this.allMarketsTotalBorrow)
        store.commit('updateLaunchpadTotalSupply', this.launchpadTotalSupply)
        store.commit('updateLaunchpadTotalBorrow', this.launchpadTotalBorrow)
        store.commit('updateIsLoadingInfo',false)
        store.commit('updateLarixCirculation',this.larixCirculation)
        store.commit('updateAllReservesDetails',this.allReserveDetails)
        store.commit('updateMineCollateralLpDetails',this.mineCollateralLp)
    }
    public getAllReserveData(){
        this.allReserveDetails = this.reserveArrayInner.map((reserve)=>{
            if (reserve.info.isLP){
                const controller = new LpReserve(reserve)
                controller.formatterReserve(this.larixPrice)
                controller.setReserveDoubleReward(this.allPrice)
                controller.setLpFeesApy(this.lpFeeApyProvider)
                controller.setLpApy(this.lpApyProvider,this.allPrice,this.allDecimals)
                return controller.reserveDetails
            }else {
                const controller = new ReserveData(reserve)
                controller.formatterReserve(this.larixPrice)
                controller.setReserveDoubleReward(this.allPrice)
                return controller.reserveDetails
            }
        })
    }
    public getAllMarketDetails(){
        this.allReserveDetails.forEach((reserveDetails)=>{
            this.getAllMarketTotalSupply(reserveDetails);
            this.getAllMarketTotalBorrow(reserveDetails);
            this.getTotalDailyMining(reserveDetails);
            this.getLarixCirculation();
        })
        this.getMineCollateralLp();
    }
    private getAllMarketTotalSupply(reserveDetails:ReserveDetails){
        if (reserveDetails.poolType==='main'){
            this.allMarketTotalSupply = this.allMarketTotalSupply.plus(reserveDetails.totalLiquidityInUsd)
        } else {
            this.launchpadTotalSupply = this.launchpadTotalSupply.plus(reserveDetails.totalLiquidityInUsd)
        }
    }
    private getAllMarketTotalBorrow(reserveDetails:ReserveDetails){
        if (reserveDetails.poolType==='main'){
            this.allMarketsTotalBorrow = this.allMarketsTotalBorrow.plus(reserveDetails.totalBorrowedInUsd)
        } else {
            this.launchpadTotalBorrow = this.launchpadTotalBorrow.plus(reserveDetails.totalBorrowedInUsd)
        }

    }
    private getTotalDailyMining(reserveDetails:ReserveDetails){
        this.totalDailyMining = this.totalDailyMining.plus(reserveDetails.dailyMining)
    }
    private getLarixCirculation(){
        const currentTime = new Date().valueOf()

        const circulationTime = new BigNumber((currentTime-1631858400000)/3600000)
        this.larixCirculation =  new BigNumber(new BigNumber(TOTAL_LARIX_DAILY).div(24).times(circulationTime).plus(IEO_LARIX_AMOUNT).toFixed(2))
    }
    private getMineCollateralLp(){
        const stakePoolController = new StakePools(this.mineCollateralLp)
        return stakePoolController.formatMineCollateralLp(this.lpFeeApyProvider,this.larixPrice,this.allPrice['RAY'])
    }
}
